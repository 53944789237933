import classnames from 'classnames';
import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import xrange from 'highcharts/modules/xrange';
import I18n from 'i18n-js';
import './Gantt.scss';

xrange(Highcharts);

function Gantt({ className, data }) {
  const classNames = classnames('Gantt', className);

  const categories = data.rows.map((row) => {
    return row[1];
  });

  const seriesData = data.rows.map((row, rowIndex) => {
    return {
      x: row[2],
      x2: row[3],
      y: rowIndex,
    };
  });

  const series = [
    {
      borderColor: '#282887',
      borderRadius: 0,
      color: '#282887',
      colorByPoint: false,
      data: seriesData,
      dataLabels: {
        enabled: false,
      },
      name: 'Aikataulu',
      pointWidth: 20,
      showInLegend: false,
    },
  ];

  const options = {
    chart: {
      type: 'xrange',
    },
    title: {
      text: '',
    },
    tooltip: {
      xDateFormat: '%d.%m.%Y',
    },
    xAxis: {
      type: 'datetime',
    },
    yAxis: {
      categories,
      gridLineWidth: 0,
      labels: {
        align: 'left',
        reserveSpace: true,
      },
      reversed: true,
      title: {
        text: '',
      },
    },
    series,
  };

  Highcharts.setOptions({
    lang: {
      decimalPoint: ',',
      months: [
        I18n.t('calendar.months.january'),
        I18n.t('calendar.months.february'),
        I18n.t('calendar.months.march'),
        I18n.t('calendar.months.april'),
        I18n.t('calendar.months.may'),
        I18n.t('calendar.months.june'),
        I18n.t('calendar.months.july'),
        I18n.t('calendar.months.august'),
        I18n.t('calendar.months.september'),
        I18n.t('calendar.months.october'),
        I18n.t('calendar.months.november'),
        I18n.t('calendar.months.december'),
      ],
      shortMonths: [
        I18n.t('calendar.shortMonths.jan'),
        I18n.t('calendar.shortMonths.feb'),
        I18n.t('calendar.shortMonths.mar'),
        I18n.t('calendar.shortMonths.apr'),
        I18n.t('calendar.shortMonths.may'),
        I18n.t('calendar.shortMonths.jun'),
        I18n.t('calendar.shortMonths.jul'),
        I18n.t('calendar.shortMonths.aug'),
        I18n.t('calendar.shortMonths.sep'),
        I18n.t('calendar.shortMonths.oct'),
        I18n.t('calendar.shortMonths.nov'),
        I18n.t('calendar.shortMonths.dec'),
      ],
      shortWeekdays: [
        I18n.t('calendar.shortWeekdays.su'),
        I18n.t('calendar.shortWeekdays.mo'),
        I18n.t('calendar.shortWeekdays.tu'),
        I18n.t('calendar.shortWeekdays.we'),
        I18n.t('calendar.shortWeekdays.th'),
        I18n.t('calendar.shortWeekdays.fr'),
        I18n.t('calendar.shortWeekdays.sa'),
      ],
      weekdays: [
        I18n.t('calendar.weekdays.sunday'),
        I18n.t('calendar.weekdays.monday'),
        I18n.t('calendar.weekdays.tuesday'),
        I18n.t('calendar.weekdays.wednesday'),
        I18n.t('calendar.weekdays.thursday'),
        I18n.t('calendar.weekdays.friday'),
        I18n.t('calendar.weekdays.saturday'),
      ],
    },
  });

  return (
    <div className={classNames}>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
}

export default Gantt;
