import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './index.scss';
import detectBrowserLanguage from 'detect-browser-language';
import I18n from 'i18n-js';
import Logger from 'js-logger';
import translations from './config/translations.js';
import App from './App';
import LocalStorageService from './AvainiaTools/LocalStorageService.js';

// Setup logger
Logger.useDefaults();
Logger.info('Avainia initializating');

// Setup localisations
I18n.translations = translations;
I18n.defaultLocale = 'en';
I18n.missingTranslation = (key) => { Logger.warn(`Missing translation '${key}`); return '???'; };
window.I18n = I18n;

const storedLang = LocalStorageService.getLang();

if (storedLang) {
  I18n.locale = storedLang;
} else {
  let detectedLanguage = detectBrowserLanguage();

  // Truncate "fi-fi" to "fi" for example
  if (detectedLanguage) {
    if (detectedLanguage.length > 2) {
      detectedLanguage = detectedLanguage.slice(0, 2);
    }
    detectedLanguage = detectedLanguage.toLowerCase();
  }

  if (translations[detectedLanguage]) {
    I18n.locale = detectedLanguage;
  } else {
    Logger.warn(`Detected language ${detectedLanguage} is not available, falling back to ${I18n.currentLocale()}`);
  }
}

// Setup global helper
window.Avainia = { version: '3', core: process.env.REACT_APP_API_HOST };

// Render application
ReactDOM.render(<BrowserRouter><App /></BrowserRouter>, document.getElementById('root'));
