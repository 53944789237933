import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Pusher from 'pusher-js';
import Admin from './components/mainviews/Admin/Admin.js';
import Apartment from './components/mainviews/Condominiums/Apartment.js';
import Utils from './AvainiaTools/Utils.js';
import AvainiaPermissions from './AvainiaTools/AvainiaPermissions.js';
import AvainiaNav from './components/multiview/AvainiaNav/AvainiaNav.js';
import Companies from './components/mainviews/Companies/Companies.js';
import CompanyManagement from './components/mainviews/CompanyManagement/CompanyManagement.js';
import Condominium from './components/mainviews/Condominiums/Condominium.js';
import Condominiums from './components/mainviews/Condominiums/Condominiums.js';
import Error from './components/multiview/Error/Error.js';
import Footer from './components/multiview/Footer/Footer.js';
import Loading from './components/multiview/Loading/Loading.js';
import Login from './components/mainviews/Login/Login.js';
import LoginStatusToast from './components/multiview/Toasts/LoginStatusToast.js';
import PrivacyPolicy from './components/mainviews/PrivacyPolicy/PrivacyPolicy.js';
import Products from './components/mainviews/Products/Products.js';
import Permissions from './components/mainviews/Permissions/Permissions.js';
import Project from './components/mainviews/Projects/Project/Project.js';
import Projects from './components/mainviews/Projects/Projects.js';
import Translations from './components/mainviews/Translations/Translations.js';
import DocumentTypes from './components/mainviews/DocumentTypes/DocumentTypes.js';
import './App.scss';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      configuration: false,
      loading: true,
      error: false,
      sessionToast: false,
      timeout: false,
      loginCheckInterval: false,
      interval: null,
      pageTitle: '',
    };
  }

  componentDidMount() {
    const loginCheckInterval = this.createLoginChecker();
    window.Avainia.loginCheckInterval = loginCheckInterval;

    this.setState({ loginCheckInterval }, () => {
      this.pusherSubscriber();
      Utils.Globals.getGlobals().then(() => {
        // Ensure that the configuration exists and works
        Utils.Configuration.getConfiguration().then((configuration) => {
          if (configuration.error) { return this.setState({ error: 1000 }); }
          this.setState({
            configuration,
            loading: false,
            pageTitle: document.title,
          });
        });
      });
    });
  }

  createLoginChecker = () => {
    return window.setInterval(() => {
      if (window.triggerLoginCheck) {
        window.clearInterval(this.state.loginCheckInterval);

        Utils.API.loginCheck().then((result) => {
          if (result.error) {
            const localToken = Utils.LocalStorage.getToken();
            this.handleSessionTimeout(localToken);
          } else {
            // TODO: Replace with real log
            console.error('This message should not be visible ever. Check why this is visible');
          }
        });
      }
    }, 1000);
  }

  loginCallback = () => {
    this.setState({
      timeout: false,
      sessionToast: false,
    }, this.stopBlink);
  }

  handleSessionTimeout = (token) => {
    const localToken = Utils.LocalStorage.getToken();
    if (token === localToken) {
      Utils.LocalStorage.clearUser();
      this.setState({ timeout: true }, this.stopBlink);
    }
  }

  handlePusherEvent = (event) => {
    if (event.message === 'SESSION_WILL_EXPIRE') {
      const interval = setInterval(this.blinkTitle, 1000);
      this.setState({ sessionToast: true, interval });
    } else if (event.message === 'SESSION_HAS_EXPIRED') {
      this.handleSessionTimeout(event.token);
    }
  }

  pusherSubscriber = () => {
    Pusher.logToConsole = false;
    const user = Utils.LocalStorage.getUser();

    if (user) {
      const userChannel = `private-App.User.${user.id}`;

      const pusher = new Pusher('67e6fecb53b597ce117a', {
        cluster: 'eu',
        authEndpoint: `${process.env.REACT_APP_API_HOST}/api/v1/auth/pusher`,
        auth: {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        },
      });

      const channel = pusher.subscribe(userChannel);
      channel.bind('Illuminate\\Notifications\\Events\\BroadcastNotificationCreated', this.handlePusherEvent);
    }
  }

  blinkTitle = () => {
    const { pageTitle } = this.state;
    const attentionMsg = `\u26A0 ${pageTitle}`;

    if (document.title === attentionMsg) {
      document.title = pageTitle;
    } else {
      document.title = attentionMsg;
    }
  }

  stopBlink = () => {
    clearInterval(this.state.interval);
    document.title = this.state.pageTitle;
  }

  render() {
    const user = Utils.LocalStorage.getUser();
    if (this.state.loading) { return <Loading />; }
    if (this.state.error) { return <Error error={this.state.error} />; }

    // const importtest = () => {
    //   const data = {
    //     type: 'projects',
    //     payload: 'ContractCode,ContractName,CompanyName,CompanyCode\n' +
    //             '1979349,Sysmä- Särkilahti-Leppäkorpi,Elenia Oy,2445423-4\n' +
    //             '1874860,Miemala-Kirkonkulma,Elenia Oy,2445423-4\n' +
    //             '1975793,EA5911 Isojoki vanhan automaation purku ja toimitus Kajaaniin,Kajave Oy,2058753-1\n' +
    //             '2055661,Vuorokkaan asuntoalueen pj-verkon saneeraus,Kajave Oy,2058753-1\n' +
    //             '1906414,Puuska - Kortevaara hanke,Kajave Oy,2058753-1\n' +
    //             '1501860,Kalliojoki - Lentiiran EA hanke,Kajave Oy,2058753-1\n' +
    //             '1567388,Kontinjoki - Hanhila,Kajave Oy,2058753-1\n' +
    //             '1567388,Kontinjoki - Hanhila,MissingCompany,23123132132132\n' +
    //             '1567388,Kontinjoki - Hanhila,Mediasignal,1\n' +
    //             '1501860,Kalliojoki - Lentiiran EA hanke,Mediasignal,123141413414314143\n' +
    //             '2055661,Vuorokkaan asuntoalueen pj-verkon saneeraus,Mediasignal XXXXXX,1',
    //   };

    //   Utils.API.doImportData(data);
    // };

    if (!user || this.state.timeout) {
      return <div className="App">
        <Switch>
          <Route path="/privacy" render={() => <PrivacyPolicy configuration={this.state.configuration} />} />
          <Route path="/activate/:token/:email" render={() => <Login configuration={this.state.configuration} activate />} />
          <Route path="/forgot/:token/:email" render={() => <Login configuration={this.state.configuration} forgot />} />
          <Route render={() => <Login configuration={this.state.configuration} timeout={this.state.timeout} />} />
        </Switch>
      </div>;
    }

    //! Note: these need to match the ones in AvainiaNav!
    const companiesManage = user.hasPermission(AvainiaPermissions.CompaniesManage);
    const admin = user && (user.hasRole('owner') || user.hasRole('super'));
    const myCompany = user && (user.hasRole('manager') || user.hasRole('super'));
    const ownerManager = user && user.isOwnerManager();

    return <div className="App">
      {this.state.sessionToast && <LoginStatusToast loginCallback={this.loginCallback} />}
      <AvainiaNav configuration={this.state.configuration} />
      <Switch>
        <Route path="/privacy" render={() => <PrivacyPolicy configuration={this.state.configuration} />} />
        <Route path="/projects" render={() => <Projects configuration={this.state.configuration} />} />
        <Route path="/project/:id/:view" render={() => <Project configuration={this.state.configuration} />} />
        <Route path="/project/:id" render={() => <Project configuration={this.state.configuration} />} />
        <Route path="/products" render={() => <Products />} />
        <Route path="/condominiums" render={() => <Condominiums />} />
        <Route path="/condominium/:condominiumId/apartments/:apartmentId" render={() => <Apartment />} />
        <Route path="/condominium/:id" render={() => <Condominium />} />
        {ownerManager && <Route path="/permissions" render={() => <Permissions />} />}
        {companiesManage && <Route path="/companies" render={() => <Companies configuration={this.state.configuration} />} />}
        {myCompany && <Route path="/company-management/:id" render={() => <CompanyManagement configuration={this.state.configuration} />} />}
        {admin && <Route path="/translations" render={() => <Translations />} />}
        {ownerManager && <Route path="/admin" render={() => <Admin />} />}
        {ownerManager && <Route path="/document-types" render={() => <DocumentTypes />} />}
        <Route path="/" render={() => <Redirect to='/projects' />} />
      </Switch>
      <Footer configuration={this.state.configuration} />
    </div>;
  }
}

export default App;
