import React, { Component } from 'react';
import { Button, Modal } from 'react-bootstrap';
import I18n from 'i18n-js';

class ProductReportModal extends Component {
  componentDidMount = () => {
    const html = this.props.data;
    const element = document.getElementById('output_iframe1');

    // write new content
    element.contentWindow.document.open();
    element.contentWindow.document.write(html);
    element.contentWindow.document.close();
  }

  render() {
    return <Modal show={true} onHide={this.props.onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title></Modal.Title>
      </Modal.Header>
      <Modal.Body>

      <iframe title="Avainia" id="output_iframe1" src="about:blank" style={{ width: '100%', minHeight: '400px', border: 0 }}>

      </iframe>

      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={this.props.onHide}>{I18n.t('views.materialforms.button-cancel')}</Button>
      </Modal.Footer>
    </Modal>;
  }
}

export default ProductReportModal;
