import React, { Component } from 'react';
import I18n from 'i18n-js';
import { Trash } from '../Icon/Icon.js';
import { Col, Row, Button, Form, FormControl, Modal } from 'react-bootstrap';
import APIService from '../../../AvainiaTools/APIService.js';
import UtilService from '../../../AvainiaTools/UtilService.js';
import Error from '../Error/Error.js';
import Loading from '../Loading/Loading.js';

class EditMaterialformModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      materialform: {
        id: false,
        name: '',
        groups: [],
      },
      productCategories: [],
      nameGroup: '',
      newGroupActive: false,
      activeCategory: false,
      loading: true,
      error: false,
    };
  }

  componentDidMount = () => {
    APIService.productCategoriesGet().then((productCategories) => {
      if (productCategories.error) { return this.setState({ error: productCategories.error }); }

      // Properly initiate materialform
      APIService.projectMaterialFormGet(this.props.project.id, this.props.materialform.id).then((result) => {
        if (result.error) { return this.setState({ error: result.error }); }

        UtilService.convertMaterialform(result).then((materialform) => {
          if (materialform.error) { return this.setState({ error: materialform.error }); }

          return this.setState({
            materialform,
            name: materialform.name,
            productCategories,
            loading: false,
          });
        });
      });
    });
  }

  componentDidUpdate = () => {
    if (!this.props.materialform) { return; }
    if (this.props.materialform.id === this.state.materialform.id) { return; }
    this.setState({ materialform: this.props.materialform });
  }

  activateCategory = (activeCategory, e) => {
    this.setState({ activeCategory, loading: true }, () => {
      APIService.productsGet(activeCategory.id).then((products) => {
        if (products.error) { return this.setState({ error: products.error }); }

        this.setState({ products, loading: false });
      });
    });
  }

  save = async () => {
    if (this.state.loading) { return; }

    // TODO! There is a bug here. We should FIRST delete using a top-down approach and then do the update/Create separately
    // TODO! There is a 2nd bug here. It's not possible to select the default option AND set the create the options at the same time, because the option IDs are not available.
    // The bug is triggered when someone deletes a top-level element (group) and then its children (sels, opts) are attempted to save

    const { materialform } = this.state;

    this.setState({ loading: true }, async () => {
      const payload = { name: materialform.name };

      let error = false;

      // Update Materialform
      await APIService.projectMaterialFormsUpdate(this.props.project.id, materialform.id, payload).then((result) => {
        if (result.error) { error = result.error; }
      });
      if (error) { return this.setState({ error }); }

      // Delete everything
      console.log('Processing deleted objects');
      let promises = [];
      materialform.groups.forEach((g) => {
        if (g.deleted) { return promises.push(APIService.projectMaterialFormsGroupsDelete(materialform.id, g.id)); }
        g.selects.forEach((s) => {
          if (s.deleted) { return promises.push(APIService.projectMaterialFormsSelectDelete(g.id, s.id)); }
          s.options.forEach((o) => {
            if (o.deleted) { return promises.push(APIService.projectMaterialFormsOptionsDelete(s.id, o.id)); }
          });
        });
      });

      await Promise.all(promises).then((results) => {
        results.forEach((result) => { if (result.error) { error = result.error; } });
      });
      if (error) { return this.setState({ error }); }

      // Update everything
      console.log('Processing updated objects');
      promises = [];
      materialform.groups.forEach((g) => {
        if (g.deleted) { return; }
        const payload = { id: g.id, name: g.name };
        if (!g.isNew) { return promises.push(APIService.projectMaterialFormsGroupsUpdate(materialform.id, payload)); }
        g.selects.forEach((s) => {
          const payload = { id: s.id, name: s.name, area: s.area, product_category_id: s.product_category_id };
          if (!s.isNew) { return promises.push(APIService.projectMaterialFormsSelectUpdate(g.id, payload)); }
          s.options.forEach((o) => {
            const payload = { id: o.id, name: o.name, price: o.price, product_id: o.product_id };
            if (!o.isNew) { return promises.push(APIService.projectMaterialFormsOptionUpdate(s.id, payload)); }
          });
        });
      });

      await Promise.all(promises).then((results) => {
        results.forEach((result) => { if (result.error) { error = result.error; } });
      });
      if (error) { return this.setState({ error }); }

      // Save everyting and mark those that need to be updated
      console.log('Processing created objects');
      await Promise.all(await materialform.groups.map(async (g) => {
        if (error || g.deleted) { return; }

        const payload = { name: g.name };
        if (g.isNew) {
          const result = await APIService.projectMaterialFormsGroupsCreate(materialform.id, payload);
          if (result.error) { error = result.error; return; }
          g.id = result.id;
        }

        await Promise.all(await g.selects.map(async (s) => {
          if (error || s.deleted) { return; }

          const payload = { name: s.name, area: s.area, product_category_id: s.product_category_id };
          if (s.isNew) {
            const result = await APIService.projectMaterialFormsSelectCreate(g.id, payload);
            if (result.error) { error = result.error; return; }
            s.id = result.id;
          }

          await Promise.all(await s.options.map(async (o) => {
            if (error || o.deleted || !o.isNew) { return; }

            const payload = { name: o.name, price: o.price, product_id: o.product_id };
            const { id } = o;
            const result = await APIService.projectMaterialFormsOptionCreate(s.id, payload);
            if (result.error) { error = result.error; return; }
            o.oldId = id;
            o.id = result.id;
          }));
        }));
      }));
      if (error) { return this.setState({ error }); }

      // Loop through everything and update selects that have a default_option_id set --- find the new ID of their matching options
      console.log('Processing selects to save defaultOptions');
      promises = [];
      materialform.groups.forEach((g) => {
        if (error) { return; }
        g.selects.forEach((s) => {
          if (error) { return; }
          if (!s.options || s.options.length === 0) { return; }

          const payload = { id: s.id, name: s.name, area: s.area, product_category_id: s.product_category_id };

          // eslint-disable-next-line eqeqeq
          const targetDefaultOption = s.options.find((o) => o.oldId == s.default_option_id || o.id == s.default_option_id);

          if (!targetDefaultOption) { error = 21; return; } // TODO! Add translation
          payload.default_option_id = targetDefaultOption.id;

          return promises.push(APIService.projectMaterialFormsSelectUpdate(g.id, payload));
        });
      });
      if (error) { return this.setState({ error }); }

      await Promise.all(promises).then((results) => {
        results.forEach((result) => { if (result.error) { error = result.error; } });
      });
      if (error) { return this.setState({ error }); }

      return this.props.refreshModal(); // TODO: After save, show alert and update the whole page, the view update to match IDs is a PITA
    });
  }

  onChange = (e) => { this.setState({ [e.target.name]: e.target.value }); }

  onChangeMaterialform = (e) => {
    const materialform = { ...this.state.materialform };
    materialform[e.target.name] = e.target.value;
    this.setState({ materialform });
  }

  addNewGroup = () => {
    if (this.state.nameGroup === '') { return; }
    const g = this.state.materialform.groups || [];
    const groups = [...g];
    groups.push({
      id: UtilService.uuidv4(),
      name: this.state.nameGroup,
      selects: [],
      isNew: true,
    });

    const materialform = { ...this.state.materialform };
    materialform.groups = groups;
    this.setState({ materialform, newGroupActive: false, nameGroup: '' });
  }

  updateGroup = (updatedGroup) => {
    const g = this.state.materialform.groups || [];
    let groups = [...g];
    groups = groups.map((group) => (group.id === updatedGroup.id ? updatedGroup : group));

    const materialform = { ...this.state.materialform };
    materialform.groups = groups;
    this.setState({ materialform });
  }

  activateGroupAdd = () => {
    this.setState({ newGroupActive: true });
  }

  render() {
    return <Modal show={true} onHide={this.props.onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{I18n.t('views.materialforms.edit-title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>

      {this.state.error && <Error inline error={this.state.error} />}
      {!this.state.error && this.state.loading && <Loading inline />}
      {!this.state.error && !this.state.loading && <>
        <Row>
          <Col sm={2}>{I18n.t('views.materialforms.materialform-name')}</Col>
          <Col sm={10}><FormControl name="name" type="text" value={this.state.materialform.name} onChange={this.onChangeMaterialform} /></Col>
        </Row>

        {!this.state.newGroupActive && <Row>
          <Col sm={2}></Col>
          <Col sm={10}>
            <Button variant="secondary" onClick={this.activateGroupAdd}>{I18n.t('views.materialforms.button-add-group')}</Button>
          </Col>
        </Row>}

        {this.state.newGroupActive && <Row>
          <Col sm={2}></Col>
          <Col sm={10}>
            <div className="typicalBox">
              <Row>
                <Col sm={2}> {I18n.t('views.materialforms.materialform-name')} </Col>
                <Col sm={10}>
                  <FormControl name="nameGroup" type="text" value={this.state.nameGroup} onChange={this.onChange} />
                </Col>
              </Row>

              <Button variant="secondary" onClick={this.addNewGroup}>{I18n.t('views.materialforms.button-add-group')}</Button>
            </div>
          </Col>
        </Row>}

        {this.state.materialform.groups.map((group) => <MaterialFormGroupEditor key={group.id} group={group} updateGroup={this.updateGroup} productCategories={this.state.productCategories} />)}
      </>}

      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={this.props.onHide}>{I18n.t('views.materialforms.button-cancel')}</Button>
        <Button variant="primary" onClick={this.save}>{I18n.t('views.materialforms.button-save')}</Button>
      </Modal.Footer>
    </Modal>;
  }
}

class MaterialFormGroupEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: false,
      name: '',
      nameSelect: '',
      deleted: false,
      product_category_id: false,
      newSelectActive: false,
      selects: [],
    };
  }

  componentDidMount = () => {
    this.setState(this.props.group);
  }

  addNewSelect = (e) => {
    if (!this.state.nameSelect.length) { return; }
    if (!this.state.product_category_id) { return; }
    const s = this.state.selects || [];
    const selects = [...s];
    selects.push({
      id: UtilService.uuidv4(),
      name: this.state.nameSelect,
      area: 0,
      product_category_id: this.state.product_category_id,
      default_option_id: false,
      options: [],
      deleted: false,
      isNew: true,
    });
    this.setState({ selects, newSelectActive: false, nameSelect: '' }, () => { this.props.updateGroup(this.state); });
  }

  onChange = (e) => { this.setState({ [e.target.name]: e.target.value }, () => { this.props.updateGroup(this.state); }); }

  updateSelect = (updatedSelect) => {
    const s = this.state.selects || [];
    let selects = [...s];
    selects = selects.map((select) => (select.id === updatedSelect.id ? updatedSelect : select));

    this.setState({ selects }, () => { this.props.updateGroup(this.state); });
  }

  onChangeCat = (e) => {
    this.setState({ product_category_id: e.target.value });
  }

  deleteThis = () => {
    this.setState({ deleted: true }, () => { console.log(`Deleted ${this.state.id}`); this.props.updateGroup(this.state); });
  }

  activateNewSelect = () => {
    this.setState({ newSelectActive: 1 });
  }

  render() {
    if (this.state.deleted) { return <></>; }
    return <div className="group">
      <Row>
        <Col sm={2}>{I18n.t('views.materialforms.materialform-group')}</Col>
        <Col sm={9}><FormControl name="name" type="text" value={this.state.name} onChange={this.onChange} /></Col>
        <Col sm={1}><Trash onClick={() => { this.deleteThis(); }} className="clickable" /></Col>
      </Row>

      {!this.state.newSelectActive && <Row>
        <Col sm={2}></Col>
        <Col sm={10}>
          <Button variant="secondary" onClick={this.activateNewSelect}>{I18n.t('views.materialforms.button-add-selection')}</Button>
        </Col>
      </Row>}

      {this.state.newSelectActive && <Row>
        <Col sm={2}></Col>
        <Col sm={10}>
          <div className="typicalBox">
            <Row>
              <Col sm={2}>{I18n.t('views.materialforms.materialform-name')}</Col>
              <Col sm={10}><FormControl name="nameSelect" type="text" value={this.state.nameSelect} onChange={this.onChange} /></Col>
            </Row>

            <Row>
              <Col sm={2}>
                {I18n.t('views.materialforms.materialform-select-choose-productcategory')}
              </Col>
              <Col sm={10}>
                <Form.Control as="select" onChange={this.onChangeCat}>
                  <option value="" />
                  {this.props.productCategories.map((cat) => <option key={cat.id} value={cat.id}>{cat.name}</option>)}
                </Form.Control>
              </Col>
            </Row>

            <Button variant="secondary" onClick={this.addNewSelect}>{I18n.t('views.materialforms.button-add-selection')}</Button>
          </div>
        </Col>
      </Row>}

      {this.state.selects.map((select) => <MaterialFormSelectEditor key={select.id} select={select} updateSelect={this.updateSelect} productCategories={this.props.productCategories} />)}
    </div>;
  }
}

class MaterialFormSelectEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: false,
      name: '',
      nameOption: '',
      area: 0,
      product_category_id: false,
      default_option_id: false,
      options: [],
      products: [],
      product: false,
      loading: false,
      error: false,
    };
  }

  componentDidMount = () => {
    this.setState(this.props.select, () => {
      if (!this.state.default_option_id && this.state.options.length !== 0) {
        this.setState({ default_option_id: this.state.options[0].id });
      }
      if (this.props.select.product_category_id) {
        this.onSelectCatDirect(this.props.select.product_category_id);
      }
    });
  }

  onChange = (e) => { this.setState({ [e.target.name]: e.target.value }, () => { this.props.updateSelect(this.state); }); }

  onSelectCatDirect = (value) => {
    this.setState({ loading: true }, () => {
      APIService.productsGet(value).then((products) => {
        if (products.error) { return this.setState({ error: products.error }); }
        this.setState({
          products,
          loading: false,
          product_category_id: value,
        }, () => { this.props.updateSelect(this.state); });
      });
    });
  }

  onSelectCat = (e) => {
    const { value } = e.target;
    this.onSelectCatDirect(value);
  }

  onSelectProd = (e) => {
    const { value } = e.target;
    this.setState({
      product: value,
      loading: false,
    }, () => { this.props.updateSelect(this.state); });
  }

  getProductById = (id) => {
    // eslint-disable-next-line eqeqeq
    return this.state.products.find((x) => x.id == id);
  }

  addNewOption = (e) => {
    const prod = this.getProductById(this.state.product);
    if (!prod) { return; }

    const o = this.state.options || [];
    const options = [...o];

    const existing = options.find((o) => o.product_id === this.state.product);
    if (existing) { return; }

    options.push({
      id: UtilService.uuidv4(),
      name: prod.name,
      price: prod.price,
      product_id: this.state.product,
      isNew: true,
    });
    this.setState({
      options,
      default_option_id: options.length === 1 ? options[0].id : this.state.default_option_id,
      nameOption: '',
    }, () => { this.props.updateSelect(this.state); });
  }

  updateOption = (updatedOption) => {
    const o = this.state.options || [];
    let options = [...o];
    options = options.map((option) => (option.id === updatedOption.id ? updatedOption : option));

    this.setState({ options }, () => { this.props.updateSelect(this.state); });
  }

  deleteThis = () => {
    this.setState({ deleted: true }, () => { this.props.updateSelect(this.state); });
  }

  changeDefaultOptionId = (target) => {
    this.setState({ default_option_id: target }, () => { this.props.updateSelect(this.state); });
  }

  namesorter = function (a, b) {
    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase();
    if (nameA < nameB) { return -1; }
    if (nameA > nameB) { return 1; }
    return 0;
  }

  render() {
    if (this.state.deleted) { return <></>; }
    if (this.state.error) { return <Error error={this.state.error} />; }
    if (this.state.loading) { return <Loading />; }

    let catName = '';
    if (this.state.product_category_id) {
      // eslint-disable-next-line eqeqeq
      const target = this.props.productCategories.find((x) => x.id == this.state.product_category_id);
      if (target) {
        catName = target.name;
      }
    }

    return <div className="select">
      <Row>
        <Col sm={2}>{I18n.t('views.materialforms.materialform-selection')}</Col>
        <Col sm={9}>
          <FormControl name="name" type="text" value={this.state.name} onChange={this.onChange} />
        </Col>
        <Col sm={1}><Trash onClick={this.deleteThis} className="clickable" /></Col>
      </Row>
      <Row>
        <Col sm={2}></Col>
        <Col sm={4}>{I18n.t('views.products.productcategory')}</Col>
        <Col sm={5}>
          <FormControl type="text" readOnly value={catName} />
        </Col>
        <Col sm={1}></Col>
      </Row>
      <Row>
        <Col sm={2}></Col>
        <Col sm={4}>{I18n.t('general.areasqm')}</Col>
        <Col sm={5}>
          <FormControl name="area" type="number" value={this.state.area} onChange={this.onChange} />
        </Col>
        <Col sm={1}></Col>
      </Row>
      <Row>
        <Col sm={2}></Col>
        <Col sm={10}>
          {this.state.options.length > 0 && <div className="typicalBox">
            {this.state.options.sort(this.namesorter).map((option) => <MaterialFormOptionEditor
                defaultOptionId={this.state.default_option_id}
                changeDefaultOptionId={this.changeDefaultOptionId}
                option={option}
                key={option.id}
                updateOption={this.updateOption} />)}
          </div>}
          <Row className="optionBox">
            <Col sm={4}>{I18n.t('views.products.products')}</Col>
            <Col sm={5}>
              <Form.Control as="select" onChange={this.onSelectProd}>
                <option value="" />
                {this.state.products.map((prod) => <option key={prod.id} value={prod.id}>{prod.name}</option>)}
              </Form.Control>
            </Col>
            <Col sm={3}>
              <Button variant="secondary" onClick={this.addNewOption}>
                {I18n.t('views.materialforms.button-add-option')}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>;
  }
}

class MaterialFormOptionEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: false,
      price: 0,
      name: '-',
      product_id: 0,
      isNew: true,
    };
  }

  componentDidMount() {
    this.setState(this.props.option);
  }

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    }, () => { this.props.updateOption(this.state); });
  }

  deleteThis = () => {
    this.setState({ deleted: true }, () => { this.props.updateOption(this.state); });
  }

  onclick = () => {
    if (this.state.id !== this.props.defaultOptionId) {
      this.props.changeDefaultOptionId(this.state.id);
    }
  }

  render() {
    if (this.state.deleted) { return <></>; }

    const isDefault = this.state.id === this.props.defaultOptionId;

    return <Form.Group as={Row}>
      <Col sm={1}>
        {isDefault && <Form.Check.Input checked={true} type="radio" readOnly={true} className="inlineradio" />}
        {!isDefault && <Form.Check.Input checked={false} type="radio" onChange={this.onclick} className="inlineradio" />}
      </Col>
      <Col sm={8}><FormControl name="name" type="text" value={this.state.name} onChange={this.onChange} /></Col>
      <Col sm={2}><FormControl name="price" type="text" value={this.state.price} onChange={this.onChange} /></Col>
      <Col sm={1}><Trash onClick={() => { this.deleteThis(); }} className="clickable" /></Col>
    </Form.Group>;
  }
}

export default EditMaterialformModal;
