import React, { Component } from 'react';
import { Tab } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import I18n from 'i18n-js';

import APIService from '../../../AvainiaTools/APIService.js';
import TabService from '../../../AvainiaTools/TabService.js';

import AvainiaPanel from '../../multiview/Panels/AvainiaPanel.js';
import Topbar from '../../multiview/Topbar/Topbar.js';
import Error from '../../multiview/Error/Error.js';
import Loading from '../../multiview/Loading/Loading.js';
import ModalCompanyEdit from '../../multiview/Modals/ModalCompanyEdit.js';

const Tabs = {
  base: 'base',
};

const Modals = {
  editCompany: 'edit-company',
};

class Condominium extends Component {
  constructor(props) {
    super(props);

    this.state = {
      company: { },
      tab: Tabs.base,
      modal: false,
      loading: true,
      error: false,
    };
  }

  componentDidMount() {
    const { id } = this.props.match.params;

    if (!id) { return this.setState({ error: 2 }); }

    APIService.companyGet(id).then((company) => {
      if (company.error) { return this.setState({ error: company.error }); }

      this.setState({
        company,
        loading: false,
      });
    });
  }

  componentDidUpdate() {
    const tab = TabService.getTab() || Tabs.base;
    if (this.state.tab !== tab) {
      this.setState({ tab });
    }
  }

  tabChange = (tab) => {
    TabService.updateUrl(tab);
    this.setState({ tab });
  }

  editCompanyCallback = (company) => {
    window.location.reload(); // TODO: Improve
  }

  editCompany = (e) => {
    this.setState({ modal: Modals.editCompany });
  }

  hideModal = (e) => {
    this.setState({ modal: false });
  }

  render() {
    if (this.state.error) { return <Error error={this.state.error} />; }
    if (this.state.loading) { return <Loading />; }

    return <div className="App-main">
      <Topbar tabKey={this.state.tab} tabChangeCallback={this.tabChange}>
        <Tab eventKey="base" title={this.state.company.name}></Tab>
      </Topbar>

      {this.state.tab === 'base' &&
      <div className="AvainiaPanel">
        <AvainiaPanel header>
          <div className="text">
            <h2>{this.state.company.name}</h2>

            <button onClick={this.editCompany} className="btn btn-primary"> {I18n.t('views.companies.manage-company')} </button>

            {this.state.modal === Modals.editCompany &&
              <ModalCompanyEdit
                disableRemoval
                editCompanyCallback={this.editCompanyCallback}
                companyToEdit={this.state.company}
                onHide={this.hideModal}
              />
            }

          </div>
        </AvainiaPanel>
      </div>
      }
    </div>;
  }
}

export default withRouter(Condominium);
