import React, { Component } from 'react';
import { Button, Modal, Row, Form, Col } from 'react-bootstrap';
import I18n from 'i18n-js';
import APIService from '../../../AvainiaTools/APIService.js';
import AvainiaPermissions from '../../../AvainiaTools/AvainiaPermissions.js';
import LocalStorageService from '../../../AvainiaTools/LocalStorageService.js';
import Error from '../Error/Error.js';
import Loading from '../Loading/Loading.js';

class ModalProjectEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      code: '',
      name: '',
      status: '',
      type: '',
      loading: false,
      error: false,
    };
  }

  componentDidMount = () => {
    this.setState({
      code: this.props.project.code,
      name: this.props.project.name,
      status: this.props.project.status,
      type: this.props.project.type,
    });
  }

  save = () => {
    const projectId = this.props.project.id;
    const payload = {
      code: this.state.code,
      name: this.state.name,
      status: this.state.status,
      type: this.state.type,
      active: true,
    };

    this.setState({ loading: 1, error: false }, () => {
      APIService.projectsEdit(projectId, payload).then((result) => {
        if (result.error) { return this.setState({ error: result.error, loading: false }); }

        return this.setState({ loading: false, error: false }, () => {
          this.props.editCallback(projectId, payload);
          this.props.onHide();
        });
      });
    });
  }

  delete = () => {
    const projectId = this.props.project.id;

    if (!window.confirm(I18n.t('views.projects.confirm-delete'))) { return; }

    this.setState({ loading: 1, error: false }, () => {
      APIService.projectsDelete(projectId).then((result) => {
        if (result.error) { return this.setState({ error: result.error, loading: false }); }

        this.props.projectDeleteCallback(projectId);
        this.props.onHide();
      });
    });
  }

  setCode = (e) => {
    const code = e.target.value;
    this.setState({ code });
  };

  setName = (e) => {
    const name = e.target.value;
    this.setState({ name });
  };

  setStatus = (e) => {
    const status = e.target.value;
    this.setState({ status });
  };

  setType = (e) => {
    const type = e.target.value;
    this.setState({ type });
  }

  render() {
    const user = LocalStorageService.getUser();
    const canDelete = user.hasPermission(AvainiaPermissions.ProjectsManage);

    return <Modal show={true} onHide={this.props.onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{I18n.t('views.projects.edit-project')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {this.state.error && <Error inline error={this.state.error} />}
        {!this.state.error && this.state.loading && <Loading inline />}
        {!this.state.error && !this.state.loading && <div>
          <Form.Group as={Row}>
            <Form.Label column sm="2">{I18n.t('views.projects.project-code')}</Form.Label>
            <Col sm="10"><Form.Control type="text" onChange={this.setCode} name="code" value={this.state.code} /></Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="2">{I18n.t('views.projects.project-name')}</Form.Label>
            <Col sm="10"><Form.Control type="text" onChange={this.setName} name="name" value={this.state.name} /></Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm="2">{I18n.t('views.projects.status')}</Form.Label>
            <Col sm="10"><Form.Control as="select" onChange={this.setStatus} value={this.state.status}>
              <option value="active">{I18n.t('views.projects.statuses.active')}</option>
              <option value="before">{I18n.t('views.projects.statuses.before')}</option>
              <option value="during">{I18n.t('views.projects.statuses.during')}</option>
              <option value="after">{I18n.t('views.projects.statuses.after')}</option>
              <option value="inactive">{I18n.t('views.projects.statuses.inactive')}</option>
            </Form.Control></Col>
          </Form.Group>

          {!this.props.disableType &&
            <Form.Group as={Row}>
              <Form.Label column sm="2">{I18n.t('views.projects.type')}</Form.Label>
              <Col sm="10"><Form.Control as="select" onChange={this.setType} value={this.state.type}>
                <option value="condominium">{I18n.t('views.projects.condominium')}</option>
                <option value="infrastructure">{I18n.t('views.projects.infrastructure')}</option>
                <option value="marine">{I18n.t('views.projects.marine')}</option>
              </Form.Control></Col>
            </Form.Group>
          }
        </div>
        }
      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" disabled={this.state.loading} onClick={this.save}>{I18n.t('general.modal-form-save')}</Button>
        {canDelete && <Button variant="primary" disabled={this.state.loading} onClick={this.delete}>{I18n.t('general.delete')}</Button>}
        <Button variant="secondary" onClick={this.props.onHide}>{I18n.t('general.modal-close')}</Button>
      </Modal.Footer>
    </Modal>;
  }
}

export default ModalProjectEdit;
