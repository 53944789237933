
import React, { Component } from 'react';
import { DropdownButton, Dropdown, Badge } from 'react-bootstrap';
import I18n from 'i18n-js';
import { Link } from 'react-router-dom';
import { AngleLeft, Envelope } from '../Icon/Icon.js';
import LocalStorageService from '../../../AvainiaTools/LocalStorageService.js';
import APIService from '../../../AvainiaTools/APIService.js';
import ModalChangeUserPhoto from '../Modals/ModalChangeUserPhoto.js';
import AvainiaPermissions from '../../../AvainiaTools/AvainiaPermissions.js';
import ModalDocumentDeleteRequests from '../Modals/ModalDocumentDeleteRequests.js';
import './Sidebar.scss';

const Modals = {
  documentDeleteRequests: 'documentDeleteRequests',
  changePhoto: 'changePhoto',
};

const languagesDictionary = { // TODO: Refactor this somewhere, translations.js? Translations.js?
  en: 'English',
  fi: 'Suomeksi',
};

class Sidebar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userphoto: '',
      documentDeleteRequests: [],
      modal: false,
    };
  }

  componentDidMount() {
    const user = LocalStorageService.getUser();

    APIService.userPhotoGet(user.id).then((userphoto) => {
      if (!userphoto.hasOwnProperty('error')) { this.setState({ userphoto }); }
    });

    const deleteRequestsManage = user && user.hasPermission(AvainiaPermissions.DocumentDeleteRequestsManage);
    if (deleteRequestsManage) {
      this.refreshDeleteRequests();
    }
  }

  refreshDeleteRequests = () => {
    APIService.documentDeleteRequestsGet().then((results) => {
      if (results.error) {
        return console.error('Error getting image delete requests!', results.error);
        // TODO! This is a fatal error, needs to reported
      }

      this.setState({
        modal: false,
        documentDeleteRequests: results.filter((x) => !x.is_deleted),
      });
    });
  }

  logout = () => {
    LocalStorageService.clearUser();
    window.location.reload();
  };

  languageSelectHandler = (e) => {
    LocalStorageService.setLang(e);
    window.location.reload();
  }

  hideModal = () => { this.setState({ modal: false }); }

  showUserPhotoModal = () => {
    this.setState({ modal: Modals.changePhoto });
  }

  showDeleteRequests = (e) => {
    e.stopPropagation();
    this.setState({ modal: Modals.documentDeleteRequests });
  }

  userphotoCallback = (userphoto) => {
    this.setState({ userphoto });
  }

  renderUserPhoto = (user) => {
    if (this.state.userphoto !== '') {
      return <img src={this.state.userphoto} alt={I18n.t('views.sidebar.user-photo-alt')} />;
    }

    const initials = user.name.split(' ').map((part) => part[0]).join('');
    return initials;
  }

  render() {
    const { cfg, user } = this.props;
    const companies = user.companies || [];

    const deleteRequestsManage = user && user.hasPermission(AvainiaPermissions.DocumentDeleteRequestsManage);

    return <div className="Sidebar">
      <div className="Sidebar-inner">
        <div className="Sidebar-toggleBtn btn btn-highlight btn-sm" onClick={() => document.querySelector('.App').classList.toggle('is-sidebarActive')}>
          <AngleLeft />
        </div>

        <header className="Sidebar-user">
          <div className="Sidebar-user-pic" title={user.name} onClick={this.showUserPhotoModal}>
            {this.renderUserPhoto(user)}

            {/* TODO */}
            {cfg.messaging && deleteRequestsManage &&
              <button className="Sidebar-user-pic-messageBtn btn btn-highlight" onClick={this.showDeleteRequests}>
                <Envelope />
                {this.state.documentDeleteRequests.length > 0 &&
                  <Badge className="Sidebar-user-pic-messageBtn-badge" variant="danger">
                    {this.state.documentDeleteRequests.length}
                  </Badge>
                }
              </button>
            }
          </div>
          <a href="#none" className="Sidebar-user-name">{user.name}</a> {/* TODO: Add link */}
          <div className="Sidebar-user-companies">{companies.map((c) => c.name).join(', ')}</div>
          <div className="Sidebar-user-links">
            {false && <a href="#none" className="Sidebar-user-profile">{I18n.t('views.sidebar.profile')}</a>} {/* TODO: Add translation & link */}
            {true &&
              <Link to="/" className="Sidebar-user-logOut" onClick={this.logout}>{I18n.t('views.sidebar.link-logout')}</Link>
            }
          </div>
        </header>

        <nav className="Sidebar-nav">
          {this.props.AvainiaLinks}
        </nav>

        <footer className="Sidebar-footer">
          <DropdownButton
            alignRight
            drop="up"
            size="sm"
            variant="light"
            onSelect={this.languageSelectHandler}
            title={languagesDictionary[I18n.currentLocale()]}
          >
            {Object.keys(languagesDictionary).map((key) => <Dropdown.Item key={key} eventKey={key}>{languagesDictionary[key]}</Dropdown.Item>)}
          </DropdownButton>
        </footer>

        <ModalChangeUserPhoto
          show={this.state.modal === Modals.changePhoto}
          onHide={this.hideModal}
          userphotocallback={this.userphotoCallback}
        />
        <ModalDocumentDeleteRequests
          show={this.state.modal === Modals.documentDeleteRequests}
          onHide={this.hideModal}
          documentDeleteRequests={this.state.documentDeleteRequests}
          refreshDeleteRequests={this.refreshDeleteRequests}
        />
      </div>
    </div>;
  }
}

export default Sidebar;
