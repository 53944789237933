import React, { Component } from 'react';
import './Lightbox.scss';

class Lightbox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      current: 0,
    };
  }

  render() {
    if (this.state.loading) {
      return <></>;
    }

    if (!this.props.images.length) {
      return <></>;
    }

    return <div onClick={this.props.close} className="Lightbox">
      <div className="Lightbox-inner" onClick={(e) => { e.stopPropagation(); }}>
        <div className="Lightbox-header">
        </div>
        <div className="Lightbox-body">
          <button onClick={this.props.close} className="Lightbox-close" > x </button>
          <img alt="lightbox-main" src={this.props.images[this.state.current]} />
        </div>
        <div className="Lightbox-footer">
          {this.props.images.length > 1 &&
            <div className="Lightbox-thumbnails">
              {this.props.images.map((img, index) => <img
                  alt="lightbox-thumbnail"
                  className={index === this.state.current && 'active'}
                  key={index}
                  onClick={() => { this.setState({ current: index }); }}
                  data-index={index}
                  src={img}
                />)}
            </div>
          }
        </div>
      </div>
    </div>;
  }
}

export default Lightbox;
