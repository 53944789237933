import React, { Component } from 'react';
import Toast from 'react-bootstrap/Toast';
import { Key } from '../Icon/Icon.js';
import I18n from 'i18n-js';

class TimeoutToast extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: true,
    };
  }

  close = () => {
    this.setState({ show: false });
  }

  render() {
    return <Toast
      style={{
        position: 'absolute',
        width: '300px',
        marginLeft: '-150px',
        bottom: '20px',
        left: '50%',
        zIndex: 999,
      }}
      show={this.state.show}
      onClose={this.close}
    >
        <Toast.Header>
          <Key/>
          <strong className="mr-auto ml-1">{I18n.t('views.toasts.timeout.session-timeout')}</strong>
          <small>{I18n.t('views.toasts.timeout.when')}</small>
        </Toast.Header>
        <Toast.Body>
          {I18n.t('views.toasts.timeout.timeout-message')}
        </Toast.Body>
      </Toast>;
  }
}

export default TimeoutToast;
