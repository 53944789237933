import React, { Component } from 'react';
import Topbar from '../../multiview/Topbar/Topbar.js';
import PanelDefaultfolders from '../../multiview/Panels/PanelDefaultfolders.js';
import './Admin.scss';

export default class Admin extends Component {
  render() {
    return <div className="App-main">
      <Topbar />
      <div className="App-container" style={{ maxWidth: '100%' }}>
        <PanelDefaultfolders />
      </div>
    </div>;
  }
}
