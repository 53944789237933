/* _x instanceof TypeError */
import LocalStorageService from './LocalStorageService.js';

function rejectWrapper(thing, reject) {
  console.log('RejectWrapper is firing for', thing);
  if (thing instanceof TypeError) {
    // Most likely the core is down or unreachable -- check locally by simply shutting down the core while using the app

    // Show an error to the user - this is completely outside of react, defined in index.html
    // TODO: const e = document.getElementById("connection-issue-modal");
    // TODO: e.classList.add("active");
    // TODO: Add loop to retry connection and show the user that this happens

    console.error('TypeError when contacting the core (core unavailable?), showing alert to user and retrying...');
    reject(thing);
    return;
  }

  console.error('Unknown error when contacting core', thing);
  reject(thing);
}

export async function PostData(type, data) {
  const BaseURL = `${process.env.REACT_APP_API_HOST}/api/v1/`;
  const Payload = data instanceof FormData ? data : JSON.stringify(data);

  return new Promise((resolve, reject) => {
    const payload = {
      method: 'POST',
      body: Payload,
      headers: {
        Accept: 'application/json',
      },
    };

    if (!(data instanceof FormData)) { payload.headers['Content-Type'] = 'application/json'; }

    const token = LocalStorageService.getToken();
    if (token) {
      payload.headers.Authorization = `Bearer ${token}`;
    }

    fetch(BaseURL + type, payload)
      .then((response) => {
        if (response.status > 299 || response.status < 199) {
          return reject({
            error: 'Request error',
            status: response.status,
            statusText: response.statusText,
          });
        }

        response = response.json();
        return resolve(response);
      }).catch((error) => { rejectWrapper(error, reject); });
  });
}

export async function RawGet(url, settings) {
  const BaseURL = `${process.env.REACT_APP_API_HOST}/`;

  settings = settings || {};

  return new Promise((resolve, reject) => {
    const payload = {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };

    if (settings.tokenify) {
      const token = LocalStorageService.getToken();
      if (token) {
        payload.headers.Authorization = `Bearer ${token}`;
      }
    }

    fetch(BaseURL + url, payload)
      .then((response) => {
        if (response.status > 299 || response.status < 199) {
          return reject({
            error: 'Request error',
            status: response.status,
            statusText: response.statusText,
          });
        }

        if (settings.text) {
          response = response.text();
        } else {
          response = response.json();
        }

        return resolve(response);
      }).catch((error) => { rejectWrapper(error, reject); });
  });
}

export async function GetData(type) {
  const BaseURL = `${process.env.REACT_APP_API_HOST}/api/v1/`;

  return new Promise((resolve, reject) => {
    const payload = {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };

    const token = LocalStorageService.getToken();
    if (token) {
      payload.headers.Authorization = `Bearer ${token}`;
    }

    fetch(BaseURL + type, payload)
      .then((response) => {
        if (response.status > 299 || response.status < 199) {
          return reject({
            error: 'Request error',
            status: response.status,
            statusText: response.statusText,
          });
        }

        response = response.json();
        return resolve(response);
      }).catch((error) => { rejectWrapper(error, reject); });
  });
}

export async function DeleteData(type) {
  const BaseURL = `${process.env.REACT_APP_API_HOST}/api/v1/`;

  return new Promise((resolve, reject) => {
    const payload = {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };

    const token = LocalStorageService.getToken();
    if (token) {
      payload.headers.Authorization = `Bearer ${token}`;
    }

    fetch(BaseURL + type, payload)
      .then((response) => {
        if (response.status > 299 || response.status < 199) {
          return reject({
            error: 'Request error',
            status: response.status,
            statusText: response.statusText,
          });
        }

        response = response.json();
        return resolve(response);
      }).catch((error) => { rejectWrapper(error, reject); });
  });
}

export async function PutData(type, data, useFormData = false) {
  const BaseURL = `${process.env.REACT_APP_API_HOST}/api/v1/`;

  const formData = new FormData();

  if (useFormData) {
    for (const key in data) {
      formData.append(key, data[key]);
    }
  }

  return new Promise((resolve, reject) => {
    const payload = {
      method: useFormData ? 'POST' : 'PUT',
      body: useFormData ? formData : JSON.stringify(data),
      headers: {
        Accept: 'application/json',
      },
    };

    if (!useFormData) { payload.headers['Content-Type'] = 'application/json'; }

    const token = data.token || LocalStorageService.getToken();
    if (token) {
      payload.headers.Authorization = `Bearer ${token}`;
    }

    let fetchUrl = BaseURL + type;
    if (useFormData) { fetchUrl += '?_method=PUT'; }
    fetch(fetchUrl, payload)
      .then((response) => {
        if (response.status > 299 || response.status < 199) {
          return reject({
            error: 'Request error',
            status: response.status,
            statusText: response.statusText,
          });
        }

        if (typeof response.length !== 'undefined') {
          response = response.length > 0 ? response = response.json() : response;
          return resolve(response);
        }
        if (response.json) {
          response = response.json();
          return resolve(response);
        }
        console.log('Unexpected response state after POST!!');
        return resolve();
      }).catch((error) => { rejectWrapper(error, reject); });
  });
}

export async function GetImage(type) {
  const BaseURL = `${process.env.REACT_APP_API_HOST}/api/v1/`;

  return new Promise((resolve, reject) => {
    const payload = {
      method: 'GET',
      headers: {
        Accept: 'image/*',
        'Content-Type': 'image/*',
      },
    };

    const token = LocalStorageService.getToken();
    if (token) {
      payload.headers.Authorization = `Bearer ${token}`;
    }

    fetch(BaseURL + type, payload)
      .then((response) => {
        if (response.status > 299 || response.status < 199) {
          return reject({
            error: 'Request error',
            status: response.status,
            statusText: response.statusText,
          });
        }
        return resolve(response.blob());
      }).catch((error) => { rejectWrapper(error, reject); });
  });
}

export async function PostUserPhoto(type, photo) {
  const BaseURL = `${process.env.REACT_APP_API_HOST}/api/v1/`;
  const EndURL = '?_method=PUT';

  return new Promise((resolve, reject) => {
    const payload = {
      method: 'POST',
      headers: {
        accept: '*/*',
      },
      body: photo,
    };

    const token = LocalStorageService.getToken();
    if (token) {
      payload.headers.Authorization = `Bearer ${token}`;
    }

    fetch(BaseURL + type + EndURL, payload)
      .then((response) => {
        if (response.status > 299 || response.status < 199) {
          return reject({
            error: 'Request error',
            status: response.status,
            statusText: response.statusText,
          });
        }
        return resolve(response);
      }).catch((error) => { rejectWrapper(error, reject); });
  });
}
