import React, { Component } from 'react';
import './AvainiaPanel.scss';

class AvainiaPanel extends Component {
  render() {
    let className = 'AvainiaPanel-panel';

    if (this.props.double) { className += ' AvainiaPanel-panel--double'; }
    if (this.props.header) { className += ' AvainiaPanel-panel--header'; } else {
      if (!this.props.heading) { return <h4 className="AvainiaPanel-panel">Misconfigured panel - missing heading!</h4>; }
      if (!this.props.icon) { return <h4 className="AvainiaPanel-panel">Misconfigured panel - missing icon!</h4>; }
    }

    return <div className={className}>
      <div className="AvainiaPanel-content">
        {!this.props.header && <>
          <div className="AvainiaPanel-icon">
            {this.props.icon}
          </div>
          <h3 className="AvainiaPanel-heading">{this.props.heading}</h3>
        </>}
        {this.props.children}
      </div>
    </div>;
  }
}

export default AvainiaPanel;
