import React, { Component } from 'react';
import { Button, Modal, Row, Form, Col } from 'react-bootstrap';
import I18n from 'i18n-js';
import APIService from '../../../AvainiaTools/APIService.js';
import Error from '../../multiview/Error/Error.js';
import Loading from '../../multiview/Loading/Loading.js';

class ProductCategoryEditModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      loading: false,
      error: false,
    };
  }

  componentDidMount = () => {
    this.setState({
      name: this.props.productCategory.name,
    });
  }

  save = () => {
    const productId = this.props.productCategory.id;
    const payload = {
      name: this.state.name,
    };
    this.setState({ loading: 1, error: false }, () => {
      APIService.productCategoriesEdit(productId, payload).then((result) => {
        if (result.error) { return this.setState({ error: result.error, loading: false }); }

        return this.setState({ loading: false, error: false }, () => {
          this.props.editCallback(productId, payload.name);
          this.props.onHide();
        });
      });
    });
  }

  onChange = (e) => {
    const name = e.target.value;
    this.setState({ name });
  }

  render() {
    return <Modal show={true} onHide={this.props.onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{I18n.t('views.products.edit-product-category')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {this.state.error && <Error inline error={this.state.error} />}
        {!this.state.error && this.state.loading && <Loading inline />}
        {!this.state.error && !this.state.loading && <div>
          <Form.Group as={Row}>
            <Form.Label column sm="2">{I18n.t('views.products.product-category-label')}</Form.Label>
            <Col sm="10">
              <Form.Control type="text" onChange={this.onChange} name="name" value={this.state.name} />
            </Col>
          </Form.Group>
        </div>}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" disabled={this.state.loading} onClick={this.save}>{I18n.t('general.modal-form-save')}</Button>
        <Button variant="secondary" onClick={this.props.onHide}>{I18n.t('general.modal-close')}</Button>
      </Modal.Footer>
    </Modal>;
  }
}

export default ProductCategoryEditModal;
