const avainiaYellow = '#f3db3d';
const avainiaPurple = '#56276a';

// TODO: refactor

export const selectStyles = {
  option: (base) => ({
    ...base,
    color: 'black',
    fontSize: 16,
    textAlign: 'left',
    cursor: 'pointer',
    backgroundColor: '#fff',
    '&:hover': {
      backgroundColor: avainiaYellow,
    },
  }),
  container: (base) => ({
    ...base,
    width: '100%',
  }),
  control: (base, state) => ({
    ...base,
    height: 40,
    minHeight: 40,
    fontSize: 16,
    borderRadius: 0,
    width: '100%',
    textAlign: 'left',
    cursor: 'pointer',
    borderColor: state.isFocused ? avainiaYellow : base.borderColor,
    boxShadow: null,
    '&:hover': {
      borderColor: avainiaYellow,
      dropdownIndicator: (base) => ({
        ...base,
        color: avainiaYellow,
      }),
    },
  }),
  indicatorsContainer: (base) => ({
    ...base,
    height: 39,
    minHeight: 39,
    backgroundColor: avainiaPurple,
    border: `1px solid ${avainiaPurple}`,
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
    color: state.selectProps.menuIsOpen ? avainiaYellow : 'white',
    '&:hover': {
      color: avainiaYellow,
    },
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: 'none',
  }),
  valueContainer: (base) => ({
    ...base,
    padding: 0,
    paddingLeft: 10,
  }),
  menu: (base) => ({
    ...base,
    backgroundColor: '#F8F8F8',
    margin: 0,
  }),
  menuList: (base) => ({
    ...base,
    padding: 0,
    cursor: 'pointer',
  }),
};
