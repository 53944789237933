import React, { Component } from 'react';
import I18n from 'i18n-js';
import { Form, Button, Row, Modal } from 'react-bootstrap';
import Error from '../../../multiview/Error/Error.js';
import Loading from '../../../multiview/Loading/Loading.js';
import CustomSelect from '../../../multiview/Select/CustomSelect.js';
import APIService from '../../../../AvainiaTools/APIService.js';

class CompaniesTabModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedCompany: null,
      loading: false,
      error: false,
    };
  }

  handleCompanySelectChange = (name, value) => {
    const company = this.props.companies.find((x) => x.code === value.value);
    this.setState({ selectedCompany: company });
  }

  save = (e) => {
    const { selectedCompany } = this.state;

    const roleEmployee = selectedCompany.roles.find((role) => role.display_name === 'employee');

    // TODO! Hardcoding the contentPermission type, should it be done?
    APIService.contentPermissionAdd(roleEmployee, 'App\\Project', this.props.project.id).then((result) => {
      if (result.error) { return this.setState({ error: result.error }); }

      this.props.addCallback(selectedCompany);
      this.props.onHide();
    });
  }

  remove = () => {
    const { companyToEdit } = this.props;
    const roleEmployee = companyToEdit.roles.find((role) => role.display_name === 'employee');
    const contentPermission = this.props.project.contentPermissions.find((x) => x.role_id === roleEmployee.id);

    APIService.contentPermissionRemove(contentPermission).then((result) => {
      if (result.error) { return this.setState({ error: result.error }); }

      this.props.removeCallback(companyToEdit);
      this.props.onHide();
    });
  }

  render() {
    if (this.state.error) { return <Error error={this.state.error} />; }
    if (this.state.loading) { return <Loading />; }

    const alreadySelectedCompanies = this.props.project.companies;
    const allCompanies = this.props.companies;
    const selectableCompanies = allCompanies.filter((x) => !alreadySelectedCompanies.find((y) => y.id === x.id));
    const selections = selectableCompanies.map((x) => {
      return {
        label: x.name,
        value: x.code,
      };
    });

    return <Modal show={true} onHide={this.props.onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>
          {this.props.companyToEdit && I18n.t('views.projects.companies.attached-company')}
          {!this.props.companyToEdit && I18n.t('views.projects.companies.attach-company')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="tinyform company-tab-modal">
          {!this.props.companyToEdit && <>
            <Form.Group as={Row}>
              <CustomSelect
                name='company'
                placeholder={''}
                handleChange={this.handleCompanySelectChange}
                selectOptions={selections}
                isSearchable={true}
              />
            </Form.Group>
            <Form.Group as={Row}>
              <input
                className="form-control"
                type="text"
                disabled
                placeholder={I18n.t('views.projects.companies.code')}
                defaultValue={(this.state.selectedCompany || {}).code}
              />
            </Form.Group>
          </>}
          {this.props.companyToEdit &&
            <Form.Group as={Row}>
              <span> {this.props.companyToEdit.name} </span>
            </Form.Group>
          }
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="company-buttons">
          {this.props.companyToEdit && <Button variant="danger" onClick={this.remove}>{I18n.t('general.delete')}</Button> }
          {!this.props.companyToEdit && <Button variant="success" disabled={this.state.loading} onClick={this.save}>{I18n.t('general.modal-form-save')}</Button> }
          <Button variant="secondary" onClick={this.props.onHide}>{I18n.t('general.cancel')}</Button>
        </div>
      </Modal.Footer>
    </Modal>;
  }
}

export default CompaniesTabModal;
