import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import { Close } from '../../../../multiview/Icon/Icon.js';
import markerIcon from '../../../../../images/marker.png';

// Based on https://cuneyt.aliustaoglu.biz/en/using-google-maps-in-react-without-custom-libraries/

const MapSettings = {
  apikey: 'AIzaSyCn6T7i_CYc8AFNuT3idK-M0PjLG07anN4',
  defaultCenter: { lat: 60.73142604597615, lng: 31.658685119061715 },
  zoom: 10,
  spidefierOptions: {
    maxZoom: 18,
    legWeight: 3,
    keepSpiderfied: 1,
    circleFootSeparation: 30,
    markersWontMove: true,
    markersWontHide: true,
  },
};

class Map extends Component {
  onGoogleApiLoaded = (config) => {
    const { maps, map } = config;
    window._map = map; // This is necessary! TODO: refactor

    const OverlappingMarkerSpiderfier = require('overlapping-marker-spiderfier');
    const MarkerClusterer = require('node-js-marker-clusterer');
    const oms = new OverlappingMarkerSpiderfier(map, MapSettings.spidefierOptions);

    const markers = this.props.images.filter((x) => x.coordinates).map((image) => {
      const marker = new maps.Marker({
        position: image.coordinates,
        map,
        label: image.counter,
        icon: {
          url: markerIcon,
          labelOrigin: new maps.Point(14, 14),
        },
      });

      marker.addListener('click', (e) => {
        this.props.activateImage(image, { dontCenter: true });
      });

      oms.addMarker(marker);

      return marker;
    });
    window._oms = oms;
    window._markers = markers;

    const closeFullscreen = document.createElement('div');
    closeFullscreen.className = 'closeFullscreen';
    closeFullscreen.style.top = '10px';
    closeFullscreen.style.marginRight = '3em';
    closeFullscreen.style.cursor = 'pointer';
    closeFullscreen.setAttribute('id', 'closeFullcreen');
    closeFullscreen.addEventListener('click', () => {
      document.getElementById('root').classList.remove('mobile-map-fullscreen');
    });

    map.controls[maps.ControlPosition.RIGHT_TOP].push(closeFullscreen);
    window._mc = new MarkerClusterer(map, markers, {
      imagePath: '/m/m',
      maxZoom: 16,
    });
  }

  getCenter = () => {
    if (this.props.allImages.length > 0) {
      const firstCoords = this.props.allImages.find((x) => x.coordinates);

      if (firstCoords) {
        return firstCoords.coordinates;
      }
    }

    return MapSettings.defaultCenter;
  }

  render() {
    return <div className="map-wrapper">
      <div className="map-close-button button">
        <Close onClick={this.props.toggleMapView} />
      </div>
      <div className="map-container">
        <GoogleMapReact
          bootstrapURLKeys={{ key: MapSettings.apikey }}
          defaultCenter={this.getCenter()}
          defaultZoom={MapSettings.zoom}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={this.onGoogleApiLoaded}
          options={{
            fullscreenControl: false,
            zoomControl: true,
            scaleControl: false,
            mapTypeControl: true,
            streetViewControl: true,
          }}
        />
      </div>
    </div>;
  }
}

export default Map;
