import React, { Component } from 'react';
import { Tab, Form, Button, Badge } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import I18n from 'i18n-js';
import Topbar from '../../../../multiview/Topbar/Topbar.js';
import Error from '../../../../multiview/Error/Error.js';
import Loading from '../../../../multiview/Loading/Loading.js';
import AvainiaPanel from '../../../../multiview/Panels/AvainiaPanel.js';
import CondominiumPanel from '../../../../multiview/Panels/CondominiumPanel.js';
import ContactPanel from '../../../../multiview/Panels/ContactPanel.js';
import SchedulePanel from '../../../../multiview/Panels/SchedulePanel.js';
import APIService from '../../../../../AvainiaTools/APIService.js';
import TabService from '../../../../../AvainiaTools/TabService.js';
import MaterialformsTab from './MaterialformsTab.js';
import CondominiumDocumentsPanel from './CondominiumDocumentsPanel.js';
import ContactTab from '../ContactTab.js';
import CompaniesTab from '../CompaniesTab.js';
import SchedulesTab from '../SchedulesTab.js';

const Tabs = {
  base: 'base',
};

class CondominiumProject extends Component {
  constructor(props) {
    super(props);

    this.state = {
      allCondominiums: [],
      apartments: [],
      contacts: [],
      schedules: [],
      tab: Tabs.base,
      selectedCondominium: false,
      redirect: false,
      loading: true,
      error: false,
    };
  }

  componentDidMount() {
    const { id } = this.props.project;

    const promises = [
      // TODO: Go through all of them and add the data attribute
      APIService.projectContactsGet(id, 'contacts'),
      APIService.projectCondominiumsGet(id, 'condominiums'),
      APIService.projectSchedulesGet(id, 'schedules'),
      APIService.condominiumsGet('allCondominiums'), // TODO: Improvement - Performance - this is only used in edge cases
    ];

    Promise.all(promises).then((data) => {
      let error = false;
      let allCondominiums = [];
      let apartments;
      let contacts;
      let schedules;

      data.forEach((x) => {
        if (error) { return; }
        if (x.error) { error = x.error; return; }
        if (x.contacts) { contacts = x.contacts; }
        if (x.schedules) { schedules = x.schedules; }
        if (x.allCondominiums) { allCondominiums = x.allCondominiums; } // TODO: Performance improvement: we only need this information if the project doesn't have a condominium set!!
      });

      if (error) { return this.setState({ error }); }

      const tab = TabService.getTab() || Tabs.base;
      this.setState({
        tab,
        allCondominiums,
        apartments,
        contacts,
        schedules,
        loading: false,
      });
    });
  }

  tabChange = (tab) => {
    TabService.updateUrl(tab);
    this.setState({ tab });
  }

  onChange = (e) => { this.setState({ [e.target.name]: e.target.value }); }

  saveCondoSelectionForProject = (e) => {
    if (!this.state.selectedCondominium) { return alert(I18n.t('views.project.warn-select-option-first')); }

    this.setState({ loading: true }, () => {
      APIService.projectCondominiumSet(this.props.project.id, this.state.selectedCondominium).then((result) => {
        if (result.error) { return this.setState({ error: result.error }); }

        window.location.reload(); // TODO: Improve
      });
    });
  }

  render() {
    if (this.state.error) { return <Error error={this.state.error} />; }
    if (this.state.loading) { return <Loading />; }

    // Make sure this Project has a condominium
    if (!this.props.project.condominiumProject) {
      return <div className="App-main">
        <Topbar />
        <div className="App-container">
          <h1>{I18n.t('views.projects.condominiumproject')}</h1>
          <Badge variant="warning" className="error">{I18n.t('views.projects.condominiumproject-needs-project')}</Badge>

          <h2>{I18n.t('views.condominiums.condominiums')}</h2>
          <Form.Control as="select" onChange={this.onChange} name="selectedCondominium">
            <option value="">-</option>
            {this.state.allCondominiums.map((x) => <option value={x.id} key={x.id}>{x.name}</option>)}
          </Form.Control>

          <div className="actions largebuffer">
            <Button size="sm" onClick={this.saveCondoSelectionForProject}>{I18n.t('general.modal-form-save')}</Button>
          </div>

        </div>
      </div>;
    }

    return <div className="App-main">
      <Topbar tabKey={this.state.tab} configModal={false && CondominiumConfigModal} tabChangeCallback={this.tabChange} userManagement={() => { this.tabChange('companies'); }}>
        <Tab eventKey="base" title={I18n.t('general.basicdata')}></Tab>
        <Tab eventKey="materialforms" title={I18n.t('views.materialforms.materialforms')}></Tab>
        <Tab eventKey="contact" title={I18n.t('views.contacts.contacts')}></Tab>
        <Tab eventKey="schedules" title={I18n.t('views.schedules.schedules')}></Tab>
      </Topbar>

      <div className="App-container">
        {this.state.tab === 'base' && <div className="AvainiaPanel">
          <AvainiaPanel header>
            <div className="text">
              <h2>{this.props.project.code} - {this.props.project.name}</h2>
            </div>
          </AvainiaPanel>
          <CondominiumPanel condominium={this.props.project.condominiumProject.condominium_id} />
          <ContactPanel project={this.props.project} />
          <SchedulePanel double project={this.props.project} />
          <CondominiumDocumentsPanel double project={this.props.project} />
        </div>}

        {this.state.tab === 'materialforms' && <MaterialformsTab condominiumId={this.props.project.condominiumProject.condominium_id} project={this.props.project} />}
        {this.state.tab === 'contact' && <ContactTab project={this.props.project} />}
        {this.state.tab === 'schedules' && <SchedulesTab project={this.props.project} />}
        {this.state.tab === 'companies' && <CompaniesTab project={this.props.project} />}
      </div>
    </div>;
  }
}

const CondominiumConfigModal = () => {
  return <div>TODO</div>;
};

export default withRouter(CondominiumProject);
