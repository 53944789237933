import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Error from '../../../multiview/Error/Error.js';
import Loading from '../../../multiview/Loading/Loading.js';
import APIService from '../../../../AvainiaTools/APIService.js';
import InfraProject from './InfraProject/InfraProject.js';
import CondominiumProject from './CondominiumProject/CondominiumProject.js';
import MarineProject from './MarineProject/MarineProject.js';

class Project extends Component {
  constructor(props) {
    super(props);

    const { id } = props.match.params;

    this.state = {
      project: { id },
      loading: true,
      error: false,
    };
  }

  componentDidMount() {
    const { id } = this.state.project;

    APIService.getProject(id).then((project) => {
      if (project.error) { return this.setState({ error: project.error }); }
      this.setState({ project, loading: false });
    });
  }

  render() {
    if (this.state.error) { return <Error error={this.state.error} />; }
    if (this.state.loading) { return <Loading />; }

    if (this.state.project.type === 'infrastructure') { return <InfraProject configuration={this.props.configuration} project={this.state.project} />; }
    if (this.state.project.type === 'condominium') { return <CondominiumProject configuration={this.props.configuration} project={this.state.project} />; }
    if (this.state.project.type === 'marine') { return <MarineProject configuration={this.props.configuration} project={this.state.project} />; }

    return <></>;
  }
}

export default withRouter(Project);
