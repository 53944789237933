import React, { Component } from 'react';
import I18n from 'i18n-js';
import Topbar from '../../multiview/Topbar/Topbar.js';

class Translations extends Component {
  handleObjectCompareRecursive = (a, b, base = '') => {
    const aKeys = Object.keys(a);
    let arr = [];

    aKeys.forEach((aKey) => {
      const path = `${base}.${aKey}`;

      if (!b.hasOwnProperty(aKey)) {
        return arr.push(path);
      }

      if (typeof b[aKey] === 'object') {
        arr = arr.concat(this.handleObjectCompareRecursive(a[aKey], b[aKey], path));
      }
    });

    return arr;
  }

  render() {
    const { translations } = I18n;
    const languages = Object.keys(translations).filter((l) => l !== 'en');

    return <div className="App-main">
      <Topbar />

      <div className="App-container">
        <h1>Missing translations</h1>
        <p>This view is meant for Avainia Developers. If you found it, congratulations! This is a list of missing translations.</p>

        {languages.map((lang) => <ul key={lang}>
            <li><b>Language: </b> {lang}</li>
            {this.handleObjectCompareRecursive(translations.en, translations[lang], lang).flat().map((row) =>
              <li key={row}>Missing translation: {row}</li>
            )}
          </ul>)}
      </div>
    </div>;
  }
}

export default Translations;
