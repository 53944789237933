import React, { Component } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import I18n from 'i18n-js';
import APIService from '../../../../AvainiaTools/APIService.js';

class DocumentTypesDeleteModal extends Component {
  deleteDocumentType = (e) => {
    APIService.documentTypesDelete(this.props.documentTypeId).then((result) => {
      if (!result.error) {
        this.props.deleteCallback();
        this.props.hideModal();
      }
    });
  }

  render() {
    return <Modal show={this.props.show} onHide={this.props.hideModal}>
      <Modal.Header closeButton>
        <Modal.Title>{I18n.t('documentType.delete-type')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group>
          <Form.Label>{I18n.t('documentType.confirm-delete')}</Form.Label>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={this.deleteDocumentType}>{I18n.t('general.delete')}</Button>
        <Button variant="secondary" onClick={this.props.hideModal}>{I18n.t('general.cancel')}</Button>
      </Modal.Footer>
    </Modal>;
  }
}

export default DocumentTypesDeleteModal;
