import APIService from './APIService.js';

export default class ConfigurationService {
  static updateConfig = async () => {
    return APIService.configurationGet();
  };

  static getConfiguration = async () => {
    if (!window.Avainia.config) {
      window.Avainia.config = await ConfigurationService.updateConfig();
    }

    if (window.Avainia.timeout) { window.clearTimeout(window.Avainia.timeout); }

    window.Avainia.timeout = window.setTimeout(() => {
      console.log('Forcing an update of the configuration next time it is needed');
      window.Avainia.config = false;
    }, 5 * 60 * 1000);

    return window.Avainia.config;
  };
}
