
import React, { Component } from 'react';
import I18n from 'i18n-js';
import { Trash, Edit, Link, File } from '../../multiview/Icon/Icon.js';
import { Form, Button, ListGroup, Badge } from 'react-bootstrap';
import Error from '../Error/Error.js';
import Loading from '../Loading/Loading.js';
import APIService from '../../../AvainiaTools/APIService.js';
import AvainiaPanel from './AvainiaPanel.js';
import EditMaterialformModal from '../Modals/EditMaterialformModal.js';
import LinkMaterialformModal from '../Modals/LinkMaterialformModal.js';

const Modals = {
  materialFormEdit: 1,
  materialFormLink: 2,
};

class MaterialformAdminPanel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      modal: false,
      materialformToEdit: false,
      materialformToLink: false,
      modalKeyHack: 1,
      loading: false,
      error: false,
    };
  }

  delete = (materialform) => {
    if (this.state.loading) { return; }
    if (!window.confirm(I18n.t('views.materialforms.confirm-delete'))) { return; } // TODO: Improve

    this.setState({ loading: true, error: false }, () => {
      APIService.projectMaterialFormsDelete(this.props.project.id, materialform.id).then((result) => {
        if (result.error) { return this.setState({ loading: false, error: result.error }); }

        window.location.reload(); // TODO: Improve
      });
    });
  }

  edit = (materialform) => {
    this.setState({ materialformToEdit: materialform, modal: Modals.materialFormEdit });
  }

  showLinkingModal = (materialformToLink) => {
    this.setState({ materialformToLink, modal: Modals.materialFormLink });
  }

  // TODO: Improve performance - replace this by mapping the materialforms to apartment templates directly during the view initialisation phase
  getApartmentTemplatesLinkedToMaterialform = (targetMaterialform) => {
    const found = [];
    this.props.apartmentTemplates.forEach((tmpl) => {
      if (!tmpl.materialforms) { return; }
      tmpl.materialforms.forEach((materialform) => {
        // eslint-disable-next-line eqeqeq
        if (materialform.id == targetMaterialform.id) {
          found.push(materialform);
        }
      });
    });
    return found.length === 0 ? false : found; // Oh yeaaah, who needs stable types?
  }

  refreshModal = () => {
    this.setState({ modalKeyHack: this.state.modalKeyHack + 1 });
  }

  createMaterialForm = (e) => {
    if (this.state.loading) { return; }

    this.setState({ loading: true, error: false }, () => {
      const payload = {
        name: this.state.name,
      };
      APIService.projectMaterialFormsCreate(this.props.project.id, payload).then((materialform) => {
        if (materialform.error) { return this.setState({ error: materialform.error, loading: false }); }

        window.location.reload(); // TODO: Improve
      });
    });
  }

  hideModal = (e) => { this.setState({ modal: false }); }

  onChange = (e) => { this.setState({ [e.target.name]: e.target.value }); }

  render() {
    if (this.state.error) { return <Error error={this.state.error} />; }
    if (this.state.loading) { return <Loading />; }

    return <AvainiaPanel double={this.props.double} icon={<File />} heading={I18n.t('views.materialforms.materialforms')}>
      <ListGroup className="listing">
        {this.props.materialforms.map((materialform) => <ListGroup.Item key={materialform.id}>
            {materialform.name}
            {this.getApartmentTemplatesLinkedToMaterialform(materialform) && <>
              &nbsp;
              <Badge variant="success">
                {I18n.t('general.active')}
              </Badge>
              <Link onClick={() => { this.showLinkingModal(materialform); }} className="clickable" />
            </>}
            {!this.getApartmentTemplatesLinkedToMaterialform(materialform) && <>
              <Badge variant="warning">
                {I18n.t('general.inactive')}
              </Badge>
              <Trash onClick={() => { this.delete(materialform); }} className="clickable" />
              <Edit onClick={() => { this.edit(materialform); }} className="clickable" />
              <Link onClick={() => { this.showLinkingModal(materialform); }} className="clickable" />
            </>}
          </ListGroup.Item>)}
      </ListGroup>

      <h3>{I18n.t('views.materialforms.create-new-materialform')}</h3>

      <Form.Group controlId="materialform-name">
        <Form.Label>{I18n.t('views.materialforms.materialform-name')}</Form.Label>
        <Form.Control type="text" onChange={this.onChange} name="name" value={this.state.name} />
      </Form.Group>
      {!this.state.loading &&
        <Button variant="primary" onClick={this.createMaterialForm}>{I18n.t('views.materialforms.button-create')}</Button>
      }

      {this.state.modal === Modals.materialFormEdit &&
        <EditMaterialformModal
          key={this.state.modalKeyHack}
          onHide={this.hideModal}
          materialform={this.state.materialformToEdit}
          project={this.props.project}
          refreshModal={this.refreshModal}
        />
      }

      {this.state.modal === Modals.materialFormLink &&
        <LinkMaterialformModal
          key={this.state.modalKeyHack}
          onHide={this.hideModal}
          materialform={this.state.materialformToLink}
          apartmentTemplates={this.props.apartmentTemplates}
          linked={this.getApartmentTemplatesLinkedToMaterialform(this.state.materialformToLink)}
          project={this.props.project}
        />
      }

    </AvainiaPanel>;
  }
}

export default MaterialformAdminPanel;
