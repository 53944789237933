import React, { Component } from 'react';
import { Tab } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import I18n from 'i18n-js';
import Topbar from '../../multiview/Topbar/Topbar.js';
import Error from '../../multiview/Error/Error.js';
import Loading from '../../multiview/Loading/Loading.js';
import APIService from '../../../AvainiaTools/APIService.js';
import TabService from '../../../AvainiaTools/TabService.js';
import CondominiumBasedataTab from './CondominiumBasedataTab.js';
import CondominiumApartmentsTab from './CondominiumApartmentsTab.js';
import CondominiumUsersTab from './CondominiumUsersTab.js';

const Tabs = {
  base: 'base',
  apts: 'apts',
  users: 'users',
};

class Condominium extends Component {
  constructor(props) {
    super(props);

    const { id } = props.match.params;

    this.state = {
      condominium: { id },
      apartmenttemplates: [],
      projects: [],
      tab: Tabs.base,
      redirect: false,
      loading: true,
      error: false,
    };
  }

  componentDidMount() {
    const { id } = this.state.condominium;

    APIService.condominiumGet(id).then((condominium) => {
      if (condominium.error) { return this.setState({ error: condominium.error }); }

      APIService.condominiumApartmentTemplatesGet(id).then((apartmenttemplates) => {
        if (apartmenttemplates.error) { return this.setState({ error: apartmenttemplates.error }); }

        APIService.condominiumProjectsGet(id).then((condominiumProjects) => {
          if (condominiumProjects.error) { return this.setState({ error: condominiumProjects.error }); }

          const promises = condominiumProjects.map((cp) => APIService.projectGet(cp.project_id));

          Promise.all(promises).then((projects) => {
            let error = false;
            projects.forEach((project) => {
              if (error) { return; }
              if (project.error) { error = project.error; }
            });

            if (error) { return this.setState({ error }); }

            this.setState({ condominium, projects, apartmenttemplates, loading: false });
          });
        });
      });
    });
  }

  componentDidUpdate() {
    const tab = TabService.getTab() || Tabs.base;
    if (this.state.tab !== tab) {
      this.setState({ tab });
    }
  }

  tabChange = (tab) => {
    TabService.updateUrl(tab);
    this.setState({ tab });
  }

  aptTemplateCreateCallback = (apartmentTemplate) => {
    const { apartmenttemplates } = this.state;
    apartmenttemplates.push(apartmentTemplate);
    this.setState({ apartmenttemplates });
  }

  aptTemplateDeleteCallback = (result) => {
    let { apartmenttemplates } = this.state;
    apartmenttemplates = apartmenttemplates.filter((x) => x.id !== result.id);
    this.setState({ apartmenttemplates });
  }

  aptTemplateEditCallback = (id, name) => {
    const { apartmenttemplates } = this.state;

    const target = apartmenttemplates.find((x) => x.id === id);
    if (target) { target.name = name; }

    this.setState({ apartmenttemplates });
  }

  render() {
    if (this.state.error) { return <Error error={this.state.error} />; }
    if (this.state.loading) { return <Loading />; }

    return <div className="App-main">
      <Topbar
        userManagement={() => { this.tabChange(Tabs.users); }}
        tabKey={this.state.tab}
        tabChangeCallback={this.tabChange}
      >
        <Tab eventKey={Tabs.base} title={I18n.t('views.condominiums.tab-basedata')}></Tab>
        <Tab eventKey={Tabs.apts} title={I18n.t('views.condominiums.tab-apartments')}></Tab>
      </Topbar>

      {this.state.tab === Tabs.base && <CondominiumBasedataTab
        condominium={this.state.condominium}
        projects={this.state.projects}
        apartmenttemplates={this.state.apartmenttemplates}
        aptTemplateCreateCallback={this.aptTemplateCreateCallback}
        aptTemplateDeleteCallback={this.aptTemplateDeleteCallback}
        aptTemplateEditCallback={this.aptTemplateEditCallback}
      />}

      {this.state.tab === Tabs.apts && <CondominiumApartmentsTab
        condominium={this.state.condominium}
        projects={this.state.projects}
        apartmenttemplates={this.state.apartmenttemplates}
      />}

      {this.state.tab === Tabs.users && <CondominiumUsersTab
        condominium={this.state.condominium}
        projects={this.state.projects}
        apartmenttemplates={this.state.apartmenttemplates}
      />}
    </div>;
  }
}

export default withRouter(Condominium);
