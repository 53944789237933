
import React, { Component } from 'react';
import I18n from 'i18n-js';
import { File } from '../../multiview/Icon/Icon.js';
import AvainiaTable from '../AvainiaTable/AvainiaTable.js';
import AvainiaPanel from './AvainiaPanel.js';

class ProjectListPanel extends Component {
  render() {
    return <AvainiaPanel heading={I18n.t('views.projects.projects')} double={this.props.double} icon={<File />}>
      <AvainiaTable data={this.props.projects} keyField="id" rowClickIdRedirect="project" columns={[
        { dataField: 'id', text: I18n.t('general.id'), headerStyle: { width: '60px' } },
        { dataField: 'name', text: I18n.t('views.projects.project-name') },
      ]} />
    </AvainiaPanel>;
  }
}

export default ProjectListPanel;
