import React, { Component } from 'react';
import { Form, Button } from 'react-bootstrap';
import I18n from 'i18n-js';
import { Edit, Cog } from '../../multiview/Icon/Icon.js';
import APIService from '../../../AvainiaTools/APIService.js';
import AvainiaTable from '../../multiview/AvainiaTable/AvainiaTable.js';
import Error from '../../multiview/Error/Error.js';
import Loading from '../../multiview/Loading/Loading.js';
import LocalStorageService from '../../../AvainiaTools/LocalStorageService.js';
import ModalConfigProjectsTable from '../../multiview/Modals/ModalConfigProjectsTable.js';
import ModalProjectEdit from '../../multiview/Modals/ModalProjectEdit.js';
import AvainiaPermissions from '../../../AvainiaTools/AvainiaPermissions.js';
import Topbar from '../../multiview/Topbar/Topbar.js';

const Modals = {
  editProjectModal: 1,
  tableConfigModal: 2,
};

class Projects extends Component {
  constructor(props) {
    super(props);

    //! TODO: Hardcoding is evil (types)
    let type = 'marine';

    if (this.props.configuration.condominiums) {
      type = 'condominium';
    }

    if (this.props.configuration.infraprojects) {
      type = 'infrastructure';
    }

    this.state = {
      projects: [],
      visibleProjects: [],
      filter: '',
      editing: false,
      key: 1,
      modal: false,
      name: '',
      code: '',
      status: '',
      type,
      navigate: false,
      tableConfig: [],
      loading: true,
      error: false,
    };
  }

  componentDidMount() {
    // ? Useful for performance tuning const t0 = performance.now();
    APIService.projectsGet().then((projects) => {
      // ? Useful for performance tuning const t1 = performance.now();
      // ? Useful for performance tuning console.log(`Call to doSomething took ${t1 - t0} milliseconds.`);

      if (projects.error) { return this.setState({ error: projects.error }); }

      // TODO: Refactor this to Inside getAllProjects!
      const openingCounts = LocalStorageService.getProjectOpeningCounts();
      const projectsWithOpeningCounts = projects.map((project) => {
        return {
          ...project,
          boost: openingCounts ? openingCounts[project.code] : 0,
        };
      });

      this.setState({
        projects: projectsWithOpeningCounts,
        visibleProjects: this.getvisibleProjects(projects, false),
        loading: false,
      });
    });
  }

  match = (a, b) => {
    if (!a) { return false; }
    if (!Array.isArray(a)) { a = a.toLowerCase(); }
    return a.indexOf(b) !== -1;
  }

  getvisibleProjects = (projects, filterValue) => {
    projects.sort((a, b) => {
      if (a.boost === b.boost) return 0;
      if (a.boost > b.boost) return -1;
      return 1;
    });

    if (!filterValue) { return projects; }

    const lowcaseFilter = filterValue.toLowerCase();
    const results = projects.filter((project) => {
      // Filter with the search term, match id
      if (this.match(project.code, lowcaseFilter)) {
        return true;
      }

      // Match status / type
      if (this.match(I18n.t(`views.projects.statuses.${project.status}`).toLowerCase(), lowcaseFilter)) {
        return true;
      }

      // Match name
      if (this.match(project.name, lowcaseFilter)) {
        return true;
      }

      // Match companies -- TODO: The data doesn't contain companies!
      return !!project.companies.find((company) => { return this.match(company.name, lowcaseFilter) || this.match(company.code, lowcaseFilter); });
    });
    return results;
  }

  onSearchTermChange = (e) => {
    // Small throttling of input updates
    const { value } = e.target;

    this.setState({ filter: value }, () => {
      // Small throttling of input updates
      if (window._timeout) { clearTimeout(window._timeout); }
      window._timeout = setTimeout(() => {
        window._timeout = false;
        const visibleProjects = this.getvisibleProjects(this.state.projects, value);
        this.setState({ visibleProjects });
      }, 200);
    });
  }

  onChange = (e) => { this.setState({ [e.target.name]: e.target.value }); }

  handleStatusChange = (changeEvent) => {
    this.setState({
      status: changeEvent.target.value,
    });
  };

  createProject = () => {
    if (this.state.loading) { return; }

    this.setState({ loading: true, error: false }, () => {
      const payload = {
        type: this.state.type,
        code: this.state.code,
        name: this.state.name,
        status: this.state.status,
      };

      APIService.projectsCreate(payload).then((project) => {
        if (project.error) { return this.setState({ error: project.error, loading: false }); }

        const { projects } = this.state;
        projects.push(project);

        this.setState({
          projects,
          visibleProjects: projects,
          name: '',
          code: '',
          status: '',
          loading: false,
        });
      });
    });
  }

  getTypeFor = (cell, row) => {
    return I18n.t(`views.projects.${row.type}`);
  }

  getStatusFor = (cell, row) => {
    return I18n.t(`views.projects.statuses.${row.status}`);
  }

  editProject = (e, row) => {
    if (this.state.loading) { return; }

    e.stopPropagation();

    const editing = this.state.projects.find((x) => x.id === row.id);

    this.setState({ editing, modal: Modals.editProjectModal });
  }

  projectEditCallback = (id, payload) => {
    const { projects } = this.state;
    const updated = projects.map((target) => {
      if (target.id === id) {
        return {
          ...target,
          code: payload.code,
          name: payload.name,
          status: payload.status,
          type: payload.type,
        };
      }

      return target;
    });

    const visibleProjects = this.getvisibleProjects(updated, this.state.filter);

    this.setState({ projects: updated, visibleProjects, key: Math.random() });
  }

  projectDeleteCallback = (id) => {
    const { projects } = this.state;
    const filtered = projects.filter((x) => x.id !== id);
    const visibleProjects = this.getvisibleProjects(filtered, this.state.filter);

    this.setState({
      visibleProjects,
      projects: filtered,
      key: Math.random(),
    });
  }

  hideModal = () => { this.setState({ modal: false }); }

  renderCompanies = (companies) => {
    return <>
      {(companies || []).map((company) => <div key={company.id} style={{ borderBottom: '1px solid' }}>{company.name}</div>)}
    </>;
  }

  renderActions = (cell, row) => {
    return <>
      <Edit onClick={(e) => { this.editProject(e, row); }} className="clickable" />
    </>;
  }

  showTableConfig = () => {
    this.setState({ modal: Modals.tableConfigModal });
  }

  refreshTable = () => {
    this.setState({ key: Math.random() });
  }

  render() {
    const config = LocalStorageService.getProjectsTableConfig();
    const projects = config.showInactiveProjects ? this.state.visibleProjects : this.state.visibleProjects.filter((project) => project.status !== 'inactive'); // TODO! Hardcoding is evil
    const onlyCondominiumOrOnlyInfra = this.props.configuration.condominiums !== this.props.configuration.infraprojects;
    const user = LocalStorageService.getUser();

    return <div className="App-main">
      <Topbar configModal={false && <div>TODO</div>} searchChangeHandler={this.onSearchTermChange} />

      <div className="App-container" key={this.state.key}>
        <h1>{I18n.t('views.projects.projects')}</h1>

        <Cog onClick={this.showTableConfig} className="clickable" />

        {this.state.error && <Error error={this.state.error} inline />}
        {!this.state.loading &&
          <AvainiaTable
            data={projects}
            keyField="id"
            rowClickIdRedirect="project"
            defaultSorted={[{ dataField: 'mostRecentDocument', order: 'desc' }]}
            columns={[
              { dataField: 'id', text: I18n.t('general.id'), headerStyle: { width: '60px' }, hidden: !config.id },
              {
                dataField: 'code',
                text: I18n.t('views.projects.project-code'),
                hidden: !config.code,
                sort: true,
                formatter: (cell, row) => {
                  return <a href={`/project/${row.id}`} className="clickable">
                    {cell}
                  </a>;
                },
              },
              { dataField: 'name', text: I18n.t('views.projects.project-name'), hidden: !config.name, sort: true },
              { dataField: 'filecount', text: I18n.t('folders.filecount'), sort: true },
              { dataField: 'mostRecentDocument', text: I18n.t('folders.mostRecentDocument'), sort: true },
              {
                dataField: 'status',
                text: I18n.t('views.projects.status'),
                formatter: this.getStatusFor,
                hidden: !config.status,
              },
              {
                dataField: 'companies',
                text: I18n.t('general.companies'),
                formatter: this.renderCompanies,
                hidden: !config.companies,
              },
              {
                dataField: 'type',
                text: I18n.t('views.projects.type'),
                formatter: this.getTypeFor,
                hidden: !config.type || onlyCondominiumOrOnlyInfra,
              },
              {
                dataField: 'actions',
                text: I18n.t('general.table-actions'),
                headerStyle: { width: '100px' },
                formatter: this.renderActions,
                hidden: !user.hasPermission(AvainiaPermissions.ProjectsManage) || !config.actions,
              },
            ]}
          />
        }

        {this.state.modal === Modals.editProjectModal &&
          <ModalProjectEdit
            disableType={onlyCondominiumOrOnlyInfra}
            project={this.state.editing}
            onHide={this.hideModal}
            projectDeleteCallback={this.projectDeleteCallback}
            editCallback={this.projectEditCallback}
          />
        }

        <ModalConfigProjectsTable
          disableType={onlyCondominiumOrOnlyInfra}
          callBack={this.refreshTable}
          show={this.state.modal === Modals.tableConfigModal}
          hide={this.hideModal}
        />

        {this.state.error && <Error error={this.state.error} inline />}
        {!this.state.error && this.state.loading && <Loading inline />}
        {user.hasPermission(AvainiaPermissions.ProjectsManage) && !this.state.loading &&
          <div className="tinyform">
            <h3>{I18n.t('views.projects.create-new-project')}</h3>
            <Button data-todo="TODO: permissions" onClick={(x) => { document.getElementById('foo').style.display = 'block'; x.target.style.display = 'none'; }}>
              {I18n.t('views.projects.button-create')}
            </Button>
            <div id="foo" style={{ display: 'none' }}>
              <Form.Group>
                <Form.Label>{I18n.t('views.projects.project-name')}</Form.Label>
                <Form.Control type="text" onChange={this.onChange} name="name" value={this.state.name} />
              </Form.Group>
              <Form.Group>
                <Form.Label>{I18n.t('views.projects.project-code')}</Form.Label>
                <Form.Control type="text" onChange={this.onChange} name="code" value={this.state.code} />
              </Form.Group>
              {!onlyCondominiumOrOnlyInfra &&
                <Form.Group>
                  <Form.Label>{I18n.t('views.projects.type')}</Form.Label>
                  <Form.Check
                    type="radio"
                    onChange={(e) => { this.setState({ type: 'condominium' }); }}
                    data-todo="Hardcoding is evil (type)"
                    value="condominium"
                    checked={this.state.type === 'condominium'}
                    label={I18n.t('views.projects.condominium')}
                  />
                  <Form.Check
                    type="radio"
                    onChange={(e) => { this.setState({ type: 'infrastructure' }); }}
                    data-todo="Hardcoding is evil (type)"
                    value="infrastructure"
                    checked={this.state.type === 'infrastructure'}
                    label={I18n.t('views.projects.infrastructure')}
                  />
                  <Form.Check
                    type="radio"
                    onChange={(e) => { this.setState({ type: 'marine' }); }}
                    data-todo="Hardcoding is evil (type)"
                    value="marine"
                    checked={this.state.type === 'marine'}
                    label={I18n.t('views.projects.marine')}
                  />
                </Form.Group>
              }
              <Form.Group>
                <Form.Label>{I18n.t('views.projects.status')}</Form.Label>
                <Form.Check type="radio" onChange={this.handleStatusChange} value="before" checked={this.state.status === 'before'} name="status" label={I18n.t('views.projects.statuses.before')} />
                <Form.Check type="radio" onChange={this.handleStatusChange} value="during" checked={this.state.status === 'during'} name="status" label={I18n.t('views.projects.statuses.during')} />
                <Form.Check type="radio" onChange={this.handleStatusChange} value="after" checked={this.state.status === 'after'} name="status" label={I18n.t('views.projects.statuses.after')} />
              </Form.Group>
              <Button variant="primary" onClick={this.createProject}>{I18n.t('views.projects.button-create')}</Button>
            </div>
          </div>
        }
      </div>
    </div>;
  }
}

export default Projects;
