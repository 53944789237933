import axios from 'axios';
import { objectToFormData } from 'object-to-formdata';
import { PostData, PutData, DeleteData, GetData, RawGet, GetImage, PostUserPhoto } from './RequestService.js';
import LocalStorageService from './LocalStorageService.js';
import UtilService from './UtilService.js';

const catcherInTheCode = (e, allow401And403) => {
  if (e.status > 199 && e.status < 300) {
    return console.log('Well, this is unexpected. Is this an error? I am ignoring it.', e);
  }
  if (e.status === 400) { return { error: 6 }; }
  if (e.status === 401) {
    if (!allow401And403) { window.triggerLoginCheck = true; }
    return { error: 4, unauthorized: allow401And403 };
  }
  if (e.status === 403) { return { error: 5, unauthorized: allow401And403 }; }
  if (e.status === 404) { return { error: 3 }; }
  if (e.status === 405) { return { error: 10 }; }
  if (e.status === 409) { return { error: 14 }; }
  if (e.status === 422) { return { error: -1 }; }
  if (e.status === 500) { return { error: 500 }; } // TODO! This is always a fatal error that should be logged && reported

  console.error('Unexpected error during API call!', e);
  return { error: -3, e };
};

const logicError = (desc, result) => {
  console.error(`Invalid return value for ${desc}`, result);
  return { error: -2 };
};

const getResultError = (result, emptyAllowed = false) => {
  if (emptyAllowed && !result) { return; }
  if (!emptyAllowed && !result) { return { error: 9 }; }
  if (result.errors) { return { error: 6 }; }
  if (result.exception) { return { error: 7 }; }
  if (result.error) { return { error: 8 }; }

  return false;
};

// TODO: Caching
// TODO: Image -> Document
/**
 * API Call helper class. All Backend calls to be routed through here (except for image).
 *
 * - All functions are async (return a promise)
 * - If a functions has an attribute called "dataAttribute", the data will be returned wrapped in a data object with the actual data inside the named data attribute.
 */
class APIService {
  static getCompanyUsers = async (id) => {
    return GetData(`companies/${id}/users`)
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { return logicError('getCompanyUsers', result); }
        return result.data;
      }).catch(catcherInTheCode);
  }

  static getProject = async (id) => {
    return this.projectGet(id); // TODO: Refactor this away
  }

  static getPhaseCompanies(phase) {
    // api/v1/fields/{fieldId}/keywords/{keywordId}/companies
    return GetData(`fields/2/keywords/${phase.id}/companies?per_page=9999`) // TODO: Let's hope the field ID never changes :skull:
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { return logicError('getPhaseCompanies', result); }

        return result.data;
      }).catch(catcherInTheCode);
  }

  static removePhaseFromCompany(phase, company) {
    // api/v1/fields/{fieldId}/keywords/{keywordId}/companies/{companyId}
    return DeleteData(`fields/2/keywords/${phase.id}/companies/${company.id}`) // TODO: Let's hope the ID never changes :skull:
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { return logicError('addPhaseToCompany', result); }

        return result.data;
      }).catch(catcherInTheCode);
  }

  static addPhaseToCompany(phase, company) {
    // api/v1/fields/{fieldId}/keywords/{keywordId}/companies
    return PostData(`fields/2/keywords/${phase.id}/companies`, company) // TODO: Let's hope the ID never changes :skull:
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { return logicError('addPhaseToCompany', result); }

        return result.data;
      }).catch(catcherInTheCode);
  }

  static createPhase(name) {
    if (!name) { return { error: 'Invalid input, need name!' }; }

    const payload = {
      field_id: 2, // TODO: Let's hope the ID never changes :skull:
      text: name,
    };

    return PostData('fields/2/keywords', payload) // TODO: Let's hope the ID never changes :skull:
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { return logicError('createPhase', result); }
        return result.data;
      }).catch(catcherInTheCode);
  }

  static savePhase(phase) {
    if (!phase) { return { error: 'Invalid input, need phase!' }; }

    const payload = {
      text: phase.name,
      field_d: 2, // TODO: Let's hope the ID never changes :skull:
      deactivated: phase.deactivated,
    };

    return PutData(`fields/2/keywords/${phase.id}`, payload) // TODO: Let's hope the ID never changes :skull:
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { return logicError('savePhase', result); }
        return result.data;
      }).catch(catcherInTheCode);
  }

  static deletePhase(phase) {
    if (!phase) { return { error: 'Invalid input, need phase!' }; }

    return DeleteData(`fields/2/keywords/${phase.id}`) // TODO: Let's hope the ID never changes :skull:
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { return logicError('deletePhase', result); }
        return result.data;
      }).catch(catcherInTheCode);
  }

  static getAllPhases = async (dataAttribute = 'phases') => {
    return GetData('fields/2/keywords?per_page=9999') // TODO: Let's hope the ID never changes :skull:
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { return logicError('getAllPhases', result); }

        result.data.sort((a, b) => { return a.text > b.text ? 1 : -1; });

        const phases = result.data.map((x) => { return { id: x.id, name: x.text, deactivated: x.deactivated }; });

        return dataAttribute ? { [dataAttribute]: phases } : phases;
      }).catch(catcherInTheCode);
  }

  static getAllSubjects = async (dataAttribute = 'subjects') => {
    // Let's hope the ID never changes :skull:
    return GetData('fields/3/keywords?per_page=9999')
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { return logicError('getAllSubjects', result); }

        result.data.sort((a, b) => { return a.text > b.text ? 1 : -1; });

        const subjects = result.data.map((x) => { return { id: x.id, name: x.text }; });
        return dataAttribute ? { [dataAttribute]: subjects } : subjects;
      }).catch(catcherInTheCode);
  }

  static getAllStates = async (dataAttribute = 'states') => {
    // THIS IS WRONG!!!! THE FIELD ID IN ENERKE PRODUCTION IS 10!!!!
    return GetData('fields/4/keywords?per_page=9999') // TODO: Let's hope the ID never changes :skull:
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { return logicError('getAllStates', result); }

        result.data.sort((a, b) => { return a.text > b.text ? 1 : -1; });

        const states = result.data.map((x) => { return { id: x.id, name: x.text }; });

        return dataAttribute ? { [dataAttribute]: states } : states;
      }).catch(catcherInTheCode);
  }


  /* Returns array of data like:
   * { "data": [
        {
          "id": 2,
          "key": "phase",
          "type": "keywords",
          "edit_config": "select-one",
          "upload_config": null,
          "upload_hook": null,
          "order": 0,
          "checkbox_default": null,
          "label": "Vaihe"
        }, {
          "id": 3,
          "key": "subject",
          "type": "keywords",
          "edit_config": "select-one",
          "upload_config": null,
          "upload_hook": null,
          "order": 1,
          "checkbox_default": null,
          "label": "Aihe"
        }...
    ]}
   */
  static getFields = async (dataAttribute = 'fields') => {
    return GetData('fields?per_page=9999')
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { logicError('getFields', result); }
        return dataAttribute ? { [dataAttribute]: result.data } : result.data;
      }).catch(catcherInTheCode);
  }

  static deleteUser = async (user) => {
    if (!user) { return { error: 'Invalid input, need user!' }; } //! TODO: Translate

    // /api/v1/users/{userId}
    return DeleteData(`users/${user.id}`)
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { return logicError('deleteUser', result); }
        return result;
      }).catch(catcherInTheCode);
  }

  static documentAdd = async (payload) => {
    const BaseURL = `${process.env.REACT_APP_API_HOST}/api/v1/`;
    const endpoint = `${BaseURL}projects/${payload.projectId}/documents`;
    const token = LocalStorageService.getToken();
    const headersCfg = { headers: { Authorization: `Bearer ${token}` } };

    const options = {
      indices: true,
      nullsAsUndefineds: false,
      booleansAsIntegers: false,
    };

    let result = false;
    await axios.post(endpoint, objectToFormData(payload, options), headersCfg).then((response) => {
      result = response;
    });

    if (!result) { return { error: 'query error 1' }; }
    if (!result.status) { return { error: 'query error 2' }; }
    if (!result.data) { return { error: 'query error 3' }; }
    if (result.error) { return { error: 'query error 4' }; }
    if (result.status !== 201) { return { error: 'query error 5' }; }

    return result;
  }


  /* This is a special function that takes a project object and a File selected for upload (event.target.files[0], only one is supported) */
  static documentAddOld = async (payload, phases, subjects) => {
    const { file, project, info, phase, subject, positionText, folderId } = payload;

    // eslint-disable-next-line eqeqeq
    const phaseO = phases.find((x) => x.id == phase);

    // eslint-disable-next-line eqeqeq
    const subjectO = subjects.find((x) => x.id == subject);

    if (!phaseO) { return { error: 'Invalid phase!' }; } //! TODO: Translate
    if (!subjectO) { return { error: 'Invalid subject!' }; } //! TODO: Translate

    const data = new FormData();
    data.append('file', file, file.name);

    data.append('fields[0][field_id]', '4'); // / "info" aka lisätietokenttä
    data.append('fields[0][text]', info);

    data.append('fields[1][field_id]', '6'); // / positionText
    data.append('fields[1][text]', positionText);

    data.append('keywords[0][field_id]', '2'); // / phase
    data.append('keywords[0][id]', phaseO.id);
    data.append('keywords[0][text]', phaseO.name);

    data.append('keywords[1][field_id]', '3'); // / subject
    data.append('keywords[1][id]', subjectO.id);
    data.append('keywords[1][text]', subjectO.name);

    if (folderId) { data.append('folderId', folderId); }

    // If coordinates is not set, the core will use exif data
    // data.append('coordinates', 'x'); // TODO: Implement

    const BaseURL = `${process.env.REACT_APP_API_HOST}/api/v1/`;
    const endpoint = `${BaseURL}projects/${project.id}/documents`;
    const token = LocalStorageService.getToken();
    const headersCfg = { headers: { Authorization: `Bearer ${token}` } };

    let result = false;
    await axios.post(endpoint, data, headersCfg).then((response) => {
      result = response;
    });

    if (!result) { return { error: 'query error 1' }; }
    if (!result.status) { return { error: 'query error 2' }; }
    if (!result.data) { return { error: 'query error 3' }; }
    if (result.error) { return { error: 'query error 4' }; }
    if (result.status !== 201) { return { error: 'query error 5' }; }

    return result;
  }

  static createFileExportJob = async (payload) => {
    return PostData(`projects/${payload.projectId}/files-zip`, payload).then((result) => {
      if (getResultError(result)) { return getResultError(result); }
      if (result.code !== 200) { return logicError('createFileExportJob'); }
      return result;
    }).catch((e) => { return catcherInTheCode(e); });
  }

  static acceptTerms = async () => {
    return PostData('acceptedprivacyterms', {})
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result) { return logicError('acceptTerms'); }
        return true;
      }).catch((e) => { return catcherInTheCode(e, true); });
  }

  static doSaveEditedImage = async (project, docId, payload) => {
    return await PutData(`projects/${project.id}/documents/${docId}`, payload)
      .then((result) => {
        if (getResultError(result, false)) { return getResultError(result, false); }

        return true;
      }).catch(catcherInTheCode);
  }

  static doDeleteDocument = async (project, document) => {
    return await DeleteData(`projects/${project.id}/documents/${document.id}`, document)
      .then((result) => {
        if (getResultError(result, false)) { return getResultError(result, false); }

        return true;
      }).catch(catcherInTheCode);
  }

  static doImportData = async (data) => {
    return await PostData('import', data)
      .then((result) => {
        return result;
      }).catch(catcherInTheCode);
  }

  /* Experimenting */

  static experiment = () => {}

  /* Rows below this comment have updated naming */

  static login = async (email, password) => {
    if (!email || !password) { return { error: 'Input error' }; }

    return PostData('authenticate', { email, password })
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data || !result.data.user) { return logicError('doLogin'); }

        const { user } = result.data;
        user.token = result.data.token;

        return user;
      }).catch((e) => { return catcherInTheCode(e, true); });
  }

  static loginCheck = async () => {
    return GetData('user')
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { return logicError('loginCheck', result); }
        return result.data;
      }).catch(catcherInTheCode);
  }

  static apartmentMaterialquotesGet = async (id) => {
    return GetData(`apartments/${id}/materialquotes`)
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { return logicError('apartmentMaterialquotesGet', result); }
        return result.data;
      }).catch(catcherInTheCode);
  }

  static apartmentMaterialquoteApprove = async (id) => {
    return PostData(`materialquotes/${id}/approve`, {})
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { return logicError('apartmentMaterialquoteApprove', result); }
        return result.data;
      }).catch(catcherInTheCode);
  }

  static condominiumsGet = async (dataAttribute) => {
    return GetData('condominiums?per_page=9999')
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { return logicError('condominiumsGet', result); }
        return dataAttribute ? { [dataAttribute]: result.data } : result.data;
      }).catch(catcherInTheCode);
  }

  static condominiumProjectsGet = async (id) => {
    return GetData(`condominiums/${id}/projects`)
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { return logicError('condominiumsGet', result); }
        return result.data;
      }).catch(catcherInTheCode);
  }

  static condominiumEdit = async (payload) => {
    return PutData(`condominiums/${payload.id}`, payload)
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { return logicError('condominiumEdit', result); }
        return result.data;
      }).catch(catcherInTheCode);
  }

  static condominiumCreate = async (payload) => {
    return PostData('condominiums', payload)
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { return logicError('condominiumsCreate', result); }
        return result.data;
      }).catch(catcherInTheCode);
  }

  static condominiumDelete = async (payload) => {
    return DeleteData(`condominiums/${payload.id}`)
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { return logicError('condominiumDelete', result); }
        return result.data;
      }).catch(catcherInTheCode);
  }

  static condominiumGet = async (id) => {
    return GetData(`condominiums/${id}`)
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { return logicError('condominiumsGet', result); }
        return result.data;
      }).catch(catcherInTheCode);
  }

  static condominiumApartmentsGet = async (id, dataAttribute) => {
    return GetData(`condominiums/${id}/apartments`)
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { return logicError('condominiumApartmentsGet', result); }

        return dataAttribute ? { [dataAttribute]: result.data } : result.data;
      }).catch(catcherInTheCode);
  }

  static condominiumApartmentGet = async (condominiumId, apartmentId, dataAttribute) => {
    return GetData(`condominiums/${condominiumId}/apartments/${apartmentId}`)
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { return logicError('condominiumApartmentGet', result); }

        return dataAttribute ? { [dataAttribute]: result.data } : result.data;
      }).catch(catcherInTheCode);
  }

  static condominiumApartmentCreate = async (id, payload) => {
    return PostData(`condominiums/${id}/apartments`, payload)
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { return logicError('condominiumApartmentTemplatesGet', result); }
        return result.data;
      }).catch(catcherInTheCode);
  }

  static condominiumApartmentDelete = async (id, aptid) => {
    return DeleteData(`condominiums/${id}/apartments/${aptid}`)
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { return logicError('condominiumApartmentDelete', result); }
        return result.data;
      }).catch(catcherInTheCode);
  }

  static condominiumApartmentTemplateMaterialformsGet = async (id) => {
    return GetData(`apartmenttemplates/${id}/materialforms`)
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { return logicError('condominiumApartmentTemplateMaterialformsGet', result); }
        return result.data;
      }).catch(catcherInTheCode);
  }

  static condominiumApartmentTemplateMaterialformsCreate = async (id, payload) => {
    return PostData(`apartmenttemplates/${id}/materialforms`, payload)
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { return logicError('condominiumApartmentTemplateMaterialformsCreate', result); }
        return result.data;
      }).catch(catcherInTheCode);
  }

  static condominiumApartmentTemplateMaterialformsDelete = async (apartmentTemplateId, materialformId) => {
    return DeleteData(`apartmenttemplates/${apartmentTemplateId}/materialforms/${materialformId}`)
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { return logicError('condominiumApartmentTemplateMaterialformsDelete', result); }
        return result.data;
      }).catch(catcherInTheCode);
  }

  static condominiumApartmentTemplatesGet = async (id) => {
    return GetData(`condominiums/${id}/apartmenttemplates`)
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { return logicError('condominiumApartmentTemplatesGet', result); }
        return result.data;
      }).catch(catcherInTheCode);
  }

  static condominiumApartmentTemplateCreate = async (id, name) => {
    const payload = { name };
    return PostData(`condominiums/${id}/apartmenttemplates`, payload)
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { return logicError('condominiumApartmentTemplatesGet', result); }
        return result.data;
      }).catch(catcherInTheCode);
  }

  static condominiumApartmentTemplateDelete = async (condoid, templateid) => {
    return DeleteData(`condominiums/${condoid}/apartmenttemplates/${templateid}`)
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { return logicError('condominiumApartmentTemplateDelete', result); }
        return result.data;
      }).catch(catcherInTheCode);
  }

  static condominiumApartmentTemplateEdit = async (condoid, templateid, payload) => {
    return PutData(`condominiums/${condoid}/apartmenttemplates/${templateid}`, payload)
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { return logicError('condominiumApartmentTemplateEdit', result); }
        return result.data;
      }).catch(catcherInTheCode);
  }

  static defaultFoldersGet = async (dataAttribute) => {
    return GetData('defaultfolders')
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { return logicError('defaultFoldersGet', result); }
        return dataAttribute ? { [dataAttribute]: result.data } : result.data;
      }).catch(catcherInTheCode);
  }

  static defaultFoldersCreate = async (defaultfolder, dataAttribute) => {
    return PostData('defaultfolders', defaultfolder)
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { return logicError('defaultFoldersCreate', result); }
        return dataAttribute ? { [dataAttribute]: result.data } : result.data;
      }).catch(catcherInTheCode);
  }

  static defaultFoldersDelete = async (defaultfolderId, dataAttribute) => {
    return DeleteData(`defaultfolders/${defaultfolderId}`)
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { return logicError('defaultFoldersDelete', result); }
        return dataAttribute ? { [dataAttribute]: result.data } : result.data;
      }).catch(catcherInTheCode);
  }

  static defaultFoldersEdit = async (defaultfolder, dataAttribute) => {
    return PutData(`defaultfolders/${defaultfolder.id}`, defaultfolder)
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { return logicError('defaultFoldersEdit', result); }
        return dataAttribute ? { [dataAttribute]: result.data } : result.data;
      }).catch(catcherInTheCode);
  }

  static apartmentMaterialQuoteSave = async (projectId, payload) => {
    return PutData(`projects/${projectId}/materialquotes/${payload.id}`, payload)
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { return logicError('projectMaterialquotesCreate', result); }
        return result.data;
      }).catch(catcherInTheCode);
  }

  static productCategoriesGet = async () => {
    return GetData('productcategories?per_page=9999')
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { return logicError('productCategoriesGet', result); }
        return result.data;
      }).catch(catcherInTheCode);
  }

  static productCategoriesCreate = async (payload) => {
    return PostData('productcategories', payload)
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { return logicError('productCategoriesGetCreate', result); }
        return result.data;
      }).catch(catcherInTheCode);
  }

  static productCategoriesDelete = async (id) => {
    return DeleteData(`productcategories/${id}`)
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { return logicError('productCategoriesDelete', result); }
        return result.data;
      }).catch(catcherInTheCode);
  }

  static productCategoriesEdit = async (id, payload) => {
    return PutData(`productcategories/${id}`, payload)
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { return logicError('productCategoriesEdit', result); }
        return result.data;
      }).catch(catcherInTheCode);
  }

  static productGet = async (id) => {
    return GetData(`products/${id}`)
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { return logicError('productGet', result); }
        return result.data;
      }).catch(catcherInTheCode);
  }

  static productsGet = async (id) => {
    return GetData(`productcategories/${id}/products?per_page=9999`)
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { return logicError('productsGet', result); }
        return result.data;
      }).catch(catcherInTheCode);
  }

  static productsDelete = async (id) => {
    return DeleteData(`products/${id}`)
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { return logicError('productsDelete', result); }
        return result.data;
      }).catch(catcherInTheCode);
  }

  static productsEdit = async (id, payload) => {
    return PutData(`products/${id}`, payload, true)
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { return logicError('productsEdit', result); }
        return result.data;
      }).catch(catcherInTheCode);
  }

  static productsCreate = async (payload) => {
    return PostData('products', payload)
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { return logicError('productsCreate', result); }
        return result.data;
      }).catch(catcherInTheCode);
  }

  static contentPermissionAdd = async (role, contentType, contentId) => {
    const payload = {
      role_id: role.id,
      content_type: contentType,
      content_id: contentId,
    };
    return PostData('content-permissions', payload)
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { return logicError('contentPermissionAdd', result); }
        return result.data;
      }).catch(catcherInTheCode);
  }

  static contentPermissionRemove = async (contentPermission) => {
    return DeleteData(`content-permissions/${contentPermission.id}`)
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { return logicError('contentPermissionRemove', result); }
        return result.data;
      }).catch(catcherInTheCode);
  }

  static contentPermissionsGet = async (type) => {
    let url = 'content-permissions';
    if (type) {
      url = `${url}?content_type=${type}`;
    }

    return GetData(url)
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { return logicError('contentPermissionsGet', result); }
        return result.data;
      }).catch(catcherInTheCode);
  }

  static permissionsGet = async () => {
    return GetData('rolepermissions')
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { return logicError('permissionsGet', result); }
        return result.data;
      }).catch(catcherInTheCode);
  }

  static permissionsForOwnerGet = async () => {
    return GetData('rolepermissions_owner')
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { return logicError('permissionsForOwnerGet', result); }
        return result.data;
      }).catch(catcherInTheCode);
  }

  static permissionsSave = async (role, permission) => {
    const payload = {
      role,
      permission: permission.name,
    };
    return PostData('rolepermissions', payload)
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { return logicError('permissionsSave', result); }
        return result.data;
      }).catch(catcherInTheCode);
  }

  static permissionsDelete = async (role, permission) => {
    return DeleteData(`rolepermissions/${role}/permission/${permission.name}`)
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { return logicError('permissionsDelete', result); }
        return result.data;
      }).catch(catcherInTheCode);
  }

  static projectGet = async (id) => {
    return GetData(`projects/${id}`)
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { return logicError('getProject', result); }
        return result.data;
      }).catch(catcherInTheCode);
  }

  static projectsEdit = async (id, payload) => { // Rename?
    return PutData(`projects/${id}`, payload)
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { return logicError('projectsEdit', result); }
        return result.data;
      }).catch(catcherInTheCode);
  }

  static projectsDelete = async (id) => { // Rename?
    return DeleteData(`projects/${id}`)
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { return logicError('projectsDelete', result); }
        return result.data;
      }).catch(catcherInTheCode);
  }

  static projectsCreate = async (payload) => { // Rename?
    return PostData('projects', payload)
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { return logicError('projectsCreate', result); }
        return result.data;
      }).catch(catcherInTheCode);
  }

  static projectsGet = async () => {
    return GetData('projects?per_page=9999')
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { return logicError('projectsGet', result); }
        return result.data;
      }).catch(catcherInTheCode);
  }

  static projectContactsGet = async (id, dataAttribute) => {
    return GetData(`projects/${id}/contacts?per_page=9999`)
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { return logicError('projectContactsGet', result); }
        return dataAttribute ? { [dataAttribute]: result.data } : result.data;
      }).catch(catcherInTheCode);
  }

  static projectContactsCreate = async (id, payload) => {
    return PostData(`projects/${id}/contacts`, payload)
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { return logicError('projectsContactsCreate', result); }
        return result.data;
      }).catch(catcherInTheCode);
  }

  static projectContactsDelete = async (projectId, contactId) => {
    return DeleteData(`projects/${projectId}/contacts/${contactId}`)
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { return logicError('productContactsDelete', result); }
        return result.data;
      }).catch(catcherInTheCode);
  }

  static projectContactsEdit = async (projectId, contactId, payload) => {
    return PutData(`projects/${projectId}/contacts/${contactId}`, payload)
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { return logicError('productContactsEdit', result); }
        return result.data;
      }).catch(catcherInTheCode);
  }

  static projectCondominiumsGet = async (id, dataAttribute) => {
    return GetData(`projects/${id}/contacts?per_page=9999`)
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { return logicError('projectCondominiumsGet', result); }
        return dataAttribute ? { [dataAttribute]: result.data } : result.data;
      }).catch(catcherInTheCode);
  }

  static projectFoldersGet = async (id, dataAttribute) => {
    return GetData(`projects/${id}/folders?per_page=9999`)
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { return logicError('projectFoldersGet', result); }
        return dataAttribute ? { [dataAttribute]: result.data } : result.data;
      }).catch(catcherInTheCode);
  }

  static projectFolderCreate = async (project, payload) => {
    return PostData(`projects/${project.id}/folders`, payload)
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { return logicError('projectFolderCreate', result); }
        return result.data;
      }).catch(catcherInTheCode);
  }

  static projectFolderDelete = async (folder) => {
    return DeleteData(`folders/${folder.id}`)
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { return logicError('projectFolderDelete', result); }
        return result.data;
      }).catch(catcherInTheCode);
  }

  static projectFolderEdit = async (folder, payload) => {
    return PutData(`folders/${folder.id}`, payload)
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { return logicError('projectFolderEdit', result); }
        return result.data;
      }).catch(catcherInTheCode);
  }

  static projectSchedulesGet = async (id, dataAttribute) => {
    return GetData(`projects/${id}/schedules?per_page=9999`)
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { return logicError('projectSchedulesGet', result); }
        return dataAttribute ? { [dataAttribute]: result.data } : result.data;
      }).catch(catcherInTheCode);
  }

  static projectSchedulesCreate = async (projectId, payload) => {
    return PostData(`projects/${projectId}/schedules`, payload)
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { return logicError('projectSchedulesCreate', result); }
        return result.data;
      }).catch(catcherInTheCode);
  }

  static projectSchedulesDelete = async (projectId, scheduleId) => {
    return DeleteData(`projects/${projectId}/schedules/${scheduleId}`)
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { return logicError('productSchedulesDelete', result); }
        return result.data;
      }).catch(catcherInTheCode);
  }

  static projectSchedulesEdit = async (projectId, scheduleId, payload) => {
    return PutData(`projects/${projectId}/schedules/${scheduleId}`, payload)
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { return logicError('productSchedulesEdit', result); }
        return result.data;
      }).catch(catcherInTheCode);
  }

  static projectMaterialFormsGet = async (id, dataAttribute) => {
    return GetData(`projects/${id}/materialforms?per_page=9999`)
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { return logicError('projectMaterialFormsGet', result); }
        return dataAttribute ? { [dataAttribute]: result.data } : result.data;
      }).catch(catcherInTheCode);
  }

  static projectMaterialFormGet = async (projectid, materialformid, dataAttribute) => {
    return GetData(`projects/${projectid}/materialforms/${materialformid}`)
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { return logicError('projectMaterialFormsGet', result); }
        return dataAttribute ? { [dataAttribute]: result.data } : result.data;
      }).catch(catcherInTheCode);
  }

  static projectMaterialFormsCreate = async (projectId, payload) => {
    return PostData(`projects/${projectId}/materialforms`, payload)
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { return logicError('projectMaterialFormsCreate', result); }
        return result.data;
      }).catch(catcherInTheCode);
  }

  static projectMaterialFormsUpdate = async (projectId, materialformId, payload) => {
    return PutData(`projects/${projectId}/materialforms/${materialformId}`, payload)
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { return logicError('projectMaterialFormsUpdate', result); }
        return result.data;
      }).catch(catcherInTheCode);
  }

  static projectMaterialFormsDelete = async (projectId, id) => {
    return DeleteData(`projects/${projectId}/materialforms/${id}`)
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { return logicError('projectMaterialFormsDelete', result); }
        return result.data;
      }).catch(catcherInTheCode);
  }

  static projectMaterialFormsGroupsCreate = async (materialformId, payload) => {
    return PostData(`materialforms/${materialformId}/groups`, payload)
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { return logicError('projectMaterialFormsGroupsCreate', result); }
        return result.data;
      }).catch(catcherInTheCode);
  }

  static projectMaterialFormsGroupsUpdate = async (materialformId, payload) => {
    return PutData(`materialforms/${materialformId}/groups/${payload.id}`, payload)
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { return logicError('projectMaterialFormsGroupsUpdate', result); }
        return result.data;
      }).catch(catcherInTheCode);
  }

  static projectMaterialFormsGroupsDelete = async (materialformId, groupId) => {
    return DeleteData(`materialforms/${materialformId}/groups/${groupId}`)
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { return logicError('projectMaterialFormsGroupsDelete', result); }
        return result.data;
      }).catch(catcherInTheCode);
  }

  static projectMaterialFormsSelectCreate = async (groupId, payload) => {
    return PostData(`materialformgroups/${groupId}/selects`, payload)
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { return logicError('projectMaterialFormsSelectCreate', result); }
        return result.data;
      }).catch(catcherInTheCode);
  }

  static projectMaterialFormsSelectUpdate = async (groupId, payload) => {
    return PutData(`materialformgroups/${groupId}/selects/${payload.id}`, payload)
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { return logicError('projectMaterialFormsSelectUpdate', result); }
        return result.data;
      }).catch(catcherInTheCode);
  }

  static projectMaterialFormsSelectDelete = async (groupId, selectId) => {
    return DeleteData(`materialformgroups/${groupId}/selects/${selectId}`)
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { return logicError('projectMaterialFormsSelectDelete', result); }
        return result.data;
      }).catch(catcherInTheCode);
  }

  static projectMaterialFormsOptionCreate = async (selectId, payload) => {
    return PostData(`materialformselects/${selectId}/options`, payload)
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { return logicError('projectMaterialFormsOptionCreate', result); }
        return result.data;
      }).catch(catcherInTheCode);
  }

  static projectMaterialFormsOptionUpdate = async (selectId, payload) => {
    return PutData(`materialformselects/${selectId}/options/${payload.id}`, payload)
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { return logicError('projectMaterialFormsOptionUpdate', result); }
        return result.data;
      }).catch(catcherInTheCode);
  }

  static projectMaterialFormsOptionsDelete = async (selectId, optionId) => {
    return DeleteData(`materialformselects/${selectId}/options/${optionId}`)
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { return logicError('projectMaterialFormsOptionUpdate', result); }
        return result.data;
      }).catch(catcherInTheCode);
  }

  static projectMaterialquotesGet = async (projectId) => {
    return GetData(`projects/${projectId}/materialquotes`)
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { return logicError('projectMaterialquotesGet', result); }
        return result.data;
      }).catch(catcherInTheCode);
  }

  static projectMaterialquotesCreate = async (projectId, payload) => {
    return PostData(`projects/${projectId}/materialquotes`, payload)
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { return logicError('projectMaterialquotesCreate', result); }
        return result.data;
      }).catch(catcherInTheCode);
  }

  static projectMaterialQuotesGet = async (id, dataAttribute) => {
    return GetData(`projects/${id}/materialquotes?per_page=9999`)
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { return logicError('projectMaterialQuotesGet', result); }
        return dataAttribute ? { [dataAttribute]: result.data } : result.data;
      }).catch(catcherInTheCode);
  }

  static projectDocumentsGet = async (id, fields) => {
    return GetData(`projects/${id}/documents?per_page=9999`)
      .then((result) => {
        if (result.error) { return { error: result.error }; }

        return result.data.map((item, index) => {
          return UtilService.dbDocumentToImage(item, id, fields, index);
        });
      }).catch(catcherInTheCode);
  }

  static projectCondominiumSet = async (projectId, condominiumId) => {
    const payload = {
      condominium_id: condominiumId,
      project_id: projectId,
    };
    return PostData('condominiumprojects', payload)
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { return logicError('projectCondominiumSet', result); }
        return result.data;
      }).catch(catcherInTheCode);
  }

  static materialSelectionDeadlinesGet = async (id, dataAttribute) => { // TODO! Rename
    return GetData(`projects/${id}/materialdeadlines?per_page=9999`)
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { return logicError('materialSelectionDeadlinesGet', result); }
        return dataAttribute ? { [dataAttribute]: result.data } : result.data;
      }).catch(catcherInTheCode);
  }

  static materialSelectionDeadlinesCreate = async (id, payload, dataAttribute) => { // TODO! Rename
    return PostData(`projects/${id}/materialdeadlines`, payload)
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { return logicError('materialSelectionDeadlinesCreate', result); }
        return dataAttribute ? { [dataAttribute]: result.data } : result.data;
      }).catch(catcherInTheCode);
  }

  static materialSelectionDeadlinesDelete = async (projectId, deadlineId, dataAttribute) => { // TODO! Rename
    return DeleteData(`projects/${projectId}/materialdeadlines/${deadlineId}`)
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { return logicError('materialSelectionDeadlinesDelete', result); }
        return dataAttribute ? { [dataAttribute]: result.data } : result.data;
      }).catch(catcherInTheCode);
  }

  static materialSelectionsGet = (projectId, apartmentId) => { // TODO! Rename
    return GetData(`projects/${projectId}/apartments/${apartmentId}/materialselections`)
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { return logicError('materialSelectionsGet', result); }
        return result.data;
      }).catch(catcherInTheCode);
  }

  static materialSelectionsCreate = (projectId, apartmentId, payload) => { // TODO! Rename
    return PostData(`projects/${projectId}/apartments/${apartmentId}/materialselections`, payload)
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { return logicError('materialSelectionsCreate', result); }
        return result.data;
      }).catch(catcherInTheCode);
  }

  static materialSelectionsDelete = (projectId, apartmentId, materialSelectionId) => { // TODO! Rename
    return DeleteData(`projects/${projectId}/apartments/${apartmentId}/materialselections/${materialSelectionId}`)
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { return logicError('materialSelectionsDelete', result); }
        return result.data;
      }).catch(catcherInTheCode);
  }

  static configurationGet = async () => {
    return RawGet('instance-configuration')
      .then((config) => {
        if (getResultError(config)) { return getResultError(config); }

        return config;
      }).catch(catcherInTheCode);
  }

  static reportForProducts = async (projectId, stairwell) => {
    // /api/v1/projects/{projectId}/productreport/{stairwell}
    return RawGet(`api/v1/projects/${projectId}/productreport/${stairwell}`, { tokenify: true, text: true })
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        return result;
      }).catch(catcherInTheCode);
  }

  static termsGet = async () => {
    return RawGet('api/v1/privacyterms')
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result) { return logicError('termsGet', result); }
        return result;
      }).catch(catcherInTheCode);
  }

  static userGet = async (id) => {
    return GetData(`users/${id}`)
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { return logicError('userGet'); }
        return result.data;
      }).catch(catcherInTheCode);
  }

  static usersGet = async () => {
    return GetData('users?per_page=9999')
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { return logicError('usersGet'); }
        return result.data;
      }).catch(catcherInTheCode);
  }

  static userTypesGet = async (dataAttribute) => {
    return GetData('userTypes?per_page=9999')
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { logicError('userTypesGet', result); }
        return dataAttribute ? { [dataAttribute]: result.data } : result.data;
      }).catch(catcherInTheCode);
  }

  static userPhotoGet = async (id) => {
    return GetImage(`userphoto/${id}`)
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result) { return logicError('userPhotoGet', result); }

        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(result);
          reader.onloadend = () => {
            resolve(reader.result);
          };
        });
      }).catch(() => { return { error: true }; });
  }

  static userPhotoCreate = async (id, photo) => {
    return PostUserPhoto(`userphoto/${id}`, photo)
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result) { return logicError('userPhotoUpdate', result); }
        return result;
      }).catch(catcherInTheCode);
  }

  static userPhotoDelete = async (id) => {
    return DeleteData(`userphoto/${id}`)
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result) { return logicError('userPhotoDelete', result); }
        return result;
      }).catch(catcherInTheCode);
  }

  static userPasswordChange = async (payload) => {
    return PostData('user/change-password', payload)
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }

        return result;
      }).catch(catcherInTheCode);
  }

  static userPasswordReset = async (email) => {
    const payload = { email };
    return PostData('user/reset-password', payload)
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }

        return result;
      }).catch(catcherInTheCode);
  }

  static companiesGet = async () => {
    return GetData('companies?per_page=9999')
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { return logicError('companiesGet'); }
        return result.data;
      }).catch(catcherInTheCode);
  }

  static companyGet = async (id) => {
    return GetData(`companies/${id}`)
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { return logicError('companyGet'); }
        return result.data;
      }).catch(catcherInTheCode);
  }

  static companyCreate = async (company) => {
    return PostData('companies', company)
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { return logicError('companyCreate', result); }
        return result.data;
      }).catch(catcherInTheCode);
  }

  static companyUpdate = async (company) => {
    return PutData(`companies/${company.id}`, company)
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { return logicError('companyDelete', result); }
        return result.data;
      }).catch(catcherInTheCode);
  }

  static companyDelete = async (company) => {
    return DeleteData(`companies/${company.id}`)
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { return logicError('companyDelete', result); }
        return result.data;
      }).catch(catcherInTheCode);
  }

  static companyUsersCreate = async (company, user) => {
    return PostData(`companies/${company.id}/users`, user)
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { return logicError('companyUsersCreate', result); }
        return result.data;
      }).catch(catcherInTheCode);
  }

  static companyUsersUpdate = async (company, user) => {
    return PutData(`companies/${company.id}/users/${user.id}`, user)
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { return logicError('companyUsersUpdate', result); }
        return result.data;
      }).catch(catcherInTheCode);
  }

  static companyUsersDelete = async (company, user) => {
    if (!company || !user) { return { error: 'Invalid input, need ID and user!' }; }
    return DeleteData(`companies/${company.id}/users/${user.id}`)
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { return logicError('companyUsersDelete', result); }
        return result.data;
      }).catch(catcherInTheCode);
  }

  static companyUsersGet = async (companyId) => {
    return GetData(`companies/${companyId}/users`)
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { return logicError('companyUsersGet', result); }
        return result.data;
      }).catch(catcherInTheCode);
  }

  static companyUserRoleAdd = (companyId, userId, roleId) => {
    const payload = {
      id: roleId,
    };

    return PostData(`companies/${companyId}/users/${userId}/roles`, payload)
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { return logicError('companyUserRoleAdd', result); }
        return result.data;
      }).catch(catcherInTheCode);
  }

  static companyUserRoleRemove = (companyId, userId, roleId) => {
    return DeleteData(`companies/${companyId}/users/${userId}/roles/${roleId}`)
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { return logicError('companyUserRoleRemove', result); }
        return result.data;
      }).catch(catcherInTheCode);
  }

  static fieldsGet = async (dataAttribute) => {
    return GetData('fields?per_page=9999')
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { logicError('fieldsGet', result); }
        return dataAttribute ? { [dataAttribute]: result.data } : result.data;
      }).catch(catcherInTheCode);
  }

  static keywordsGet = async (dataAttribute) => {
    return GetData('allKeywords?per_page=9999')
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { logicError('keywordsGet', result); }
        return dataAttribute ? { [dataAttribute]: result.data } : result.data;
      }).catch(catcherInTheCode);
  }

  static companyTypesGet = async (dataAttribute) => {
    return GetData('companyTypes?per_page=9999')
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { logicError('companyTypesGet', result); }
        return dataAttribute ? { [dataAttribute]: result.data } : result.data;
      }).catch(catcherInTheCode);
  }

  static rolesGet = async () => {
    return GetData('roles?per_page=9999')
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { logicError('rolesGet', result); }
        return result.data;
      }).catch(catcherInTheCode);
  }

  static rolesTypedGet = async (dataAttribute) => {
    return GetData('roles_typed')
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { logicError('rolesTypedGet', result); }
        return dataAttribute ? { [dataAttribute]: result.data } : result.data;
      }).catch(catcherInTheCode);
  }

  static documentDeleteRequestsGet = async () => {
    return GetData('document-delete-requests?per_page=9999')
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { return logicError('documentDeleteRequestsGet'); }
        return result.data;
      }).catch(catcherInTheCode);
  }

  static documentDeleteRequestCreate = async (id) => {
    const payload = { document_id: id };
    return PostData('document-delete-requests', payload)
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { return logicError('documentDeleteRequestCreate', result); }
        return result.data;
      }).catch(catcherInTheCode);
  }

  static documentDeleteRequestAccept = async (payload) => {
    if (!payload) { return { error: 'Invalid input, need d!' }; } // TODO: Improve

    return PostData(`document-delete-requests/${payload.id}/accept`)
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { return logicError('documentDeleteRequestAccept', result); }
        return result.data;
      }).catch(catcherInTheCode);
  }

  static documentDeleteRequestReject = async (payload) => {
    if (!payload) { return { error: 'Invalid input, need payload!' }; } // TODO: Improve

    return DeleteData(`document-delete-requests/${payload.id}`)
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { return logicError('documentDeleteRequestReject', result); }
        return result.data;
      }).catch(catcherInTheCode);
  }

  static documentTypesGet = async (dataAttribute) => {
    return GetData('document-types')
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { return logicError('getDocumentTypes', result); }

        return dataAttribute ? { [dataAttribute]: result.data } : result.data;
      }).catch(catcherInTheCode);
  }

  static documentTypesCreate = async (name) => {
    const payload = {
      name,
      is_immutable: false, // TODO
      is_default: false, // TODO
      is_active: true, // TODO
    };

    return PostData('document-types', payload)
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { return logicError('documentTypesCreate', result); }
        return result.data;
      }).catch(catcherInTheCode);
  }

  static documentTypesDelete = async (id) => {
    return DeleteData(`document-types/${id}`)
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result) { return logicError('userPhotoDelete', result); }
        return result;
      }).catch(catcherInTheCode);
  }

  static documentTypesUpdate = async (id, payload) => {
    return PutData(`document-types/${id}`, payload)
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { return logicError('documentTypesUpdate', result); }
        return result;
      }).catch(catcherInTheCode);
  }

  static documentTypeFieldsUpdate = async (id, payload) => {
    return PutData(`document-types/${id}/fields`, payload)
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { return logicError('documentTypesUpdate', result); }
        return result;
      }).catch(catcherInTheCode);
  }

  static fieldCreate = async ({ fieldName, fieldType, keywords, showInDocumentsTable }) => {
    const payload = {
      key: fieldName,
      type: fieldType,
      keywords,
      show_in_documents_table: false, // Will this be used or not?
    };

    return PostData('fields', payload)
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { return logicError('fieldCreate', result); }
        return result.data;
      }).catch(catcherInTheCode);
  }

  static fieldUpdate = async (id, { fieldName, fieldType, keywords }) => {
    const payload = {
      key: fieldName,
      type: fieldType,
      keywords,
      show_in_documents_table: true,
    };

    return PutData(`fields/${id}`, payload)
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { return logicError('fieldUpdate', result); }
        return result;
      }).catch(catcherInTheCode);
  }

  static fieldDelete = async (id) => {
    return DeleteData(`fields/${id}`)
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result) { return logicError('fieldDelete', result); }
        return result;
      }).catch(catcherInTheCode);
  }

  static keywordUpdate = async (payload) => {
    // It seems that core only checks the keyword id - field id is irrelevant
    return PutData(`fields/0/keywords/${payload.keyword}`, payload)
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result.data) { return logicError('keywordUpdate', result); }
        return result;
      }).catch(catcherInTheCode);
  }

  static keywordDelete = async (id) => {
    // It seems that core only checks the keyword id - field id is irrelevant
    return DeleteData(`fields/0/keywords/${id}`)
      .then((result) => {
        if (getResultError(result)) { return getResultError(result); }
        if (!result) { return logicError('keywordDelete', result); }
        return result;
      }).catch(catcherInTheCode);
  }
}

export default APIService;
