import React, { Component } from 'react';
import I18n from 'i18n-js';
import {
  Modal,
  Toast,
  Button,
  Row,
  Col,
  Container,
  ButtonGroup,
} from 'react-bootstrap';
import { Rnd } from 'react-rnd';
import SecureImage from '../../SecureImage/SecureImage.js';
import APIService from '../../../../AvainiaTools/APIService.js';
import AvainiaPermissions from '../../../../AvainiaTools/AvainiaPermissions.js';
import LocalStorageService from '../../../../AvainiaTools/LocalStorageService.js';
import EditableField from './Components/EditableField.js';
import ShowFields from './Components/ShowFields.js';
import NewField from './Components/NewField.js';

class ModalViewDocument extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fields: [],
      fieldData: [],
      newPosition: false,
      editCoords: false,
      editMode: false,
      newFields: [],
      loading: false,
      error: false,
    };
  }

  updateFieldData = (fieldId, fieldValue) => {
    this.setState((prevState) => ({
      fieldData: {
        ...prevState.fieldData,
        [fieldId]: fieldValue,
      },
    }));
  }

  deactivateEditMode = (e) => {
    this.setState({
      editCoords: false,
      editMode: false,
    }, () => { window.editmap = null; });
  }

  activateEditMode = (e) => {
    this.setState({
      editCoords: false,
      editMode: true,
    });
  }

  editCoords = () => {
    this.setState({ editCoords: true });
  }

  save = () => {
    const { documentActive, project } = this.props;
    const { fields } = this.state;

    const fieldData = Object.entries(this.state.fieldData);

    const payload = {
      fields: [],
      keywords: [],
    };

    fieldData.forEach((fieldSet) => {
      // eslint-disable-next-line eqeqeq
      const field = fields.find((f) => f.id == fieldSet[0]);

      if (!field) {
        return;
      }

      if (field.type === 'keywords') {
        const keywordId = field.keywords.find((keyword) => keyword.text === fieldSet[1]).id;
        payload.keywords.push({
          field_id: field.id,
          id: keywordId,
          text: fieldSet[1],
        });
      } else if (field.type === 'text') {
        payload.fields.push({
          field_id: field.id,
          text: fieldSet[1],
        });
      }
    });

    APIService.doSaveEditedImage(project, documentActive.id, payload).then((result) => {
      if (!result || result.error) { return this.setState({ error: 4220 }); }
      this.props.documentEditedCallback(result);
    });
  }

  download = async (e) => {
    const targetDocument = this.props.documentActive;

    try {
      const obj = { headers: { Authorization: `Bearer ${LocalStorageService.getToken()}` } };
      const res = await fetch(process.env.REACT_APP_API_HOST + targetDocument.url, obj);

      if (res.ok) {
        const binary = await res.blob();

        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(binary, targetDocument.files[0].full_name);
        } else {
          const src = window.URL.createObjectURL(binary);
          const a = document.createElement('a');
          document.body.appendChild(a);
          a.style.cssText = 'display: none';
          a.href = src;
          a.download = targetDocument.files[0].full_name;
          a.click();
          window.URL.revokeObjectURL(src);
          setTimeout((x) => { document.body.removeChild(a); }, 1000);
        }
      } else if (res.status !== 410) { // 410 means dont retry
        throw new Error('failed to fetch document'); // TODO! Fatal error, do actual logging
      }
    } catch (ex) {
      console.error(ex); // TODO! Fatal error, do actual logging
    }
  }

  delete = (e) => {
    if (!window.confirm(I18n.t('views.infraproject.confirm-document-delete'))) { return; }

    const targetDocument = this.props.documentActive;

    APIService.doDeleteDocument(this.props.project, targetDocument).then((result) => {
      if (result.error) { this.setState({ error: result.error }); }

      this.props.documentDeletedCallback();
    });
  }

  initializeEditMap = () => {
    const activeDocument = this.props.documentActive;

    const defaultCoords = { lat: 60.73142604597615, lng: 31.658685119061715 }; // TODO: DEDUPE
    const center = activeDocument.coordinates || defaultCoords;
    const options = { // TODO: DEDUPE, Map.js and below
      center,
      zoom: 10,
      fullscreenControl: false,
      zoomControl: true,
      scaleControl: false,
      mapTypeControl: true,
    };

    window.editmap = new window.google.maps.Map(document.getElementById('editCoordsModalMap'), options);

    if (window.editmarker) { window.editmarker.setMap(null); }

    // It is possible that an document has no coordinates if it was added from the browser TODO: What to do in this case
    if (activeDocument.coordinates) {
      window.editmarker = new window.google.maps.Marker({
        position: activeDocument.coordinates,
        map: window.editmap,
      });
    }

    window.editmap.addListener('click', (e) => {
      if (window.editmarker) {
        window.editmarker.setMap(null);
      }

      const newPosition = e.latLng;

      window.editmarker = new window.google.maps.Marker({
        position: newPosition,
        map: window.editmap,
      });

      window.editmap.panTo(newPosition);

      const gpsField = this.state.fields.find((field) => field.key === 'gps_coordinates');
      const gpsAccurary = this.state.fields.find((field) => field.key === 'gps_accuracy');

      const fixedNewPosition = `${newPosition}`.replace(' ', '').replace('(', '').replace(')', '');

      this.updateFieldData(gpsField.id, fixedNewPosition);
      this.updateFieldData(gpsAccurary.id, 0);

      this.setState({ newPosition: fixedNewPosition });
    });
  }

  getRndDefaultValues = () => {
    const defaultValues = {
      width: 380,
      height: 730,
      x: 10,
      y: 10,
    };

    if (window[`AvainiaDetailSize_${this.props.parent}`]) {
      defaultValues.width = window[`AvainiaDetailSize_${this.props.parent}`].width;
      defaultValues.height = window[`AvainiaDetailSize_${this.props.parent}`].height;
    }

    if (window[`AvainiaDetailPosition_${this.props.parent}`]) {
      defaultValues.x = window[`AvainiaDetailPosition_${this.props.parent}`].x;
      defaultValues.y = window[`AvainiaDetailPosition_${this.props.parent}`].y;
    }

    return defaultValues;
  }

  componentDidMount = () => {
    if (!this.rnd) { return; }

    const defaultValues = this.getRndDefaultValues();
    this.rnd.updateSize({ width: defaultValues.width, height: defaultValues.height });
    this.rnd.updatePosition({ x: defaultValues.x, y: defaultValues.y });

    APIService.fieldsGet().then((fields) => {
      if (fields.error) { return this.setState({ error: fields.error }); }
      this.setState({ fields });
    });
  }

  componentDidUpdate = () => {
    if (!this.state.editCoords) { return; }
    if (window.editmap) { return; }

    if (window.google) {
      return this.initializeEditMap();
    }

    const scriptNode = document.createElement('script');
    scriptNode.type = 'text/javascript';
    scriptNode.id = 'googleMapsInsert';
    scriptNode.src = 'https://maps.google.com/maps/api/js?key=AIzaSyCn6T7i_CYc8AFNuT3idK-M0PjLG07anN4'; // TODO! DEDUPLICATE

    document.head.appendChild(scriptNode);

    // Wait for map to load
    scriptNode.addEventListener('load', this.initializeEditMap);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.documentActive !== prevState.documentActive) {
      const docFields = nextProps.documentActive.fields.concat(nextProps.documentActive.keywords);

      let fieldData = {};

      docFields.forEach((docField) => {
        fieldData = { ...fieldData, [docField.field_id]: docField.text };
      });

      return ({ docFields, fieldData, documentActive: nextProps.documentActive });
    }

    return null;
  }

  addNewField = (field) => {
    this.setState({ newFields: [...this.state.newFields, field] });
  }

  render() {
    const { documentActive, hideModal, disableEdit, disableDelete, show } = this.props;

    if (!documentActive || !show) { return <></>; }

    const { editMode, docFields, fields, newPosition } = this.state;
    const user = LocalStorageService.getUser();
    const canEdit = disableEdit ? false : user.hasPermission(AvainiaPermissions.DocumentEdit);
    const canDelete = disableDelete ? false : user.hasPermission(AvainiaPermissions.DocumentsDelete);

    const unusedFields = [];

    documentActive.document_type.fields.forEach((f) => {
      const found = docFields.find((df) => df.field_id === f.id);
      if (!found) {
        unusedFields.push(f);
      }
    });

    const toast = <Toast
      style={{
        width: '100%',
        maxWidth: '100%',
        height: '100%',
        overflowY: 'auto',
      }}
      onClose={hideModal}
      animation={false}
      >
      <Toast.Header>
        <strong className="mr-auto">{documentActive.counter} - {I18n.t('views.infraproject.info')}</strong>
      </Toast.Header>
      <Toast.Body>
        <SecureImage className="document-thumbnail" src={documentActive.thumb} />
        <Container>
          <Row>
            <Col>
              {editMode
                ? <React.Fragment>
                  {docFields.map((field) => <EditableField
                    field={field}
                    updateFieldData={this.updateFieldData}
                    allFields={fields}
                    editCoords={this.editCoords}
                    newPosition={newPosition}
                    key={field.field_id}
                    isEditCoords={this.state.editCoords}
                  />)}
                  {unusedFields.map((field) => <NewField
                    key={field.key}
                    field={field}
                    updateFieldData={this.updateFieldData}
                  />)}
                </React.Fragment>
                : <ShowFields
                  doc={documentActive}
                  fields={fields}
                />
              }
            </Col>
          </Row>
        </Container>
        <hr/>
        <Container>
        <ButtonGroup className="d-flex">
          {editMode && <Button onClick={this.save}>{I18n.t('general.save')}</Button>}
          {editMode && <Button onClick={this.deactivateEditMode}>{I18n.t('general.cancel')}</Button>}
          {!editMode && canEdit && <Button onClick={this.activateEditMode}>{I18n.t('views.infraproject.edit')}</Button>}
          {!editMode && <Button onClick={this.download}>{I18n.t('views.infraproject.download')}</Button>}
          {!editMode && canDelete && <Button onClick={this.delete}>{I18n.t('views.infraproject.delete')}</Button>}
          <Button onClick={(e) => { this.props.hideModal(); }}>{I18n.t('general.modal-close')}</Button>
        </ButtonGroup>
        </Container>
      </Toast.Body>
    </Toast>;

    if (editMode) {
      return <Modal show={true} onHide={this.props.onHide} size="lg">
        <div style={{ maxHeight: '80vh', overflowY: 'auto' }}>
          {toast}
        </div>
      </Modal>;
    }

    /*
     * The RndWrapper acts as a zero point and provides a fixed position to which the Rnd can be relative to the
     * This looks hacky but is needed because Rnd uses a transform(x,y) to position its element.
     * TODO: The Z-index value was chosen randomly, create a defined ruleset of layers!
     */
    return <div
      id="RndWrapper"
      style={{
        position: 'fixed',
        top: 1,
        left: 1,
        zIndex: 9220,
      }}
    >
      <Rnd
        id="ModalViewDocument"
        ref={(c) => { this.rnd = c; }}
        // default={defaultValues}
        bounds={'#root'}
        onResizeStop={(event, direction, element, delta, position) => {
          window[`AvainiaDetailSize_${this.props.parent}`] = { width: element.style.width, height: element.style.height };
          window[`AvainiaDetailPosition_${this.props.parent}`] = { x: position.x, y: position.y };
        }}
        onDragStop={(event, dataObject) => {
          window[`AvainiaDetailSize_${this.props.parent}`] = { width: dataObject.node.style.width, height: dataObject.node.style.height };
          window[`AvainiaDetailPosition_${this.props.parent}`] = { x: dataObject.x, y: dataObject.y };
        }}
        minWidth='110'
        minHeight='120'
        style={{
          border: '3px solid #ccc',
          borderRadius: '3px',
          zIndex: 9221,
          margin: 10,
          position: 'fixed',
          // transform: 'initial !important',
        }}
      >
        {toast}
      </Rnd>
    </div>;
  }
}

export default ModalViewDocument;
