import React, { Component } from 'react';
import I18n from 'i18n-js';
import { Form, FormControl, InputGroup, Button } from 'react-bootstrap';

class EditableField extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: '',
      changed: false,
    };
  }

  setValue = (value) => {
    this.setState({ value, changed: true });
  }

  render() {
    const { field, updateFieldData, allFields, editCoords, newPosition } = this.props;

    const globalField = allFields.find((f) => f.id === field.field_id);

    const readOnly = globalField.is_automatic;

    if (globalField.type === 'keywords') {
      const options = globalField.keywords
        .sort((a, b) => { return a.text > b.text ? 1 : -1; })
        .map((kw) => <option disabled={kw.deactivated} key={kw.id} value={kw.text}>{kw.text}</option>);

      return <Form.Group>
        <Form.Label>{I18n.t(`fields.${globalField.key}`, { defaultValue: globalField.key })}</Form.Label>
        <FormControl
          as="select"
          defaultValue={field.text}
          onChange={(e) => {
            this.setValue(e.target.value);
            updateFieldData(globalField.id, e.target.value);
          }}
        >
          { options }
        </FormControl>
      </Form.Group>;
    }

    if (globalField.type === 'text') {
      const fieldIsGps = globalField.key === 'gps_coordinates';

      let inputValue = this.state.value;

      if (newPosition && fieldIsGps) {
        inputValue = newPosition;
      }

      if (!inputValue && field.text && !this.state.changed) {
        inputValue = field.text;
      }

      if (fieldIsGps) {
        return <Form.Group>
          <Form.Label>{I18n.t(`fields.${globalField.key}`, { defaultValue: globalField.key })}</Form.Label>
          <InputGroup>
            <FormControl
              placeholder={field.text}
              type="text"
              value={inputValue}
              readOnly={readOnly}
              onChange={(e) => {
                this.setValue(e.target.value);
                updateFieldData(globalField.id, e.target.value);
              }}
            />
            <InputGroup.Append>
              <Button
                variant="outline-secondary"
                onClick={editCoords}
              >
                {I18n.t('general.edit')}
              </Button>
            </InputGroup.Append>
          </InputGroup>
          {this.props.isEditCoords && fieldIsGps && <div id="editCoordsModalMap"></div>}
        </Form.Group>;
      }

      return <Form.Group>
        <Form.Label>{I18n.t(`fields.${globalField.key}`, { defaultValue: globalField.key })}</Form.Label>
        <FormControl
          placeholder={field.text}
          type="text"
          value={inputValue}
          readOnly={readOnly}
          onChange={(e) => {
            this.setValue(e.target.value);
            updateFieldData(globalField.id, e.target.value);
          }}
        />
        {this.props.isEditCoords && fieldIsGps && <div id="editCoordsModalMap"></div>}
      </Form.Group>;
    }

    return false;
  }
}

export default EditableField;
