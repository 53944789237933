class TabService {
  /* Adds #{tab} to url and pushes that url to history. Usage usually inside tabChange() */
  static updateUrl = (tab) => {
    window.location = `#${tab}`;
  }

  /* Go straight to the corresponding tab indicated by urls #. Usage inside ComponentDidUpdate */
  static getTab = () => {
    return window.location.hash ? window.location.hash.slice(1) : null;
  }
}

export default TabService;
