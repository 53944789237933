const AvainiaPermissions = {
  CompaniesManage: 'p:companies.manage',
  CompanyUsersManage: 'p:company-users.manage',
  DocumentCreate: 'p:document.create',
  DocumentDeleteRequestsManage: 'p:document-delete-requests.manage',
  DocumentEdit: 'p:document.edit',
  DocumentsAccess: 'p:documents.access',
  DocumentsDelete: 'p:documents.delete',
  Import: 'p:import',
  KeywordsManage: 'p:keywords.manage',
  ProjectsAccess: 'p:projects.access',
  ProjectsManage: 'p:projects.manage',
  ProductsManage: 'p:products.manage',
  // PermissionsManage: 'p:permissions.manage', -- disabled, replaced with ownermanager
  RolesManage: 'p:roles.manage',
  UsersManage: 'p:users.manage',
  CondominiumsManage: 'p:condominiums.manage',
  // DefaultoldersManage: 'p:defaultfolder.manage', -- disabled, replaced with ownermanager
};

export default AvainiaPermissions;
