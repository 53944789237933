import React, { Component } from 'react';
import I18n from 'i18n-js';
import { Button, FormControl, Modal } from 'react-bootstrap';
import APIService from '../../../AvainiaTools/APIService.js';
import Error from '../Error/Error.js';
import Loading from '../Loading/Loading.js';

class ModalFolderAdd extends Component {
  constructor(props) {
    super(props);

    this.state = {
      foldername: '',
      parentId: null,
      loading: false,
      error: false, // TODO: Implement
    };
  }

  addFolder = async (e) => {
    const payload = {
      name: this.state.foldername,
      parentId: this.props.parentFolder ? this.props.parentFolder.id : null,
    };

    this.setState({ loading: true }, () => {
      APIService.projectFolderCreate(this.props.project, payload).then((result) => {
        if (result.error) { return this.setState({ error: result.error, loading: false }); }

        window.location.reload(); // TODO: Improve
      });
    });
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    return <Modal show={true} onHide={this.props.hideModal}>
      <Modal.Header closeButton>
        <Modal.Title>{I18n.t('views.infraproject.add-folder')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {this.props.parentFolder && <>
          <FormControl
            value={`${I18n.t('folders.parentFolder')} ${this.props.parentFolder.name}`}
            disabled
            name="name"
            type="text"
          />
          <br />
        </>}

        {this.state.error && <Error error={this.state.error} inline /> }
        {this.state.loading && <Loading error={this.state.error} inline /> }
        {!this.state.loading &&
          <FormControl
            placeholder={I18n.t('folders.name')}
            name="foldername"
            type="text"
            value={this.state.name}
            onChange={this.onChange}
          />
        }
      </Modal.Body>
      <Modal.Footer>
        {!this.state.loading && <>
          <Button variant="secondary" onClick={this.props.hideModal}>{I18n.t('general.cancel')}</Button>
          <Button variant="primary" onClick={this.addFolder}>{I18n.t('general.save')}</Button>
        </>}
      </Modal.Footer>
    </Modal>;
  }
}

export default ModalFolderAdd;
