import React, { Component } from 'react';
import { Form, Button, ListGroup } from 'react-bootstrap';
import I18n from 'i18n-js';
import Error from '../../../multiview/Error/Error.js';
import Loading from '../../../multiview/Loading/Loading.js';
import APIService from '../../../../AvainiaTools/APIService.js';
import ModalContactEdit from '../../../multiview/Modals/ModalContactEdit.js';
import AvainiaPanel from '../../../multiview/Panels/AvainiaPanel.js';
import AvainiaList from '../../../multiview/AvainiaList/AvainiaList.js';
import AvainiaPermissions from '../../../../AvainiaTools/AvainiaPermissions.js';
import LocalStorageService from '../../../../AvainiaTools/LocalStorageService.js';
import { Edit, Trash, Users } from '../../../multiview/Icon/Icon.js';

const Modals = {
  contactEdit: 1,
};

class ContactTab extends Component {
  constructor(props) {
    super(props);

    this.state = {
      contacts: [],
      contactToEdit: false,
      error: false,
      loading: false,
      first_name: '',
      last_name: '',
      title: '',
      phone: '',
      email: '',
      modal: false,
    };
  }

  componentDidMount() { this.getContacts(); }

  getContacts() {
    const { id } = this.props.project;

    const promises = [
      APIService.projectContactsGet(id, 'contacts'),
    ];

    Promise.all(promises).then((data) => {
      let error = false;
      let contacts;

      data.forEach((x) => {
        if (error) { return; }
        if (x.error) { error = x.error; return; }
        if (x.contacts) { contacts = x.contacts; }
      });

      if (error) { return this.setState({ error }); }

      this.setState({ contacts, loading: false });
    });
  }

  createContact = (e) => {
    if (this.state.loading) { return; }

    this.setState({ loading: true, error: false }, () => {
      const payload = {
        first_name: this.state.first_name,
        last_name: this.state.last_name,
        title: this.state.title,
        phone: this.state.phone,
        email: this.state.email,
      };

      APIService.projectContactsCreate(this.props.project.id, payload).then((contact) => {
        if (contact.error) { return this.setState({ error: contact.error, loading: false }); }

        const { contacts } = this.state;
        contacts.push(contact);

        this.setState({
          contacts,
          first_name: '',
          last_name: '',
          title: '',
          phone: '',
          email: '',
          loading: false,
        });
      });
    });
  }

  delete = (e) => {
    if (this.state.loading) { return; }

    this.setState({ loading: true, error: false }, () => {
      APIService.projectContactsDelete(this.props.project.id, e.id).then((contact) => {
        if (contact.error) { this.setState({ error: contact.error, loading: false }); }

        const contacts = this.state.contacts.filter((x) => x.id !== contact.id);
        this.setState({ contacts, loading: false });
      });
    });
  }

  formChange = (event) => {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;

    this.setState({ [name]: value });
  }

  edit = (contact) => {
    this.setState({ contactToEdit: contact, modal: Modals.contactEdit });
  }

  hideModal = (e) => { this.setState({ modal: false }); }

  refreshView = () => { this.getContacts(); }

  render() {
    const user = LocalStorageService.getUser();
    const canManage = user.hasPermission(AvainiaPermissions.ProjectsManage);

    return <div className="App-container">
      {this.props.showRenderedContacts && <AvainiaPanel heading={I18n.t('views.contacts.contacts')} icon={<Users />}>
        {this.state.contacts.map((contact, index) => <AvainiaList title={`${contact.title} ${contact.first_name} ${contact.last_name}`} key={index}>
            <li>
              <div> {contact.phone} </div>
              <div> {contact.email} </div>
            </li>
          </AvainiaList>)}
      </AvainiaPanel>}

      {this.state.error && <Error error={this.state.error} inline />}
      {!this.state.error && this.state.loading && <Loading inline />}
      {!this.state.loading && canManage && <>
        <ListGroup className="listing">
          {this.state.contacts.map((contact) => <ListGroup.Item key={contact.id}>
            <Trash onClick={() => { this.delete(contact); }} />
            <Edit onClick={() => { this.edit(contact); }} />
            <span className="clickable">
              {contact.first_name} {contact.last_name}
            </span>
          </ListGroup.Item>)}
        </ListGroup>

        <div className="tinyform">
          <h3>{I18n.t('views.contacts.create-contact')}</h3>

          <Form.Label>{I18n.t('views.contacts.create-contact-first_name')}</Form.Label>
          <Form.Control type="text" onChange={this.formChange} name="first_name" value={this.state.first_name} />

          <Form.Label>{I18n.t('views.contacts.create-contact-last_name')}</Form.Label>
          <Form.Control type="text" onChange={this.formChange} name="last_name" value={this.state.last_name} />

          <Form.Label>{I18n.t('views.contacts.create-contact-title')}</Form.Label>
          <Form.Control type="text" onChange={this.formChange} name="title" value={this.state.title} />

          <Form.Label>{I18n.t('views.contacts.create-contact-phone')}</Form.Label>
          <Form.Control type="text" onChange={this.formChange} name="phone" value={this.state.phone} />

          <Form.Label>{I18n.t('general.email')}</Form.Label>
          <Form.Control type="text" onChange={this.formChange} name="email" value={this.state.email} />

          <Button variant="primary" onClick={this.createContact}>{I18n.t('views.contacts.button-create')}</Button>
        </div>
      </>}

      <ModalContactEdit
        show={this.state.modal === Modals.contactEdit}
        onHide={this.hideModal}
        onRefresh={this.refreshView}
        contact={this.state.contactToEdit}
        project={this.props.project}
      />

    </div>;
  }
}

export default ContactTab;
