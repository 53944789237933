import React, { Component } from 'react';
import I18n from 'i18n-js';
import { Form, FormControl } from 'react-bootstrap';

class NewField extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: '',
    };
  }

  setValue = (value) => {
    this.setState({ value });
  }

  render() {
    const { field, updateFieldData } = this.props;

    if (field.key.includes('gps')) {
      return false;
    }

    let fieldComponent = false;
    const fieldLabel = I18n.t(`fields.${field.key}`, { defaultValue: field.key });

    if (field.type === 'text') {
      fieldComponent = <Form.Group>
        <Form.Label>{fieldLabel}</Form.Label>
        <FormControl
          type="text"
          value={this.state.value}
          onChange={(e) => {
            this.setValue(e.target.value);
            updateFieldData(field.id, e.target.value);
          }
        }/>
      </Form.Group>;
    } else if (field.type === 'keywords') {
      const options = field.keywords
        .filter((kw) => !kw.deactivated)
        .sort((a, b) => { return a.text > b.text ? 1 : -1; })
        .map((kw) => <option key={kw.id} value={kw.text}>{kw.text}</option>);
      fieldComponent = <Form.Group>
        <Form.Label>{fieldLabel}</Form.Label>
        <Form.Control
          as="select"
          value={this.state.value}
          onChange={(e) => {
            this.setValue(e.target.value);
            updateFieldData(field.id, e.target.value);
          }
        }>
          <option key={0} value="" disabled>-</option>
          { options }
        </Form.Control>
      </Form.Group>;
    }

    return fieldComponent;
  }
}

export default NewField;
