import React, { Component } from 'react';
import './AvainiaList.scss';
import { NavLink } from 'react-router-dom';

class AvainiaList extends Component {
  render() {
    return <ul {...this.props} className="avainia-list">
      {this.props.title && <li className="avainia-list-title">
        {this.props.link && <NavLink to={this.props.link}> {this.props.title} </NavLink> }
        {!this.props.link && <span>{this.props.title}</span> }
      </li>}
      {this.props.children}
    </ul>;
  }
}

export default AvainiaList;
