import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';
import I18n from 'i18n-js';
import { Trash, Link, Search } from '../Icon/Icon.js';
import AvainiaTable from '../AvainiaTable/AvainiaTable.js';
import APIService from '../../../AvainiaTools/APIService.js';
import UtilService from '../../../AvainiaTools/UtilService.js';
import LocalStorageService from '../../../AvainiaTools/LocalStorageService.js';
import Error from '../Error/Error.js';
import Loading from '../Loading/Loading.js';
import SecureImage from '../SecureImage/SecureImage.js';

import './ModalDocumentDeleteRequests.scss';

class ModalDocumentDeleteRequests extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fields: [],
      subjects: [],
      phases: [],
      navigate: false,
      examine: false,
      loading: true,
      error: false,
    };
  }

  componentDidMount = () => {
    const promises = [
      APIService.getAllPhases(),
      APIService.getAllSubjects(),
      APIService.getFields('fields'),
    ];

    Promise.all(promises).then((data) => {
      let error = false;
      let phases;
      let subjects;
      let fields;

      data.forEach((x) => {
        if (error) { return; }
        if (x.error) { error = x.error; return; }
        if (x.phases) { phases = x.phases.filter((p) => !p.deactivated); }
        if (x.subjects) { subjects = x.subjects; }
        if (x.fields) { fields = x.fields; }
      });

      if (error) { return this.setState({ error, loading: false }); }

      this.setState({ phases, subjects, fields, loading: false });
    });
  }

  hider = () => {
    this.props.onHide();
  }

  acceptDeleteRequest = (row) => {
    if (!window.confirm(I18n.t('documents.confirmAcceptDeleteRequest'))) { return; }

    APIService.documentDeleteRequestAccept(this.state.examine.deleteRequest).then((result) => {
      if (result.error) { return this.setState({ error: result.error }); }

      this.props.refreshDeleteRequests();
    });
  };

  rejectDeleteRequest = (row) => {
    if (!window.confirm(I18n.t('documents.confirmRejectDeleteRequest'))) { return; }

    APIService.documentDeleteRequestReject(this.state.examine.deleteRequest).then((result) => {
      if (result.error) { return this.setState({ error: result.error }); }

      this.props.refreshDeleteRequests();
    });
  };

  examine = (deleteRequest) => {
    this.setState({ loading: true });
    // Get project
    // Get document

    APIService.projectGet(deleteRequest.project_id).then((project) => {
      if (project.error) { return this.setState({ error: project.errror, loading: false }); }

      this.setState({
        examine: {
          deleteRequest,
          document: UtilService.dbDocumentToImage(deleteRequest.document, project.id, this.state.fields, 0),
          project,
        },
        loading: false,
      });
    });
  }

  examineEnd = () => {
    this.setState({ examine: false });
  }

  // TODO: This is duplicated code! Move to Utils?
  download = async (e) => {
    const targetDocument = this.state.examine.document;

    try {
      const obj = { headers: { Authorization: `Bearer ${LocalStorageService.getToken()}` } };
      const res = await fetch(process.env.REACT_APP_API_HOST + targetDocument.url, obj);

      if (res.ok) {
        const binary = await res.blob();

        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(binary, targetDocument.name);
        } else {
          const src = window.URL.createObjectURL(binary);
          const a = document.createElement('a');
          document.body.appendChild(a);
          a.style.cssText = 'display: none';
          a.href = src;
          a.download = targetDocument.name;
          a.click();
          window.URL.revokeObjectURL(src);
          setTimeout((x) => { document.body.removeChild(a); }, 1000);
        }
      } else if (res.status !== 410) { // 410 means dont retry
        throw new Error('failed to fetch document'); // TODO! Fatal error, do actual logging
      }
    } catch (ex) {
      console.error(ex); // TODO! Fatal error, do actual logging
    }
  }

  renderActions = (cell, row) => {
    return <>
      <Link onClick={() => { this.acceptDeleteRequest(row); }} className="clickable" />
      <Trash onClick={() => { this.rejectDeleteRequest(row); }} className="clickable" />
    </>;
  }

  render() {
    if (this.state.navigate) {
      return <Redirect to={`/project/${this.state.navigate}`} push={true} />;
    }

    return <Modal show={this.props.show} onHide={this.hider} id="document-delete-requests-modal" size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{I18n.t('documents.deleteRequests')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {this.state.error && <Error inline error={this.state.error} />}
        {!this.state.error && this.state.loading && <Loading inline />}

        {!this.state.error && !this.state.loading && <>
          {!this.state.examine && <AvainiaTable data={this.props.documentDeleteRequests} keyField="id" columns={[
            { dataField: 'id', text: I18n.t('general.id'), headerStyle: { width: '60px' } },
            {
              dataField: 'documen',
              text: I18n.t('general.document'),
              formatter: (cell, row) => { return <>{row.document.id}</>; },
            },
            {
              dataField: 'projj',
              text: I18n.t('views.projects.project'),
              formatter: (cell, row) => { return <>{row.project_id}</>; },
            },
            {
              dataField: 'creatr',
              text: I18n.t('documents.deleteRequestRequestedBy'),
              formatter: (cell, row) => { return <>{row.created_by.email}</>; },
            },
            {
              dataField: 'actions',
              text: I18n.t('general.table-actions'),
              headerStyle: { width: '100px' },
              formatter: (cell, row) => { return <Search className="clickable" onClick={() => this.examine(row)} />; },
            },
          ]} />}
          {this.state.examine && <>
            <div style={{ width: '60%', margin: '0 auto', textAlign: 'center' }}>
              <SecureImage className="image-thumbnail" src={this.state.examine.document.thumb} />
              <Button size="sm" onClick={this.download}>{I18n.t('views.infraproject.download')}</Button>
            </div>
            <ul>
              <li>{`${I18n.t('views.projects.project')}: ${this.state.examine.project.code} ${this.state.examine.project.name}`}</li>
              <li>{`${I18n.t('documents.deleteRequestRequestedBy')}: ${this.state.examine.deleteRequest.created_by.name} ${this.state.examine.deleteRequest.created_by.email}`}</li>
              <li>{`${I18n.t('views.infraproject.created')}: ${this.state.examine.document.created}`}</li>
              <li>{`${I18n.t('views.infraproject.company')}: ${this.state.examine.document.addedByCompany}`}</li>
              <li>{`${I18n.t('views.infraproject.phase')}: ${this.state.examine.document.phase}`}</li>
              <li>{`${I18n.t('views.infraproject.subject')}: ${this.state.examine.document.subject}`}</li>
              <li>{`${I18n.t('views.infraproject.info')}: ${this.state.examine.document.detailText}`}</li>
              <li>{`${I18n.t('views.infraproject.position')}: ${this.state.examine.document.positionText}`}</li>
            </ul>

            {/* <SecureImage className="document-thumbnail" src={documentActive.thumb} /> */}
            <Button variant="success" onClick={this.acceptDeleteRequest}>{I18n.t('general.accept')}</Button>
            <Button variant="primary" onClick={this.rejectDeleteRequest}>{I18n.t('general.reject')}</Button>
            <Button variant="secondary" onClick={this.examineEnd}>{I18n.t('general.cancel')}</Button>
          </>}
        </>}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={this.hider}>{I18n.t('general.modal-close')}</Button>
      </Modal.Footer>
    </Modal>;
  }
}

export default ModalDocumentDeleteRequests;
