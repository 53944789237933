import React, { Component } from 'react';
import moment from 'moment';
import I18n from 'i18n-js';
import Graph from './Gantt.js';

class SchedulerGraph extends Component {
  renderGraphChildren(schedule, rows) {
    const lvlIndicatorBase = '-';

    schedule.children.forEach((child) => {
      let lvlIndicator = lvlIndicatorBase;

      for (let i = 1; i < child.lvl; i++) {
        lvlIndicator += lvlIndicatorBase;
      }

      const row = [
        child.id,
        `${lvlIndicator } ${child.description}`,
        moment(child.start_at).unix() * 1000,
        moment(child.end_at).unix() * 1000,
        null,
        null,
        null,
      ];

      rows.push(row);

      if (child.children && child.children.length > 0) {
        rows = this.renderGraphChildren(child, rows);
      }
    });

    return rows;
  }

  render() {
    if (this.props.schedules.length === 0) { return <>{I18n.t('general.no-results')}</>; }

    let rows = [];

    this.props.schedules.forEach((schedule) => {
      const row = [
        schedule.id,
        schedule.description,
        moment(schedule.start_at).unix() * 1000,
        moment(schedule.end_at).unix() * 1000,
        null,
        null,
        null,
      ];

      rows.push(row);

      if (schedule.children && schedule.children.length > 0) {
        rows = this.renderGraphChildren(schedule, rows);
      }
    });

    //! TODO: TRANSLATE
    const graphProps = {
      chartLanguage: 'fi',
      chartPackages: ['gantt'],
      chartType: 'Gantt',
      columns: [
        { label: 'ID', type: 'string' },
        { label: 'Selite', type: 'string' },
        { label: 'Alkaa', type: 'date' },
        { label: 'Aikaisin loppu', type: 'date' },
        { label: 'Kesto', type: 'number' },
        { label: 'Valmiina %', type: 'number' },
        { label: 'Riippuvuudet', type: 'string' },
      ],
      options: {
        gantt: {
          barCornerRadius: 0,
          barHeight: 12,
          criticalPathEnabled: false,
          percentEnabled: false,
          trackHeight: 30,
        },
        height: rows.length * 10,
      },
      rows,
      width: '100%',
    };

    return <Graph data={graphProps} />;
  }
}

export default SchedulerGraph;
