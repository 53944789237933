import React, { Component } from 'react';
import I18n from 'i18n-js';
import { Form, Button, Row, Modal } from 'react-bootstrap';
import Error from '../../multiview/Error/Error.js';
import Loading from '../../multiview/Loading/Loading.js';
import CustomSelect from '../../multiview/Select/CustomSelect.js';

const UserTypes = [
  {
    label: 'Stakeholder',
    value: 'Stakeholder',
  },
  {
    label: 'Tenant',
    value: 'Tenant',
  },
  {
    label: 'Admin',
    value: 'Admin',
  },
];

const Specifiers = [
  {
    label: 'A1',
    value: 'A1',
  },
  {
    label: 'A2',
    value: 'A2',
  },
  {
    label: 'B1',
    value: 'B1',
  },
];

class CondominiumUsersModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userToEdit: this.props.userToEdit,
      loading: false,
      error: false,
    };
  }

  handleUserSelectChange = (name, value) => {
    const { userToEdit } = this.state;
    userToEdit[name] = value.value;
    this.setState({ userToEdit });
  }

  onUserFormChange = (e) => {
    const { name } = e.target;
    const { value } = e.target;

    const { userToEdit } = this.state;
    userToEdit[name] = value;

    this.setState({ userToEdit });
  }

  save = (e) => {
    const editedUser = this.state.userToEdit;

    if (editedUser.new) {
      editedUser.new = false;
      this.props.addCallback(editedUser);
    } else {
      this.props.editCallback(editedUser);
    }

    this.props.onHide();
  }

  remove = () => {
    this.props.removeCallback(this.props.userToEdit.id);
    this.props.onHide();
  }

  render() {
    const typeOptions = this.state.userToEdit.type ? { label: this.state.userToEdit.type, value: this.state.userToEdit.type } : null;
    const specifiersOptions = this.state.userToEdit.specifier ? { label: this.state.userToEdit.specifier, value: this.state.userToEdit.specifier } : null;

    if (this.state.error) { return <Error error={this.state.error} />; }
    if (this.state.loading) { return <Loading />; }

    return <Modal show={true} onHide={this.props.onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>
          {!this.state.userToEdit.new && I18n.t('views.condominiums.users.edit-user')}
          {this.state.userToEdit.new && I18n.t('views.condominiums.users.add-user')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="tinyform">
          <Form.Group as={Row}>
            <CustomSelect
              name="type"
              placeholder={I18n.t('views.condominiums.users.type')}
              handleChange={this.handleUserSelectChange}
              selectOptions={UserTypes}
              value={typeOptions}
            />
          </Form.Group>
          <Form.Group as={Row}>
            <CustomSelect
              name="specifier"
              placeholder={I18n.t('views.condominiums.users.specifier')}
              handleChange={this.handleUserSelectChange}
              selectOptions={Specifiers}
              value={specifiersOptions}
            />
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Control placeholder={I18n.t('views.condominiums.users.name')} type="text" onChange={this.onUserFormChange} name="name" value={this.state.userToEdit.name} />
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Control placeholder={I18n.t('general.email')} type="text" onChange={this.onUserFormChange} name="email" value={this.state.userToEdit.email} />
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Control placeholder={I18n.t('views.condominiums.users.phone')} type="text" onChange={this.onUserFormChange} name="phone" value={this.state.userToEdit.phone} />
          </Form.Group>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="user-buttons">
          {!this.state.userToEdit.new && <Button variant="danger" onClick={this.remove}>{I18n.t('general.delete')}</Button> }
          <Button variant="success" disabled={this.state.loading} onClick={this.save}>{I18n.t('general.modal-form-save')}</Button>
          <Button variant="secondary" onClick={this.props.onHide}>{I18n.t('general.cancel')}</Button>
        </div>
      </Modal.Footer>
    </Modal>;
  }
}

export default CondominiumUsersModal;
