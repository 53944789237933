
import React, { Component } from 'react';
import I18n from 'i18n-js';
import { NavLink } from 'react-router-dom';
import Sidebar from '../Sidebar/Sidebar.js';
import MobileMenu from '../Mobilemenu/MobileMenu.js';
import LocalStorageService from '../../../AvainiaTools/LocalStorageService.js';
import AvainiaPermissions from '../../../AvainiaTools/AvainiaPermissions.js';
import { Shield, Project, Box, Users, UsersCog, City, Cog, Language, Lock, File } from '../Icon/Icon.js';

const AvainiaLinks = (props) => {
  const { cfg } = props;

  //! NOTE: This needs to be in sync with App.js
  const user = LocalStorageService.getUser();
  const productsManage = cfg.productsAndMaterialselection && user.hasPermission(AvainiaPermissions.ProductsManage);
  const companiesManage = user.hasPermission(AvainiaPermissions.CompaniesManage);
  const myCompany = user && (user.hasRole('manager') || user.hasRole('super'));
  const company = user.companies[0]; // TODO! This ASSUMES the user has exactly one company
  const admin = user && (user.hasRole('owner') || user.hasRole('super'));
  const ownerManager = user && user.isOwnerManager();

  return <>
    {cfg.projects && <NavLink to="/projects"><Project />{I18n.t('views.sidebar.link-projects')}</NavLink>}

    {cfg.condominiums && <NavLink to="/condominiums"><City />{I18n.t('views.sidebar.link-condominiums')}</NavLink>}

    {productsManage && <NavLink to="/products"><Box />{I18n.t('views.sidebar.link-products')}</NavLink>}

    <NavLink to="/privacy"><Shield />{I18n.t('views.sidebar.link-privacy')}</NavLink>

    {companiesManage && <NavLink to="/companies"><Users />{I18n.t('views.sidebar.link-companies')}</NavLink>}

    {myCompany && <NavLink to={`/company-management/${company.id}`}><UsersCog />{I18n.t('views.sidebar.link-company-management')}</NavLink>}

    {admin && false && <NavLink to="/translations"><Language />{I18n.t('views.sidebar.link-translations')}</NavLink>}

    {ownerManager && <NavLink to="/admin"><Cog />{I18n.t('views.sidebar.link-admin')}</NavLink>}

    {ownerManager && <NavLink to="/document-types"><File />{I18n.t('views.sidebar.document-types')}</NavLink>}

    {ownerManager && <NavLink to="/permissions"><Lock />{I18n.t('views.sidebar.link-permissions')}</NavLink>}
  </>;
};

class AvainiaNav extends Component {
  render() {
    const config = this.props.configuration;
    const links = <AvainiaLinks cfg={config}/>;
    const user = LocalStorageService.getUser();

    return <>
      <Sidebar cfg={config} user={user} AvainiaLinks={links}/>
      <MobileMenu configuration={config} AvainiaLinks={links}/>
    </>;
  }
}

export default AvainiaNav;
