import React, { Component } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Key } from '../Icon/Icon.js';
import Form from 'react-bootstrap/Form';
import I18n from 'i18n-js';
import APIService from '../../../AvainiaTools/APIService.js';
import LocalStorageService from '../../../AvainiaTools/LocalStorageService.js';
import Error from '../Error/Error.js';

class ReLoginModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      error: '',
    };
  }

  componentDidMount() {
    const { email } = LocalStorageService.getUser();
    this.setState({ email });
  }

  doLogin = (e) => {
    e.preventDefault();
    e.stopPropagation();

    APIService.login(this.state.email, this.state.password)
      .then((result) => {
        if (result.error) {
          this.setState({ error: 8 });
          return;
        }

        LocalStorageService.setUser(result);
        this.props.onHide();
        this.props.callBack();
        this.props.loginCallback();
      })
      .catch((error) => { console.error('Error during login', error); });
  }

  setPassword = (e) => {
    const password = e.target.value;
    this.setState({ password });
  }

  render() {
    return <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={this.props.show}
      onHide={this.props.onHide}
    >
    <Modal.Header closeButton>
      <Modal.Title id="contained-modal-title-vcenter">
        <Key className="pr-2" />{I18n.t('views.modals.relogin.extend-session')}
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Form.Group controlId="formBasicEmail">
        <Form.Label>{I18n.t('general.email')}</Form.Label>
        <Form.Control type="email" placeholder={this.state.email} disabled />
      </Form.Group>
      <Form.Group controlId="formBasicPassword">
        <Form.Label>{I18n.t('general.password')}</Form.Label>
        <Form.Control type="password" placeholder={I18n.t('general.password')} onChange={this.setPassword} />
        {this.state.error && <Error error={this.state.error} inline />}
      </Form.Group>
      <Form.Group>
        <Button onClick={this.doLogin}>
          {I18n.t('views.modals.relogin.button-extend-session')}
        </Button>
        <Button className="ml-2" onClick={this.props.onHide}>
          {I18n.t('views.modals.relogin.button-cancel')}
        </Button>
      </Form.Group>
    </Modal.Body>
    </Modal>;
  }
}

export default ReLoginModal;
