import React, { Component } from 'react';
import { Form, Button, Badge, Spinner } from 'react-bootstrap';
import I18n from 'i18n-js';
import { List, Table, AlignLeft } from '../../../multiview/Icon/Icon.js';
import LocalStorageService from '../../../../AvainiaTools/LocalStorageService.js';
import IconifiedDocumentGrid from './IconifiedDocumentGrid.js';
import DocumentReportDate from './DocumentReportDate.js';
import DocumentReportType from './DocumentReportType.js';
import ModalDocumentAdd from '../../../multiview/Modals/ModalDocumentAdd.js';
import ModalFolderAdd from '../../../multiview/Modals/ModalFolderAdd.js';
import ModalFolderEdit from '../../../multiview/Modals/ModalFolderEdit.js';
import AvainiaTable from '../../../multiview/AvainiaTable/AvainiaTable.js';
import './DocumentTable.scss';
import APIService from '../../../../AvainiaTools/APIService.js';
import Error from '../../../multiview/Error/Error.js';
import Loading from '../../../multiview/Loading/Loading.js';
import DesktopFilters from './DesktopFilters.js';
import MobileFilters from './MobileFilters.js';

const Modals = {
  documentAdd: 'documentAdd',
  folderAdd: 'folderAdd',
  folderEdit: 'folderEdit',
};

class DocumentTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeFolder: null,
      flatMode: false,
      tableMode: 'table',
      modal: false,
      iconified: false,
      exportIds: [],
      exportLoading: false,
      reportsDropdown: false,
      settingsDropdown: false,
      tableConfig: LocalStorageService.getTableListConfig(),
      fields: [],
      loading: false,
      error: false,
    };
  }

  setTableMode = (mode) => {
    this.setState({ tableMode: mode, reportsDropdown: false });
  }

  componentDidMount = () => {
    APIService.fieldsGet().then((fields) => {
      if (fields.error) { return this.setState({ error: fields.error }); }
      this.setState({ fields });
    });
  }

  toggleIconified = () => { this.setState({ iconified: !this.state.iconified }); }

  tableConfigModalActivate = (e) => {
    this.setState({ modal: Modals.tableConfig });
  }

  hideModal = () => { this.setState({ modal: false }); }

  documentAdd = () => { this.setState({ modal: Modals.documentAdd }); }

  folderAdd = () => { this.setState({ modal: Modals.folderAdd }); }

  folderEdit = () => { this.setState({ modal: Modals.folderEdit }); }

  getDocument = (id) => {
    return this.props.documents.find((doc) => doc.id === id);
  }

  renderFolderThing = () => {
    if (!this.state.activeFolder) { return <></>; }

    const parents = [];
    let parent = this.props.folders.find((f) => f.id === Number(this.state.activeFolder.id));

    parents.push(parent);

    while (parent.parent_id) {
      // "It's safe" -Joel 2020
      // eslint-disable-next-line no-loop-func
      parent = this.props.folders.find((f) => f.id === Number(parent.parent_id));
      parents.push(parent);
    }

    const showDelete = !this.state.activeFolder.default
                       && !this.state.activeFolder.filecount
                       && !this.state.activeFolder.subfoldercount;

    const showEdit = !this.state.activeFolder.default;

    return <span>
        &nbsp;/&nbsp;
        <span
          onClick={() => { this.setState({ activeFolder: null }); }}
          className="clickable"
          style={{ textDecoration: 'underline' }}
        >
          {this.props.project.name}
        </span>
      {parents.reverse().map((folder) => <span key={folder.id}>
        &nbsp;/&nbsp;
        <span
          onClick={() => { this.setState({ activeFolder: folder }); }}
          className="clickable"
          style={{ textDecoration: 'underline' }}
        >
          {folder.name}
        </span>
      </span>)}
      &nbsp;

      {showDelete &&
        <Badge variant="warning" className="clickable" onClick={this.deleteFolder}>
          {I18n.t('general.delete')}
        </Badge>
      }
      {showEdit &&
        <Badge variant="primary" className="clickable" onClick={this.folderEdit}>
          {I18n.t('general.edit')}
        </Badge>
      }
    </span>;
  }

  deleteFolder = () => {
    if (!window.confirm(I18n.t('folders.confirm-delete'))) { return; }

    this.setState({ loading: true }, () => {
      APIService.projectFolderDelete(this.state.activeFolder).then((result) => {
        if (result.error) { return this.setState({ error: result.error, loading: false }); }

        this.setState({ loading: false, activeFolder: null });
      });
    });
  }

  getFolderDescendents = (folderId) => {
    const children = this.props.folders.filter((x) => x.parent_id === folderId);
    let descendents = children;

    children.forEach((x) => {
      descendents = descendents.concat(this.getFolderDescendents(x.id));
    });

    return descendents;
  }

  updateTableConfig = (key) => {
    const { tableConfig } = this.state;

    tableConfig[key] = !tableConfig[key];
    LocalStorageService.setTableListConfig(tableConfig);

    this.setState({ tableConfig });
  }

  getDeletedPhaseWithName = (name) => {
    return this.props.allPhases ? this.props.allPhases.find((x) => x.name === name && x.deactivated) : null;
  }

  toggleFlatmode = () => {
    this.setState((prevState) => ({
      flatMode: !prevState.flatMode,
      activeFolder: false,
    }));
  }

  exportzip = () => {
    if (this.state.exportLoading) { return; }

    const documentIds = this.state.exportIds;
    if (typeof documentIds === 'undefined' || documentIds.length <= 0) {
      return alert(I18n.t('views.infraproject.export-choose-one'));
    }

    this.setState({ exportLoading: true }, () => {
      const payload = {
        projectId: this.props.project.id,
        documentIds,
      };

      // TODO: Improve user feedback
      APIService.createFileExportJob(payload).then((response) => {
        if (response.error) {
          alert(I18n.t('views.infraproject.export-error'));
        } else {
          alert(I18n.t('views.infraproject.export-success'));
        }
        this.setState({ exportLoading: false });
      });
    });
  }

  render() {
    const config = LocalStorageService.getTableListConfig();
    const user = LocalStorageService.getUser();

    //! TODO! REFACTOR THIS PERMISSION CHECK TO ACTUALLY USE PERMISSIONS
    const userCanAddDocument = user.hasRole('Super') || !!user.companies.find((x) => x.type === 'owner' || x.type === 'subcontractor');
    const currentFolderId = this.state.activeFolder ? Number(this.state.activeFolder.id) : Number(null);
    const folders = this.props.folders ? this.props.folders.filter((folder) => Number(folder.parent_id) === currentFolderId) : null;

    // Because of folder <-> phase matching, some folders will not be shown (they match a deactivated phase and they don't contain files. We need to show all files, even for deactivated phase folders!)
    const foldersToList = folders ? folders.filter((f) => {
      return !(f.filecount === 0 && this.getDeletedPhaseWithName(f.name));
    }) : null;

    let documentsToList = this.props.visibleDocuments;

    if (!this.state.flatMode) {
      documentsToList = documentsToList.filter((document) => {
        const folderId = Number(document.folder_id);

        if (folderId === currentFolderId) { return true; }

        /*
        TODO! The bit below was disabled because the E customer requested it; it was too confusing.
        TODO! Because this is disabled, some users might not see all of their own files!!
        if (this.props.folders) {
          const targetFolder = this.props.folders.find((folder) => Number(folder.id) === folderId);

          if (!targetFolder) {
            // It would appear that this document is in a folder that the user can't access.
            // When the user sees a document but not the folder, it is highly likely that
            // access has been removed or it was uploaded directly into an inaccessible folder
            // using the mobile app. The user can see the file almost certainly because they
            // are the owner of the file. SO; list this file in the listing.
            // TODO: Detect this situation automatically and add a metadata marker to the document and to the document listing
            return true;
          }
        }
        */

        return false;
      });
    }

    if (this.state.loading) { return <Loading inline />; }

    return <>
    <div className="DocumentTable-topbar">
      {this.props.useFilters && <DesktopFilters thing={this.props.thing} toggleFlatmode={this.toggleFlatmode} flatMode={this.state.flatMode} />}
      {this.props.useFilters && <MobileFilters thing={this.props.thing} />}
      <div className="DocumentTable-controls">
        <div className="DocumentTable-controls-inner">
          {this.props.useIcons && <>
          <span className={`DocumentTable-controls-modetoggle ${this.state.tableMode === 'table' ? 'is-active' : ''}`} onClick={() => { this.setTableMode('table'); } }>
            <List />
          </span>
          <span className={`DocumentTable-controls-modetoggle ${this.state.tableMode === 'icons' ? 'is-active' : ''}`} onClick={() => { this.setTableMode('icons'); } }>
            <Table />
          </span>
          <span className={`reports DocumentTable-controls-modetoggle ${this.state.tableMode === 'report-date' || this.state.tableMode === 'report-type' ? 'is-active' : ''}`}>
            <span onClick={() => this.setState({ reportsDropdown: !this.state.reportsDropdown })}><AlignLeft /></span>
              {this.state.reportsDropdown && <span className="settings-dropdown">
                <span onClick={() => { this.setTableMode('report-date'); } }>
                  <input defaultChecked={this.state.tableMode === 'report-date'} id="reporttype-1" name="reporttype" type="radio" />
                  <label htmlFor="reporttype-1">
                    {I18n.t('views.infraproject.sort-by-date')}
                  </label>
                </span>
                <span onClick={() => { this.setTableMode('report-type'); } }>
                  <input defaultChecked={this.state.tableMode === 'report-type'} id="reporttype-2" name="reporttype" type="radio" />
                  <label htmlFor="reporttype-2">
                    {I18n.t('views.infraproject.sort-by-type')}
                  </label>
                </span>
              </span>}
            </span>
            </>}
          <span className="settings DocumentTable-controls-modetoggle dropdown-toggle">
            <span onClick={() => this.setState({ settingsDropdown: !this.state.settingsDropdown })}>{I18n.t('views.infraproject.settings')}</span>
              {this.state.settingsDropdown && <span className="settings-dropdown">
                <Form.Check type="checkbox" label={I18n.t('views.infraproject.show-info')} id="cfgDetailText" checked={this.state.tableConfig.viewDetailText} onChange={() => this.updateTableConfig('viewDetailText')} />
                <Form.Check type="checkbox" label={I18n.t('views.infraproject.show-position')} id="cfgPositionText" checked={this.state.tableConfig.viewPositionText} onChange={() => this.updateTableConfig('viewPositionText')} />
                <Form.Check type="checkbox" label={I18n.t('views.infraproject.show-company')} id="cfgAddedByCompany" checked={this.state.tableConfig.viewAddedByCompany} onChange={() => this.updateTableConfig('viewAddedByCompany')} />
              </span>}
          </span>
        </div>
      </div>
    </div>
    <div className="DocumentTable">
      {this.state.error && <Error inline error={this.state.error} />}
      {this.state.tableMode === 'table' && <>
        {!this.state.flatMode && this.props.folders.length > 0 && <>
          {this.renderFolderThing()}
          <AvainiaTable
            noDataIndication={I18n.t('general.no-folders')}
            wrapperClasses="table-folders-wrapper"
            condensed
            keyField="id"
            defaultSorted={[{ dataField: 'name', order: 'asc' }]}
            data={foldersToList}
            rowClickHandler={(e, rowData) => { this.setState({ activeFolder: rowData }); }}
            columns={[
              // { dataField: 'id', text: I18n.t('general.id'), headerStyle: { width: '60px' } },
              { dataField: 'name', text: I18n.t('folders.folder'), sort: true },
              { dataField: 'filecount', text: I18n.t('folders.filecount'), sort: true },
              { dataField: 'subfoldercount', text: I18n.t('folders.subfolders'), sort: true },
              {
                dataField: 'foo',
                text: I18n.t('folders.files-in-subfolders'),
                formatter: (cell, row) => {
                  // TODO: Precalculate (in the frontend, at APIService!)
                  const descendents = this.getFolderDescendents(row.id);
                  return descendents.reduce((accumulator, folder) => { return accumulator + folder.filecount; }, 0);
                },
                sort: true,
              },
            ]}
          />
        </>}
        <AvainiaTable
          condensed
          data={documentsToList}
          keyField="counter"
          selectRow={{
            hideSelectColumn: !this.props.configuration.documentExporting,
            mode: 'checkbox',
            clickToSelect: false,
            selectColumnStyle: { textAlign: 'center' },
            headerColumnStyle: { textAlign: 'center' },
            onSelect: (row, isSelect, rowIndex, e) => {
              if (isSelect) {
                this.setState({ exportIds: [...this.state.exportIds, row.id] });
              } else {
                this.setState({ exportIds: this.state.exportIds.filter((id) => id !== row.id) });
              }
            },
            onSelectAll: (isSelect, rows, e) => {
              if (isSelect) {
                this.setState({ exportIds: rows.map((row) => row.id) });
              } else {
                this.setState({ exportIds: [] });
              }
            },
          }}
          rowStyle={(row, rowIndex) => {
            const style = {};

            if (this.props.documentActive && this.props.documentActive.id === row.id) {
              style.backgroundColor = 'lightgreen';
            }

            return style;
          }}
          rowClickHandler={(e, rowData) => {
            this.props.documentActivate(this.getDocument(rowData.id));
          }}
          columns={[
            {
              dataField: 'counter',
              text: I18n.t('views.infraproject.nbr'),
              headerStyle: { width: '60px' },
              sort: true,
              sortFunc: (a, b, order, dataField, rowA, rowB) => {
                if (order === 'asc') {
                  return rowA.counterNumber - rowB.counterNumber;
                }

                return rowB.counterNumber - rowA.counterNumber;
              },
            },
            { dataField: 'files[0].extension', text: I18n.t('views.documents.type'), headerStyle: { width: '60px' }, sort: true },
            {
              dataField: 'folder_id',
              text: I18n.t('folders.folder'),
              formatter: (cell, row) => {
                // TODO: Precalulate in APIService
                if (!cell) { return ''; }

                let parent = this.props.folders.find((f) => f.id === Number(cell));
                if (!parent) { return '/'; }

                let text = `${parent.name}/`;

                while (parent.parent_id) {
                  // "It's safe" -Joel 2020
                  // eslint-disable-next-line no-loop-func
                  parent = this.props.folders.find((f) => f.id === Number(parent.parent_id));
                  text = `${parent.name}/${text}`;
                }

                return text;
              },
              hidden: !this.state.flatMode,
              sort: true,
            },
            {
              dataField: 'document_type.name',
              text: I18n.t('documentType.document-type'),
              formatter: (cell, row) => {
                return row.document_type.is_default ? I18n.t('documentType.default') : row.document_type.name;
              },
              sort: true,
            },
            { dataField: 'phase', text: I18n.t('views.infraproject.phase'), sort: true },
            { dataField: 'subject', text: I18n.t('views.infraproject.subject'), sort: true },
            { dataField: 'state', text: I18n.t('views.infraproject.state'), sort: true },
            { dataField: 'created', text: I18n.t('views.infraproject.created'), sort: true },
            { dataField: 'detailText', text: I18n.t('views.infraproject.info'), hidden: !config.viewDetailText, sort: true },
            { dataField: 'positionText', text: I18n.t('views.infraproject.position'), hidden: !config.viewPositionText, sort: true },
            { dataField: 'addedByCompany', text: I18n.t('views.infraproject.company'), hidden: !config.viewAddedByCompany, sort: true },
          ]}
        />
        <div className="DocumentTable-bottomPadding" />
      </>}


      {this.state.tableMode === 'icons' &&
        <IconifiedDocumentGrid
          phases={this.props.phases}
          subjects={this.props.subjects}
          visibleDocuments={this.props.visibleDocuments}
          documentActive={this.props.documentActive}
          documentActivate={this.props.documentActivate}
        />
      }

      {this.state.tableMode === 'report-date' &&
        <DocumentReportDate
          phases={this.props.phases}
          subjects={this.props.subjects}
          visibleDocuments={this.props.visibleDocuments}
          documentActive={this.props.documentActive}
          documentActivate={this.props.documentActivate}
        />
      }

      {this.state.tableMode === 'report-type' &&
        <DocumentReportType
          phases={this.props.phases}
          subjects={this.props.subjects}
          visibleDocuments={this.props.visibleDocuments}
          documentActive={this.props.documentActive}
          documentActivate={this.props.documentActivate}
        />
      }

      <div className="operations">
        {this.props.configuration.documentExporting &&
          <Button onClick={this.exportzip}>
            {this.state.exportLoading && <> <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />&nbsp; {I18n.t('general.loading')} </>}
            {!this.state.exportLoading && <> {I18n.t('views.infraproject.export-selected-documents')} </>}
          </Button>
        }
        {userCanAddDocument && <>
          <Button onClick={this.documentAdd}>{I18n.t('views.infraproject.button-add-document')}</Button>
          &nbsp;
          <Button onClick={this.folderAdd}>{I18n.t('views.infraproject.button-add-folder')}</Button>
        </>}
      </div>

      {this.state.modal === Modals.documentAdd &&
        <ModalDocumentAdd
          parentFolder={this.state.activeFolder}
          fields={this.props.fields}
          project={this.props.project}
          phases={this.props.phases}
          subjects={this.props.subjects}
          hideModal={this.hideModal}
        />
      }

      {this.state.modal === Modals.folderAdd &&
        <ModalFolderAdd
          parentFolder={this.state.activeFolder}
          project={this.props.project}
          hideModal={this.hideModal}
        />
      }

      {this.state.modal === Modals.folderEdit &&
        <ModalFolderEdit
          folder={this.state.activeFolder}
          hideModal={this.hideModal}
        />
      }
    </div>
  </>;
  }
}

export default DocumentTable;
