import React, { Component } from 'react';
import { Button, Modal } from 'react-bootstrap';
import I18n from 'i18n-js';

class PrivacyPolicyModal extends Component {
  render() {
    return <Modal show={this.props.show} onHide={this.props.onHide}>
      <Modal.Body>
        <iframe
          title={I18n.t('general.terms')}
          src={this.props.configuration.privacy} style={{ width: '90%', height: '450px', margin: '0px auto', paddingTop: '10px', display: 'block', border: 0 }}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={this.props.onHide}>{I18n.t('general.modal-close')}</Button>
      </Modal.Footer>
    </Modal>;
  }
}

export default PrivacyPolicyModal;
