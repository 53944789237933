import React, { Component } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import I18n from 'i18n-js';
import LocalStorageService from '../../../AvainiaTools/LocalStorageService.js';

class MobileMenu extends Component {
  logout = (e) => {
    LocalStorageService.clearUser();
    window.location.reload(); // TODO: Improve
  }

  render() {
    return <Navbar bg="light" expand="lg" id="mobilemenu">
      <Navbar.Brand>{I18n.t('general.appname')}</Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
        {this.props.AvainiaLinks}
        </Nav>
      </Navbar.Collapse>
    </Navbar>;
  }
}

export default MobileMenu;
