import React, { Component } from 'react';
import { Button, Row, Col, FormControl } from 'react-bootstrap';
import { Trash, Clock } from '../../multiview/Icon/Icon.js';
import I18n from 'i18n-js';
import Error from '../Error/Error.js';
import Loading from '../Loading/Loading.js';
import AvainiaTable from '../AvainiaTable/AvainiaTable.js';
import APIService from '../../../AvainiaTools/APIService.js';
import AvainiaPanel from './AvainiaPanel.js';

class MaterialformDeadlinesPanel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deadline_at: '2022-01-01 00:00:00',
      stairwell: '',
      apartment_number: '',
      deadlines: [],
      numberValid: true,
      loading: true,
      error: false,
    };
  }

  componentDidMount = () => {
    if (!this.props.project) { return this.setState({ error: 17 }); }

    if (this.props.deadlines) { return this.setState({ deadlines: this.props.deadlines, loading: false }); }

    APIService.materialSelectionDeadlinesGet(this.props.project.id).then((deadlines) => {
      if (deadlines.error) { return this.setState({ error: deadlines.error }); }

      this.setState({ deadlines, loading: false });
    });
  }

  onChange = (e) => {
    let { numberValid } = this.state;

    if (e.target.name === 'apartment_number') {
      if (e.target.value === '') {
        numberValid = true;
      } else {
        numberValid = this.isNormalInteger(e.target.value);
      }
    }

    this.setState({ [e.target.name]: e.target.value, numberValid });
  }

  isNormalInteger = (str) => {
    const n = Math.floor(Number(str));
    return n !== Infinity && String(n) === str && n >= 0;
  }

  createDeadline = (stairwell, apartment_number) => {
    if (this.state.loading) { return; }

    if (this.state.apartmentNumber && !this.isNormalInteger(this.state.apartmentNumber)) {
      return;
    }

    this.setState({ loading: true, numberWarning: false }, () => {
      const payload = {
        deadline_at: this.state.deadline_at,
        stairwell: this.state.stairwell,
        apartment_number: this.state.apartment_number,
      };

      APIService.materialSelectionDeadlinesCreate(this.props.project.id, payload).then((result) => {
        if (result.error) { return this.setState({ error: result.error }); }

        window.location.reload(); // TODO: improve
      });

      this.setState({ loading: false });
    });
  }

  formatDate(input) {
    return new Date(input).toLocaleDateString('fi-FI', {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric',
    }).split(' ').join('.');
  }

  delete = (id) => {
    if (this.state.loading) { return; }

    if (!window.confirm(I18n.t('views.materialforms.confirm-deadline-delete'))) { return; }

    this.setState({ loading: true, numberWarning: false }, () => {
      APIService.materialSelectionDeadlinesDelete(this.props.project.id, id).then((result) => {
        if (result.error) { return this.setState({ error: result.error }); }

        window.location.reload(); // TODO: improve
      });

      this.setState({ loading: false });
    });
  }

  getActionsFor = (cell, row) => {
    return <Trash onClick={() => { this.delete(row.id); }} className="clickable" />;
  }

  render() {
    return <AvainiaPanel heading={`${I18n.t('views.materialforms.deadlines-for')} ${this.props.project.code}`} icon={<Clock />} double={this.props.double}>
      {this.state.error && <Error inline error={this.state.error} /> }
      {!this.state.error && this.state.loading && <Loading inline /> }
      {!this.state.error && !this.state.loading && <>

        <AvainiaTable data={this.state.deadlines} keyField="id" rowClickIdRedirect="project" columns={[
          { dataField: 'id', text: I18n.t('general.id'), headerStyle: { width: '60px' } },
          { dataField: 'deadline_at', text: I18n.t('views.materialforms.deadline'), formatter: (cell, row) => this.formatDate(row.deadline_at) },
          { dataField: 'stairwell', text: I18n.t('views.condominiums.stairwell') },
          { dataField: 'apartment_number', text: I18n.t('views.condominiums.apartment-number') },
          { dataField: 'description', text: I18n.t('general.table-actions'), hidden: !this.props.readonly, formatter: this.getActionsFor },
        ]} />

      </>}

      {!this.props.readonly && <>
        <Row>
          <Col sm={4}>{I18n.t('views.projects.project')}</Col>
          <Col sm={8}><FormControl type="text" value={`${this.props.project.code} ${this.props.project.name || ''}`} readOnly={true} /></Col>
        </Row>

        <Row>
          <Col sm={4}>{I18n.t('views.materialforms.deadline')}</Col>
          <Col sm={8}><FormControl name="deadline_at" type="text" value={this.state.deadline_at} onChange={this.onChange} /></Col>
        </Row>

        <Row>
          <Col sm={4}>{I18n.t('views.condominiums.stairwell')}</Col>
          <Col sm={8}><FormControl name="stairwell" type="text" value={this.state.stairwell} onChange={this.onChange} /></Col>
        </Row>

        <Row>
          <Col sm={4}>{I18n.t('views.condominiums.apartment-number')}</Col>
          <Col sm={8}><FormControl isInvalid={!this.state.numberValid} name="apartment_number" type="text" value={this.state.apartment_number} onChange={this.onChange} /></Col>
        </Row>

        <Button onClick={this.createDeadline}>{I18n.t('views.materialforms.button-create')}</Button>
      </>}
    </AvainiaPanel>;
  }
}

export default MaterialformDeadlinesPanel;
