import React, { Component } from 'react';
import I18n from 'i18n-js';
import moment from 'moment';
import { ListGroup } from 'react-bootstrap';

class ShowFields extends Component {
  renderField = (fieldName) => {
    let field = this.props.fields.find((x) => x.key === fieldName);
    if (field) {
      const fieldId = field.id;

      field = this.props.doc.fields.find((x) => x.field_id === fieldId);

      if (field) {
        return field.date ? moment(field.date).format('YYYY.MM.DD HH:mm') : field.text;
      }

      field = this.props.doc.keywords.find((x) => x.field_id === fieldId);
      if (field) {
        return field.text;
      }

      return <span className="field-not-found" />;
    }

    return <span className="error-field-not-found" />;
  }

  render() {
    const { doc, fields } = this.props;

    if (!doc || !fields || !fields.length) {
      return false;
    }

    const hardcodedFields = {
      phase: true,
      subject: true,
      state: true,
      creation_date: true,
      info: true,
      position: true,
      gps_coordinates: true,
      gps_accuracy: true,
    };
    const fieldSets = [];

    doc.fields.forEach((field) => {
      const globalField = fields.find((f) => f.id === field.field_id);

      if (!globalField) {
        console.log('Global field not found!', field); // TODO: Real error!
        return;
      }

      if (!hardcodedFields[globalField.key]) {
        let fieldValue = '';

        if (globalField.type === 'text') {
          fieldValue = field.text;
        } else if (globalField.type === 'date') {
          fieldValue = field.date;
        }

        fieldSets.push({
          key: globalField.key,
          value: fieldValue,
        });
      }
    });

    doc.keywords.forEach((keyword) => {
      const globalField = fields.find((f) => f.id === keyword.field_id);

      if (!globalField) {
        console.log('Global field not found!!!', keyword); // TODO: Real error!
        return;
      }

      if (!hardcodedFields[globalField.key]) {
        fieldSets.push({ key: globalField.key, value: keyword.text });
      }
    });

    return <ListGroup variant="flush">
      <ListGroup.Item style={{ padding: '0' }}>{I18n.t('fields.phase')}: {this.renderField('phase')}</ListGroup.Item>
      <ListGroup.Item style={{ padding: '0' }}>{I18n.t('fields.subject')}: {this.renderField('subject')}</ListGroup.Item>
      <ListGroup.Item style={{ padding: '0' }}>{I18n.t('fields.state')}: {this.renderField('state')}</ListGroup.Item>
      <ListGroup.Item style={{ padding: '0' }}>{I18n.t('fields.creation_date')}: {this.renderField('creation_date')}</ListGroup.Item>
      <ListGroup.Item style={{ padding: '0' }}>{I18n.t('fields.info')}: {this.renderField('info')}</ListGroup.Item>
      <ListGroup.Item style={{ padding: '0' }}>{I18n.t('fields.position')}: {this.renderField('position')}</ListGroup.Item>
      <ListGroup.Item style={{ padding: '0' }}>{I18n.t('views.infraproject.addedByCompany')}: {doc.addedByCompany}</ListGroup.Item>
      <ListGroup.Item style={{ padding: '0' }}>{I18n.t('fields.gps_coordinates')}: {this.renderField('gps_coordinates')}</ListGroup.Item>
      <ListGroup.Item style={{ padding: '0' }}>{I18n.t('fields.gps_accuracy')}: {this.renderField('gps_accuracy')}</ListGroup.Item>

      {fieldSets.map((fieldSet) => {
        return <ListGroup.Item key={fieldSet.key} style={{ padding: '0' }}>
          {I18n.t(`fields.${fieldSet.key}`, { defaultValue: fieldSet.key })}:
          {fieldSet.key === 'creation_date' ? moment(fieldSet.value).format('DD.MM.YYYY, h:mm') : fieldSet.value }
        </ListGroup.Item>;
      })}
    </ListGroup>;
  }
}

export default ShowFields;
