import React, { Component } from 'react';
import I18n from 'i18n-js';
import Error from '../Error/Error.js';
import Loading from '../Loading/Loading.js';
import APIService from '../../../AvainiaTools/APIService.js';
import AvainiaPanel from './AvainiaPanel.js';
import AvainiaList from '../AvainiaList/AvainiaList.js';
import { City } from '../Icon/Icon.js';

class CondominiumPanel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      condominium: {},
      loading: true,
      error: false,
    };
  }

  componentDidMount = () => {
    if (!Number(this.props.condominium)) {
      return this.setState({ condominium: this.props.condominium, loading: false });
    }

    APIService.condominiumGet(this.props.condominium).then((condominium) => {
      if (condominium.error) { return this.setState({ error: condominium.error }); }

      this.setState({ condominium, loading: false });
    });
  }

  render() {
    return <AvainiaPanel heading={I18n.t('views.condominiums.condominium')} icon={<City />}>
      {this.state.error && <Error inline error={this.state.error} /> }
      {!this.state.error && this.state.loading && <Loading inline /> }
      {!this.state.error && !this.state.loading &&
        <AvainiaList
          title={this.state.condominium.name}
          link={(this.props.disableLink) ? null : `/condominium/${this.state.condominium.id}`}
        >
          <li>
            <div> {this.state.condominium.street_address} </div>
            <div> {this.state.condominium.postcode} </div>
            <div> {this.state.condominium.post_office} </div>
          </li>
        </AvainiaList>
      }
    </AvainiaPanel>;
  }
}

export default CondominiumPanel;
