import moment from 'moment';
import GPSService from './GPSService.js';
import APIService from './APIService.js';

class UtilService {
  static uuidv4 = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      // eslint-disable-next-line no-mixed-operators
      const r = Math.random() * 16 | 0;
      // eslint-disable-next-line no-mixed-operators
      const v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  static getFieldValue = (fields, fieldName, image, dateTrigger) => {
    const infoField = fields.find((x) => x.key === fieldName);
    if (!infoField) { return '-'; }

    const valueField = image.fields.find((x) => x.field_id === infoField.id);
    if (!valueField) { return ''; }

    return dateTrigger ? valueField.date : valueField.text;
  }

  /**
   * This acts as a converter that is used between the data coming from the API and the things show on screen.
   *
   * @param {*} document
   * @param {*} projectId
   * @param {*} fields
   * @param {*} index
   */
  static dbDocumentToImage(document, projectId, fields, index) {
    const item = document;

    if (item.files && item.files[0]) {
      // TODO! Hardcoding is evil!!
      item.url = `/api/v1/projects/${projectId}/documents/${item.id}/files/${item.files[0].id}`;
    } else {
      item.url = '';
    }

    // TODO! Hardcoding is evil!!
    item.thumb = `/api/v1/projects/${projectId}/documents/${item.id}/thumb`;
    item.counterNumber = index + 1;
    item.counter = item.counterNumber.toString(32);
    item.addedByCompany = (item.owner_company || { name: '' }).name;

    item.phase = '';
    item.subject = '';
    if (item.keywords) {
      // eslint-disable-next-line eqeqeq
      const phase = item.keywords.find((x) => x.field_id == 2);
      item.phase = phase ? phase.text : '-';

      // eslint-disable-next-line eqeqeq
      const subject = item.keywords.find((x) => x.field_id == 3);
      item.subject = subject ? subject.text : '-';

      // eslint-disable-next-line eqeqeq
      const state = item.keywords.find((x) => x.field_id == 4);
      item.state = state ? state.text : '-';
    }

    let created = this.getFieldValue(fields, 'creation_date', item, true);
    if (created) {
      created = moment(created);
      item.createdMoment = created;
      item.created = created.format('YYYY.MM.DD HH:mm');
      item.createdDateOnly = created.format('YYYY.MM.DD');
    } else {
      item.created = '';
      item.createdDateOnly = '';
    }

    item.positionText = this.getFieldValue(fields, 'position', item);
    item.detailText = this.getFieldValue(fields, 'info', item);

    const coords = this.getFieldValue(fields, 'gps_coordinates', item);
    if (coords) {
      item.coordinates = GPSService.fixCoords(coords);
      item.coordinateAccuracy = this.getFieldValue(fields, 'gps_accuracy', item);
    } else {
      item.coordinates = false;
    }

    return item;
  }

  /**
   * This converts Materialforms.
   *
   * FROM: materialform: {
   *   groups:[{}],
   *   selects:[{}]
   *   options:[{}],
   * } }
   *
   * TO: materialform: {
   *   groups: [{
   *     selects:[{
   *       options: [{}]
   *     }]
   *   }]
   * }
   */
  static convertMaterialform(source) {
    let error = false;
    source.selects.forEach((select) => {
      if (error) { return; }
      // eslint-disable-next-line eqeqeq
      const group = source.groups.find((g) => g.id == select.material_form_group_id);
      if (!group) { console.log('Group not found! ', select); /* TODO: Replace with actual logger */ error = true; return; }
      if (!Array.isArray(group.selects)) { group.selects = []; }
      select.isNew = false;
      group.selects.push(select);
    });

    if (error) { return { error: 11 }; }

    const promises = [];

    // Aaaand the options inside the selects.
    source.options.forEach((option) => {
      if (error) { return; }
      let select = false;
      source.groups.forEach((g) => {
        if (select) { return; }
        if (!g.selects || g.selects.length === 0) { return; }
        // eslint-disable-next-line eqeqeq
        select = g.selects.find((s) => s.id == option.material_form_select_id);
      });
      if (!select) { console.log('Select not found! ', option); /* TODO: Replace with actual logger */ error = true; return; }
      if (!Array.isArray(select.options)) { select.options = []; }
      option.isNew = false;
      option.isDefault = option.id === select.default_option_id;

      promises.push(APIService.productGet(option.product_id).then((result) => {
        option.product_obj = result;
        option.product_photo = option.product_obj.product_photo;
        option.product_context_photo = option.product_obj.product_context_photo;
        select.options.push(option);
      }).catch((error) => { console.error(`Error fetching product ${option.id}`, error); }));
    });

    return Promise.all(promises).then((data) => {
      if (error) { return { error: 12 }; }

      if (!source.groups) { source.groups = []; }

      source.groups.forEach((g) => {
        if (!g.selects) { g.selects = []; }
        g.selects.forEach((s) => {
          if (!s.options) { s.options = []; }
        });
      });

      return {
        id: source.id,
        project_id: source.project_id,
        name: source.name,
        groups: source.groups,
      };
    });
  }
}

export default UtilService;
