import Logger from 'js-logger';

// TODO: Implement https://stackoverflow.com/a/32766809/694325

export default class LocalStorageService {
  // TODO: Check
  static getProjectOpeningCounts = () => {
    try {
      return JSON.parse(localStorage.getItem('projectCounter'));
    } catch (e) {}
    return null;
  }

  // TODO: Check
  static incrementProjectOpeningCount = (code) => {
    const counters = LocalStorageService.getProjectOpeningCounts() || {};
    if (!counters[code]) { counters[code] = 0; }
    counters[code] += 1;
    localStorage.setItem('projectCounter', JSON.stringify(counters));
  }

  // TODO Should we get the latest permissions here? Makes data fresh, but also possible strain on system
  static getUser() {
    try {
      const user = JSON.parse(localStorage.getItem('userData'));

      if (!user) { return; }

      user.hasRole = (role) => {
        if (!user.roles) { return false; }
        return user.roles.find((x) => x.display_name === role);
      };

      user.hasPermission = (permission) => {
        if (user.hasRole('Super')) { return true; }
        if (!user.permissions) { return false; }
        return user.permissions.find((x) => x.name === permission);
      };

      user.isOwnerManager = () => {
        if (user.hasRole('Super')) { return true; }
        return user.hasRole('owner') && user.hasRole('manager');
      };

      return user;
    } catch (e) {
      Logger.error('Error retrieving user from localstorage', e);
    }
  }

  static setUser(userObject) {
    try {
      localStorage.setItem('userData', JSON.stringify(userObject));
    } catch (e) {
      Logger.error('Error writing user to localstorage');
    }
  }

  static getToken() {
    const user = LocalStorageService.getUser();
    if (!user) return null;
    return user.token;
  }

  static clearUser() {
    this.setUser({});
    localStorage.clear();
  }

  static setTableListConfig(config) {
    try {
      localStorage.setItem('tableListConfig', JSON.stringify(config));
    } catch (e) {
      Logger.error('Error writing  tableConfig to localstorage', e);
    }
  }

  static getTableListConfig() {
    try {
      return JSON.parse(localStorage.getItem('tableListConfig') || '{}');
    } catch (e) {
      Logger.error('Error getting tableConfig from localstorage', e);
    }
    return {};
  }

  static getLang() {
    try {
      return localStorage.getItem('al10n');
    } catch (e) {
      Logger.error('Error getting lang from localstorage', e);
    }
    return {};
  }

  static setLang(lang) {
    try {
      localStorage.setItem('al10n', lang);
    } catch (e) {
      Logger.error('Error writing lang to localstorage', e);
    }
  }

  static setProjectsTableConfig(config) {
    try {
      localStorage.setItem('ProjectsTableConfig', JSON.stringify(config));
    } catch (e) {
      Logger.error('Error writing ProjectsTableConfig to localstorage', e);
    }
  }

  static getProjectsTableConfig() {
    const config = localStorage.getItem('ProjectsTableConfig');
    if (!config) {
      return {
        id: false,
        code: true,
        name: true,
        status: false,
        companies: false,
        type: false,
        actions: true,
        showInactiveProjects: false,
      };
    }

    try {
      return JSON.parse(config);
    } catch (e) {
      Logger.error('Error getting ProjectsTableConfig from localstorage', e);
    }
    return {};
  }
}
