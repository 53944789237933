import APIService from './APIService.js';
import LocalStorageService from './LocalStorageService.js';

/**
 * This acts as a getter for all the globally shared data
 */
export default class GlobalsService {
  static getGlobals = async () => {
    const user = LocalStorageService.getUser();
    if (!user) { return {}; }

    const promises = [
      APIService.fieldsGet('Fields'),
      APIService.keywordsGet('Keywords'),
      APIService.userTypesGet('UserTypes'),
      APIService.companyTypesGet('CompanyTypes'),
    ];

    await Promise.all(promises).then(async (data) => {
      const Globals = {
        Fields: null,
        Keywords: null,
        UserTypes: null,
        CompanyTypes: null,
        loading: true,
      };

      await data.forEach(async (x) => {
        if (x.error) { Globals.error = x.error; return; }
        if (x.Fields) { Globals.Fields = x.Fields; }
        if (x.Keywords) { Globals.Keywords = x.Keywords; }
        if (x.UserTypes) { Globals.UserTypes = x.UserTypes; }
        if (x.CompanyTypes) { Globals.CompanyTypes = x.CompanyTypes; }
      });

      if (Globals.error) {
        window.Avainia.Globals = Globals;
        return console.log('Globals error!', Globals.error);
      }

      Globals.Fields = Globals.Fields.map((field) => {
        if (field.type !== 'keywords') { return field; }
        return {
          ...field,
          keywords: Globals.Keywords.filter((keyword) => keyword.field_id === field.id),
        };
      });

      window.Avainia.Globals = Globals;
    });

    return window.Avainia.Globals;
  };

  static invalidateGlobals = async () => {
    window.Avainia.globals = null;
  }
}
