import React, { Component } from 'react';
import I18n from 'i18n-js';
import { File } from '../../../../multiview/Icon/Icon.js';
import AvainiaPanel from '../../../../multiview/Panels/AvainiaPanel.js';
import ModalViewDocument from '../../../../multiview/Modals/ModalViewDocument/ModalViewDocument.js';
import LocalStorageService from '../../../../../AvainiaTools/LocalStorageService.js';
import APIService from '../../../../../AvainiaTools/APIService.js';
import DocumentTable from '../DocumentTable.js';

class CondominiumDocumentsPanel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      documentActive: null,
      folders: [],
      documents: [], // TODO
      visibleDocuments: [], // TODO
      phases: [], // TODO
      allPhases: [], // TODO
      subjects: [], // TODO
      documentTypes: [],
      loading: true,
      error: false,
    };
  }

  documentActivate = (documentActive) => {
    this.setState({
      documentActive,
    });
  }

  componentDidMount() {
    const { project } = this.props;
    const { id } = project;

    const promises = [
      APIService.getAllPhases(),
      APIService.getAllSubjects(),
      APIService.projectFoldersGet(id, 'folders'),
      APIService.getFields('fields'),
      APIService.documentTypesGet('documentTypes'),
    ];

    Promise.all(promises).then((data) => {
      let error = false;
      let phases;
      let allPhases;
      let subjects;
      let fields;
      let folders;
      let documentTypes;

      data.forEach((x) => {
        if (error) { return; }
        if (x.error) { error = x.error; return; }
        if (x.phases) { allPhases = x.phases; phases = x.phases.filter((p) => !p.deactivated); }
        if (x.subjects) { subjects = x.subjects; }
        if (x.fields) { fields = x.fields; }
        if (x.folders) { folders = x.folders; }
        if (x.documentTypes) { documentTypes = x.documentTypes; }
      });

      if (error) { return this.setState({ error }); }

      APIService.projectDocumentsGet(id, fields).then((result) => {
        if (result.error) { return this.setState({ error: result.error }); }
        const documents = result;
        const visibleDocuments = documents;

        LocalStorageService.incrementProjectOpeningCount(id);

        this.setState({ project, allPhases, documents, visibleDocuments, phases, folders, subjects, fields, documentTypes, loading: false });
      });
    });
  }

  resetFilters = () => {
    const newFilters = { subject: [], phase: [], state: [] };
    const view = { ...this.state.view };

    view.filter = '';
    view.filterFromDate = '';
    view.filterToDate = '';
    view.activeFilters = newFilters;

    const visibleImages = this.getVisibleImages(view);
    this.setState({ visibleImages, view });
    this.updateUrl(view);
  }

  render() {
    return <AvainiaPanel double={this.props.double} heading={I18n.t('views.documents.documents')} icon={<File />}>
      <DocumentTable
        project={this.state.project}
        phases={this.state.phases}
        allPhases={this.state.allPhases}
        subjects={this.state.subjects}
        documentActive={this.state.documentActive}
        documentActivate={this.documentActivate}
        documents={this.state.documents}
        folders={this.state.folders}
        visibleDocuments={this.state.visibleDocuments || this.state.documents}
        configuration={{}} // TODO
        thing={this}
        resetFilters={this.resetFilters}
      />

      {this.state.documentActive &&
        <ModalViewDocument
          parent="condominium"
          key={this.state.documentActive ? this.state.documentActive.id : '0'}
          documentActive={this.state.documentActive}
          documentEditedCallback={() => { document.location.reload(); }}
          documentDeletedCallback={() => { document.location.reload(); }}
          hideModal={this.documentActivate}
          fields={this.state.fields}
          phases={this.state.phases}
          project={this.props.project}
          subjects={this.state.subjects}
          show={true}
        />
      }
    </AvainiaPanel>;
  }
}

export default CondominiumDocumentsPanel;
