import React, { Component } from 'react';
import { Button, Modal } from 'react-bootstrap';
import I18n from 'i18n-js';
import Loading from '../Loading/Loading.js';
import Error from '../Error/Error.js';
import APIService from '../../../AvainiaTools/APIService.js';

class TermsModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      terms: {},
      loading: true,
      error: false,
    };
  }

  componentDidMount = () => {
    APIService.termsGet().then((result) => {
      const terms = this.termsFormat(result);
      if (!terms) { return this.setState({ error: 23 }); }
      this.setState({ terms: terms.fi, loading: false });
    });
  }

  termsFormat = (terms) => {
    if (!terms || !terms.translations || terms.translations.length < 1) { return false; }

    const result = {};

    terms.translations.forEach((translation) => {
      const row = {
        title: translation.title,
        content: translation.content,
      };

      result[translation.locale] = row;
    });

    return result;
  }

  cancel = () => {
    document.location.reload();
  }

  render() {
    return <Modal show={this.props.show} onHide={this.props.onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{this.state.terms.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>

      {this.state.error && <Error inline error={this.state.error} />}
      {!this.state.error && this.state.loading && <Loading inline />}
      {!this.state.error && !this.state.loading && <>
        <p>{this.state.terms.content}</p>
      </>}

      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={this.props.onAccept}>{I18n.t('general.accept')}</Button>
        <Button variant="secondary" onClick={this.cancel}>{I18n.t('general.cancel')}</Button>
      </Modal.Footer>
    </Modal>;
  }
}

export default TermsModal;
