import React, { Component } from 'react';
import I18n from 'i18n-js';
import { Button } from 'react-bootstrap';
import { Cog, Trash } from '../../multiview/Icon/Icon.js';
import Topbar from '../../multiview/Topbar/Topbar.js';
import Error from '../../multiview/Error/Error.js';
import Loading from '../../multiview/Loading/Loading.js';
import AvainiaTable from '../../multiview/AvainiaTable/AvainiaTable.js';
import './DocumentTypes.scss';
import APIService from '../../../AvainiaTools/APIService.js';
import DocumentTypeCreateModal from '../../multiview/Modals/DocumentTypeModals/DocumentTypeCreateModal.js';
import DocumentTypeDeleteModal from '../../multiview/Modals/DocumentTypeModals/DocumentTypeDeleteModal.js';
import DocumentTypeManageModal from '../../multiview/Modals/DocumentTypeModals/DocumentTypeManageModal.js';
import FieldsManageModal from '../../multiview/Modals/DocumentTypeModals/FieldsManageModal.js';

const Modals = {
  create: 1,
  delete: 2,
  manage: 3,
  manageFields: 4,
};

class DocumentTypes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      documentTypes: [],
      toBeDeletedId: false,
      selectedDocumentType: false,
      selectedDocumentTypeFieldIds: false,
      loading: true,
      error: false,
    };
  }

  componentDidMount() {
    APIService.documentTypesGet().then((documentTypes) => {
      if (documentTypes.error) { return this.setState({ error: documentTypes.error }); }

      this.setState({
        documentTypes,
        loading: false,
      });
    });
  }

  rowClick = (cell, row) => {
    this.manageDocumentType(row);
  }

  showModal = (modal) => { this.setState({ modal }); }

  hideModal = () => { this.setState({ modal: false }); }

  renderActions = (cell, row) => {
    return <div className="documenttype-actions">
      <Cog onClick={() => this.manageDocumentType(row)} className="clickable" />
      { !row.is_default && <Trash onClick={() => this.deleteDocumentType(row.id)} className="clickable" />}
    </div>;
  }

  manageDocumentType = (selectedDocumentType) => {
    const selectedDocumentTypeFieldIds = selectedDocumentType.fields.map((field) => field.id);
    this.setState({
      selectedDocumentType,
      selectedDocumentTypeFieldIds,
      modal: Modals.manage,
    });
  }

  deleteDocumentType = (toBeDeletedId) => {
    this.setState({
      toBeDeletedId,
      modal: Modals.delete,
    });
  }

  createCallback = (newDocumentType) => {
    this.setState({ documentTypes: [...this.state.documentTypes, newDocumentType] });
  }

  editCallback = (documentType) => {
    this.setState((prevState) => ({
      documentTypes: prevState.documentTypes.map((type) => {
        return type.id === documentType.id ? { ...type, name: documentType.name } : type;
      }),
    }));
  }

  deleteCallback = () => {
    this.setState((prevState) => ({
      documentTypes: prevState.documentTypes.filter((docType) => docType.id !== this.state.toBeDeletedId),
      toBeDeletedId: false,
    }));
  }

  render() {
    if (this.state.error) { return <Error error={this.state.error} />; }
    if (this.state.loading) { return <Loading />; }

    return <div className="App-main">
      <Topbar />
      <div className="App-container">
        <div className="documenttypes-top">
          <h1>{I18n.t('documentType.manage-document-types')}</h1>
          <div className="companies-buttons">
            <Button onClick={() => this.showModal(Modals.create)} className="btn btn-primary">{I18n.t('documentType.create-document-type')}</Button>
            <Button onClick={() => this.showModal(Modals.manageFields)} className="btn btn-primary">{I18n.t('documentType.manage-fields')}</Button>
          </div>
        </div>
        <AvainiaTable
          keyField='id'
          data={this.state.documentTypes}
          columns={[
            {
              dataField: 'name',
              text: I18n.t('documentType.name'),
              formatter: (cell, row) => {
                return row.is_default ? I18n.t('documentType.defaultDocumentType') : row.name;
              },
            },
            {
              dataField: 'actions',
              text: I18n.t('general.table-actions'),
              headerStyle: { width: '100px' },
              formatter: this.renderActions,
              // headerAlign: 'center',
            },
          ]}
        />
      </div>
      <DocumentTypeCreateModal
        show={this.state.modal === Modals.create}
        hideModal={this.hideModal}
        createCallback={this.createCallback}
      />
      <DocumentTypeDeleteModal
        show={this.state.modal === Modals.delete}
        hideModal={this.hideModal}
        documentTypeId={this.state.toBeDeletedId}
        deleteCallback={this.deleteCallback}
      />
      <DocumentTypeManageModal
        show={this.state.modal === Modals.manage}
        hideModal={this.hideModal}
        selectedDocumentType={this.state.selectedDocumentType}
        editCallback={this.editCallback}
        selectedDocumentTypeFieldIds={this.state.selectedDocumentTypeFieldIds}
      />
      <FieldsManageModal
        show={this.state.modal === Modals.manageFields}
        hideModal={this.hideModal}
      />
    </div>;
  }
}

export default DocumentTypes;
