import React, { Component } from 'react';
import I18n from 'i18n-js';
import { Form, Button } from 'react-bootstrap';
import Topbar from '../../multiview/Topbar/Topbar.js';
import Error from '../../multiview/Error/Error.js';
import Loading from '../../multiview/Loading/Loading.js';
import AvainiaTable from '../../multiview/AvainiaTable/AvainiaTable.js';
import CompanyCreateModal from '../../multiview/Modals/CompanyCreateModal.js';
import ModalCompanyEdit from '../../multiview/Modals/ModalCompanyEdit.js';
import APIService from '../../../AvainiaTools/APIService.js';

const Modals = {
  editCompany: 'edit-company',
  createCompany: 'create-company',
};

class Companies extends Component {
  constructor(props) {
    super(props);

    this.state = {
      companies: [],
      companiesFiltered: [],
      companyToEdit: false,
      search: '',
      modal: false,
      loading: true,
      error: false,
    };
  }

  componentDidMount() {
    APIService.companiesGet().then((companies) => {
      if (companies.error) { return this.setState({ error: companies.error }); }

      this.setState({
        companies,
        companiesFiltered: companies,
        loading: false,
      });
    });
  }

  search = (e) => {
    const { companies } = this.state;
    const term = e.target.value;
    const searchableFields = ['name', 'code', 'type'];

    const companiesFiltered = companies
      .filter((obj) => {
        return Object.keys(obj).filter((key) => searchableFields.includes(key))
          .some((key) => {
            return obj[key].toLowerCase().includes(term.toLowerCase());
          });
      });

    this.setState({ companiesFiltered });
  }

  hideModal = (e) => {
    this.setState({ modal: 0, companyToEdit: null });
  }

  addCompanyCallback = (company) => {
    const { companies } = this.state;
    companies.push(company);
    this.setState({ companies });
  }

  editCompanyCallback = (company) => {
    const companies = this.state.companies.filter((x) => x.id !== this.state.companyToEdit.id);

    companies.push(company);
    companies.sort((a, b) => { return a.id > b.id ? 1 : -1; });

    this.setState({ companies, companiesFiltered: companies });
  }

  removeCompanyCallback = (id) => {
    const companies = this.state.companies.filter((x) => x.id !== id);
    this.setState({ companies, companiesFiltered: companies });
  }

  handleCompanyRowClick = (e, rowData) => {
    const companyToEdit = this.state.companies.find((x) => x.id === rowData.id);
    this.setState({ companyToEdit, modal: Modals.editCompany });
  }

  createCompany = (e) => {
    this.setState({ modal: Modals.createCompany });
  }

  render() {
    if (this.state.error) { return <Error error={this.state.error} />; }
    if (this.state.loading) { return <Loading />; }

    return <div className="App-main">
      <Topbar />

      <div className="App-container">
        <h1>{I18n.t('views.companies.companies')}</h1>

        <Button onClick={this.createCompany} variant="primary">
          {I18n.t('views.companies.add-company')}
        </Button>

        <Form.Control
          type="text"
          onChange={this.search}
          placeholder={I18n.t('views.companies.search')}
        />

        <AvainiaTable
          keyField='id'
          data={this.state.companiesFiltered}
          rowClickHandler={this.handleCompanyRowClick}
          defaultSorted={[{ dataField: 'name', order: 'asc' }]}
          columns={[
            { dataField: 'id', text: I18n.t('general.id') },
            { dataField: 'name', text: I18n.t('views.companies.name'), sort: true },
            { dataField: 'code', text: I18n.t('views.companies.code') },
            {
              dataField: 'type',
              text: I18n.t('views.companies.type'),
              formatter: (cell, row) => I18n.t(`constants.companyTypes.${cell}`),
            },
          ]}
        />
      </div>

      {this.state.modal === Modals.createCompany &&
        <CompanyCreateModal addCompanyCallback={this.addCompanyCallback} onHide={this.hideModal} />
      }

      {this.state.modal === Modals.editCompany &&
        <ModalCompanyEdit
          editCompanyCallback={this.editCompanyCallback}
          removeCompanyCallback={this.removeCompanyCallback}
          companyToEdit={this.state.companyToEdit}
          onHide={this.hideModal}
        />
      }

    </div>;
  }
}

export default Companies;
