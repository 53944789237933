import React, { PureComponent } from 'react';
import LocalStorageService from '../../../AvainiaTools/LocalStorageService.js';

class SecureImage extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      imageSrc: null,
      fetchCounter: 0,
    };
  }

  timeoutId = null;

  componentDidMount() {
    this.fetchImage();
  }

  componentDidUpdate(prevProps) {
    if (this.props.src !== prevProps.src) {
      if (this.timeoutId) clearTimeout(this.timeoutId);

      this.timeoutId = null;
      this.setState(() => ({})); // reset state
      this.fetchImage();
    }
  }

  componentWillUnmount() {
    if (this.timeoutId) clearTimeout(this.timeoutId);
  }

  fetchImage = async () => {
    try {
      const res = await fetch(process.env.REACT_APP_API_HOST + this.props.src, {
        headers: {
          Authorization: `Bearer ${LocalStorageService.getToken()}`,
        },
      });

      if (res.ok) {
        const binary = await res.blob();
        const imageSrc = URL.createObjectURL(binary);
        this.setState({ imageSrc });
      } else if (res.status !== 410) { // 410 means dont retry
        throw new Error('failed to fetch image');
      }
    } catch (ex) {
      this.setState((state) => ({
        ...state,
        fetchCounter: state.fetchCounter + 1,
      }));

      this.timeoutId = setTimeout(
        this.fetchImage,
        Math.pow(10 + this.state.fetchCounter, 2),
      );
    }
  }

  render() {
    const { imageSrc } = this.state;
    const { src, useImgTag, alt, ...domProps } = this.props;

    if (!imageSrc) {
      return null; // placeholder?
    }

    if (useImgTag) {
      return <img src={imageSrc} alt={alt} {...domProps} />;
    }

    return <div {...domProps} style={{ backgroundImage: `url(${imageSrc})` }} />;
  }
}

export default SecureImage;
