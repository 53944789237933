import React, { Component } from 'react';
import I18n from 'i18n-js';
import { Form, Button, InputGroup, FormControl } from 'react-bootstrap';
import AvainiaPanel from './AvainiaPanel.js';
import { Check, Cog, Close, Edit, Trash } from '../Icon/Icon.js';
import Error from '../Error/Error.js';
import Loading from '../Loading/Loading.js';
import APIService from '../../../AvainiaTools/APIService.js';

export default class PanelDefaultfolders extends Component {
  constructor(props) {
    super(props);

    this.state = {
      companies: [],
      defaultFolders: [],
      contentPermissions: [],
      activeDefaultfolder: false,
      selectedCompany: null,
      name: '',
      editName: '',
      loading: true,
      error: false,
    };
  }

  componentDidMount = () => {
    APIService.defaultFoldersGet().then((defaultFolders) => {
      if (defaultFolders.error) { return this.setState({ error: defaultFolders.error }); }

      // TODO! Hardcoding the contentPermission type, should it be done?
      APIService.contentPermissionsGet('App\\Defaultfolder').then((contentPermissions) => {
        if (contentPermissions.error) { return this.setState({ error: contentPermissions.error }); }

        APIService.companiesGet().then((companies) => {
          if (companies.error) { return this.setState({ error: companies.error }); }

          this.setState({
            contentPermissions,
            defaultFolders,
            companies,
            loading: false,
          });
        });
      });
    });
  }

  // ? note: this is copied from App\\Folder -- dedupe?
  contentPermissionCreate = (role, folder) => {
    // TODO! Hardcoding the contentPermission type, should it be done?
    APIService.contentPermissionAdd(role, 'App\\Defaultfolder', folder.id).then((result) => {
      if (result.error) { return this.setState({ error: result.error }); }

      window.location.reload(); // TODO: Improve
    });
  }

  // ? note: this is copied from App\\Folder -- dedupe?
  contentPermissionRemove = (contentPermission) => {
    APIService.contentPermissionRemove(contentPermission).then((result) => {
      if (result.error) { return this.setState({ error: result.error }); }

      window.location.reload(); // TODO: Improve
    });
  }

  activateDefaultfolder = (df) => {
    this.setState((prevState) => ({
      editName: df.name,
      activeDefaultfolder: prevState.activeDefaultfolder.id === df.id ? false : df,
    }));
  }

  onChange = (e) => { this.setState({ [e.target.name]: e.target.value }); }

  create = () => {
    if (this.state.loading) { return; }

    this.setState({ loading: true }, () => {
      const payload = {
        name: this.state.name,
      };

      APIService.defaultFoldersCreate(payload).then((result) => {
        if (result.error) { return this.setState({ error: result.error, loading: false }); }

        window.location.reload(); // TODO: Improve
      });
    });
  }

  save = () => {
    if (this.state.loading) { return; }

    this.setState({ loading: true }, () => {
      const payload = {
        id: this.state.activeDefaultfolder.id,
        name: this.state.editName,
      };

      APIService.defaultFoldersEdit(payload).then((result) => {
        if (result.error) { return this.setState({ error: result.error, loading: false }); }

        window.location.reload(); // TODO: Improve
      });
    });
  }

  deleteDefaultfolder = (df) => {
    if (this.state.loading) { return; }

    if (!window.confirm(I18n.t('defaultfolders.confirm-delete'))) { return; }

    this.setState({ loading: true }, () => {
      APIService.defaultFoldersDelete(df.id).then((result) => {
        if (result.error) { return this.setState({ error: result.error, loading: false }); }

        window.location.reload(); // TODO: Improve
      });
    });
  }

  renderDefaultFolderCompanies = (df) => {
    const relevantPermissions = this.state.contentPermissions.filter((cp) => {
      return cp.content_id === df.id;
    });

    const companies = this.state.companies.map((company) => {
      const attachedRoles = company.roles.filter((role) => {
        return relevantPermissions.find((rp) => rp.role_id === role.id);
      });
      return { ...company, attachedRoles };
    }).sort((a, b) => { return a.name > b.name ? 1 : -1; });

    return <div className="permissions-for-company">
      <table className="informational-grid">
        <thead>
          <tr>
            <td>-</td>
            <td>{I18n.t('constants.userTypes.employee')}</td>
            <td>{I18n.t('constants.userTypes.editor')}</td>
            <td>{I18n.t('constants.userTypes.manager')}</td>
          </tr>
        </thead>
        <tbody>
          {companies.map((company) => {
            const roleEmployee = company.roles.find((x) => x.display_name === 'employee');
            const roleEditor = company.roles.find((x) => x.display_name === 'editor');
            const roleManager = company.roles.find((x) => x.display_name === 'manager');

            const roleEmployeeAssigned = relevantPermissions.find((rp) => rp.role_id === roleEmployee.id);
            const roleEditorAssigned = relevantPermissions.find((rp) => rp.role_id === roleEditor.id);
            const roleManagerAssigned = relevantPermissions.find((rp) => rp.role_id === roleManager.id);

            return <tr key={company.id}>
              <td>{company.name}</td>
              <td>
                {roleEmployeeAssigned && <Check onClick={() => this.contentPermissionRemove(roleEmployeeAssigned)} title={I18n.t('folders.click-to-revoke-permission')} />}
                {!roleEmployeeAssigned && <Close onClick={() => this.contentPermissionCreate(roleEmployee, df)} title={I18n.t('folders.click-to-grant-permission')} />}
              </td>
              <td>
                {roleEditorAssigned && <Check onClick={() => this.contentPermissionRemove(roleEditorAssigned)} title={I18n.t('folders.click-to-revoke-permission')} />}
                {!roleEditorAssigned && <Close onClick={() => this.contentPermissionCreate(roleEditor, df)} title={I18n.t('folders.click-to-grant-permission')} />}
              </td>
              <td>
                {roleManagerAssigned && <Check onClick={() => this.contentPermissionRemove(roleManagerAssigned)} title={I18n.t('folders.click-to-revoke-permission')} />}
                {!roleManagerAssigned && <Close onClick={() => this.contentPermissionCreate(roleManager, df)} title={I18n.t('folders.click-to-grant-permission')} />}
              </td>
            </tr>;
          })}
        </tbody>
      </table>
    </div>;
  }

  render() {
    return <AvainiaPanel heading={I18n.t('folders.defaultfolders')} icon={<Cog />}>
      {this.state.error && <Error inline error={this.state.error} /> }
      {!this.state.error && this.state.loading && <Loading inline /> }
      {!this.state.error && !this.state.loading && <>
        <p>{I18n.t('defaultfolders.explanation')}</p>

        {this.state.defaultFolders.sort((a, b) => { return a.name > b.name ? 1 : -1; }).map((df) => <div key={df.id}>
          <div style={{ fontWeight: 'bold' }} data-id={df.id}>
            {this.state.activeDefaultfolder.id === df.id && <>
              <Form.Label>{I18n.t('defaultfolders.name')}</Form.Label>
              <InputGroup className="mb-3">
                <FormControl
                  type="text"
                  onChange={this.onChange}
                  name="editName"
                  value={this.state.editName}
                />
                <InputGroup.Append>
                  <Button variant="primary" onClick={this.save}>{I18n.t('general.save')}</Button>
                </InputGroup.Append>
              </InputGroup>
            </>}
            {this.state.activeDefaultfolder.id !== df.id && <span>
              {df.name}
              &nbsp;
              <Edit
                className="clickable"
                onClick={(e) => this.activateDefaultfolder(df) }
                style={{ color: this.state.activeDefaultfolder.id === df.id ? 'green' : 'black' }}
              />
              &nbsp;
              {false && <Trash
                data-todo="DISABLED FOR NOW! NEEDS TESTING!!"
                className="clickable"
                onClick={(e) => this.deleteDefaultfolder(df) }
                style={{ color: 'red' }}
              />}
            </span>}
          </div>
          {this.state.activeDefaultfolder.id === df.id && this.renderDefaultFolderCompanies(df)}
        </div>)}

        <hr />
        <Button onClick={(e) => { document.getElementById('foo').style.display = 'block'; e.target.style.display = 'none'; }}>
            {I18n.t('defaultfolders.button-create-new')}
          </Button>
        <div id="foo" style={{ display: 'none' }}>
          <Form.Group>
            <Form.Label>{I18n.t('defaultfolders.name')}</Form.Label>
            <Form.Control type="text" onChange={this.onChange} name="name" value={this.state.name} />
          </Form.Group>
          <Button variant="primary" onClick={this.create}>
            {I18n.t('general.save')}
          </Button>
        </div>
      </>}
    </AvainiaPanel>;
  }
}
