import React, { Component } from 'react';
import I18n from 'i18n-js';
import Topbar from '../../multiview/Topbar/Topbar.js';

class PrivacyPolicy extends Component {
  render() {
    return <div className="App-main">
      <Topbar />
      <div className="App-container">
        <iframe
          title={I18n.t('general.terms')}
          src={this.props.configuration.privacy}
          style={{ width: '90%', height: '90vh', margin: '0px auto', paddingTop: '10px', display: 'block', border: 0 }}
        />
      </div>
    </div>;
  }
}

export default PrivacyPolicy;
