import React, { Component } from 'react';
import './DocumentReportType.scss';
import I18n from 'i18n-js';
import moment from 'moment';
import IconifiedDocumentGrid from './IconifiedDocumentGrid.js';

class DocumentReportType extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openDocuments: {},
      openImageDocuments: false,
      error: false,
      loading: true,
    };
  }

  sortDocuments = (documents) => {
    const documentsSorted = {};

    documents.forEach((doc) => {
      const type = doc.document_type.name;
      if (!documentsSorted[type]) {
        documentsSorted[type] = [];
      }
      documentsSorted[type].push(doc);
    });

    return documentsSorted;
  }

  toggleCollapse = (key) => {
    const doc = !this.state.openDocuments[key];
    this.setState((prevState) => ({
      openDocuments: {
        ...prevState.openDocuments,
        [key]: doc,
      },
    }));
  }

  setOpenImageDocuments = (openImageDocuments) => {
    this.setState({ openImageDocuments });
  }

  render() {
    const documentsSorted = this.sortDocuments(this.props.visibleDocuments);

    return <div className='DocumentReportType'>
      {!this.state.openImageDocuments && <>
        <table className='table table-bordered AvainiaTable clickable'>
          <thead>
            <tr>
              <th>{I18n.t('views.infraproject.reports.type-and-file')}</th>
              <th>{I18n.t('views.infraproject.reports.phase')}</th>
              <th>{I18n.t('views.infraproject.reports.format')}</th>
              <th>{I18n.t('views.infraproject.reports.created')}</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(documentsSorted).map((typeGroup) => {
              const documentsForType = documentsSorted[typeGroup];
              const key = typeGroup;
              const displayText = typeGroup === "document_type_default" ? I18n.t('documentType.defaultDocumentType') : typeGroup;

              return (
                <React.Fragment key={key}>
                  <tr>
                    <td colSpan='2' onClick={() => { this.toggleCollapse(key); }}>
                      {this.state.openDocuments[key] ? '▾' : '▴'}
                      <strong>{displayText}</strong>
                      ({documentsForType.length})
                    </td>
                    <td colSpan='2'>
                      <button className='btn btn-primary' onClick={() => { this.setOpenImageDocuments(documentsForType); }}>{I18n.t('views.infraproject.reports.show-images')}</button>
                    </td>
                  </tr>
                  {Object.keys(documentsForType).map((document) => {
                    const doc = documentsForType[document];
                    const key = typeGroup + document;
                    const display = this.state.openDocuments && this.state.openDocuments[typeGroup] ? '' : 'hidden';
                    const active = (this.props.documentActive && doc.id === this.props.documentActive.id) ? 'selected' : '';
                    return (
                      <tr className={`${display} document ${active}`} key={key} onClick={() => { this.props.documentActivate(doc); }}>
                        <td>{doc.id}</td>
                        <td>{doc.phase}</td>
                        <td>{doc.files[0] && doc.files[0].extension}</td>
                        <td>{moment(doc.created, 'YYYY.MM.DD HH:ii').format('DD.MM.YYYY (HH:mm)')}</td>
                      </tr>
                    );
                  })}
                </React.Fragment>
              );
            })}
          </tbody>
        </table>
        <div className="DocumentTable-bottomPadding" />
      </>}
      {this.state.openImageDocuments && <div className='DocumentReportDateImages'>
        <button className="btn btn-primary" onClick={() => { this.setState({ openImageDocuments: false }); }}>{I18n.t('views.infraproject.reports.back')}</button>
        <IconifiedDocumentGrid
          phases={this.props.phases}
          subjects={this.props.subjects}
          visibleDocuments={this.state.openImageDocuments}
          documentActive={this.props.documentActive}
          documentActivate={this.props.documentActivate}
        />
      </div>}
    </div>;
  }
}

export default DocumentReportType;
