import React from 'react';
import I18n from 'i18n-js';
import './Loading.scss';

export default (props) => {
  if (props.hide) {
    return <></>;
  }

  if (props.inline) {
    return <div className="Loading Loading--inline">
      <p className="Loading-text">{I18n.t('general.loading')}...</p>
      <div className="Loading-icon">
        <div className="Loading-inner"></div>
        <div className="Loading-inner"></div>
      </div>
    </div>;
  }

  return <div className="Loading">
    <p className="Loading-text">{I18n.t('general.loading')}...</p>
    <div className="Loading-icon">
      <div className="Loading-inner"></div>
      <div className="Loading-inner"></div>
    </div>
  </div>;
};
