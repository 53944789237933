import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { selectStyles } from './Styles.js';

const CustomSelect = ({
  handleChange,
  name,
  placeholder,
  selectOptions,
  value,
  defaultValue,
}) => (
  <div className="select-container">
    <Select className="avainia-select" classNamePrefix="avainia-select"
      name={name}
      placeholder={placeholder}
      isClearable={false}
      backspaceRemovesValue={false}
      isSearchable={true}
      value={value}
      defaultValue={defaultValue}
      onChange={(newValue) => handleChange(name, newValue)}
      options={selectOptions}
      styles={selectStyles}
    />
  </div>
);

CustomSelect.propTypes = {
  handleChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  selectOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]).isRequired,
    }),
  ),
  value: PropTypes.objectOf({
    value: PropTypes.string,
    label: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
  }),
  defaultValue: PropTypes.object,
};

export default CustomSelect;
