import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import I18n from 'i18n-js';
import UpcertCondominiumModal from '../../multiview/Modals/UpcertCondominiumModal.js';
import LocalStorageService from '../../../AvainiaTools/LocalStorageService.js';
import AvainiaPermissions from '../../../AvainiaTools/AvainiaPermissions.js';
import Topbar from '../../multiview/Topbar/Topbar.js';
import Error from '../../multiview/Error/Error.js';
import Loading from '../../multiview/Loading/Loading.js';
import AvainiaTable from '../../multiview/AvainiaTable/AvainiaTable.js';
import APIService from '../../../AvainiaTools/APIService.js';

const Modals = {
  create: 1,
};

class Condominiums extends Component {
  constructor(props) {
    super(props);

    this.state = {
      condominiums: [],
      modal: false,
      loading: true,
      error: false,
    };
  }

  componentDidMount() {
    APIService.condominiumsGet().then((condominiums) => {
      if (condominiums.error) { return this.setState({ error: condominiums.error }); }

      this.setState({ condominiums, loading: false });
    });
  }

  createNew = () => { this.setState({ modal: Modals.create }); }

  hideModal = (e) => { this.setState({ modal: false }); }

  successCallback = (condominium) => {
    const { condominiums } = this.state;
    condominiums.push(condominium);
    this.setState({ condominiums }, this.hideModal);
  }

  render() {
    if (this.state.error) { return <Error error={this.state.error} />; }
    if (this.state.loading) { return <Loading />; }

    const user = LocalStorageService.getUser();

    return <div className="App-main">
      <Topbar />

      <div className="App-container">
        <h1 className="App-pageTitle">{I18n.t('views.condominiums.condominiums')}</h1>

        <AvainiaTable data={this.state.condominiums} keyField="id" rowClickIdRedirect="condominium" columns={[
          { dataField: 'id', text: I18n.t('general.id'), headerStyle: { width: '60px' } },
          { dataField: 'name', text: I18n.t('views.condominiums.name') },
          { dataField: 'street_address', text: I18n.t('views.condominiums.street_address') },
          { dataField: 'postcode', text: I18n.t('views.condominiums.postcode') },
          { dataField: 'post_office', text: I18n.t('views.condominiums.post_office') },
        ]} />

        {user.hasPermission(AvainiaPermissions.CondominiumsManage) &&
          <Button onClick={this.createNew}>{I18n.t('views.condominiums.button-create-new')}</Button>
        }
      </div>

      <UpcertCondominiumModal show={!!this.state.modal} onHide={this.hideModal} successCallback={this.successCallback} />
    </div>;
  }
}

export default Condominiums;
