import React, { Component } from 'react';
import { Edit, Trash } from '../../../multiview/Icon/Icon.js';
import { Form, Button, ListGroup } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import I18n from 'i18n-js';
import moment from 'moment';
import Error from '../../../multiview/Error/Error.js';
import Loading from '../../../multiview/Loading/Loading.js';
import APIService from '../../../../AvainiaTools/APIService.js';
import ScheduleService from '../../../../AvainiaTools/ScheduleService.js';
import EditScheduleModal from './EditScheduleModal.js';
import SchedulerGraph from '../../../multiview/Graph/SchedulerGraph.js';

const Modals = {
  scheduleEdit: 1,
};

class SchedulesTab extends Component {
  constructor(props) {
    super(props);

    this.state = {
      baseKey: 0,
      description: '',
      start_at: '',
      end_at: '',
      parent_id: 0,
      schedules: [],
      schedulesSorted: [],
      scheduleToEdit: false,
      modal: false,
      graphProps: false,
      error: false,
      loading: true,
    };
  }

  componentDidMount() {
    ScheduleService.getSchedules(this.props.project.id).then((data) => {
      const schedulesSorted = data.sorted;
      const schedules = data.unsorted;
      this.setState({ schedulesSorted, schedules, loading: false });
    });
  }

  createSchedule = (e) => {
    if (this.state.loading) { return; }

    this.setState({ loading: true }, () => {
      const start = this.formatDateDB(this.state.start_at);
      const end = this.formatDateDB(this.state.end_at);

      const payload = {
        description: this.state.description,
        start_at: start,
        end_at: end,
      };

      if (this.state.parent_id) {
        payload.parent_id = this.state.parent_id;
      }

      APIService.projectSchedulesCreate(this.props.project.id, payload).then((schedule) => {
        if (schedule.error) { this.setState({ error: schedule.error }); }

        this.refreshView();
      });
    });
  }

  delete = (e) => {
    if (this.state.loading) { return; }

    this.setState({ loading: true }, () => {
      APIService.projectSchedulesDelete(this.props.project.id, e.id).then((schedule) => {
        if (schedule.error) { this.setState({ error: schedule.error }); }

        this.refreshView();
      });
    });
  }

  formatDateDB = (date) => {
    const d = new Date(date);
    const formatted = `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`;

    return formatted;
  }

  formChange = (event) => {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;

    this.setState({ [name]: value });
  }

  onChangeDatepickerStart = (date) => { this.setState({ start_at: date }); }

  onChangeDatepickerEnd = (date) => { this.setState({ end_at: date }); }

  edit = (schedule) => {
    this.setState({ scheduleToEdit: schedule, modal: Modals.scheduleEdit });
  }

  hideModal = () => {
    this.setState({ modal: false });
  }

  refreshView = () => {
    this.setState({ loading: true }, () => {
      ScheduleService.getSchedules(this.props.project.id).then((data) => {
        const schedulesSorted = data.sorted;
        const schedules = data.unsorted;
        this.setState({ schedulesSorted, schedules, loading: false, baseKey: this.state.baseKey + 1 });
      });
    });
  }

  renderScheduleChild(schedule) {
    if (schedule.children && schedule.children.length > 0) {
      return<ListGroup className="listing">
        {schedule.children.map((child) => <ListGroup.Item key={child.id}>
        {child.id} {child.description} {moment(child.start_at).format('DD.MM.YYYY')} - {moment(child.end_at).format('DD.MM.YYYY')}
          <Trash onClick={() => { this.delete(child); }} />
          <Edit onClick={() => { this.edit(child); }} />
          {this.renderScheduleChild(child)}
        </ListGroup.Item>)}
      </ListGroup>;
    }
  }

  render() {
    return <div className="App-container schedule">
      <h1>{I18n.t('views.schedules.schedules')}</h1>

      {this.state.error && <Error inline error={this.state.error} />}
      {!this.state.error && this.state.loading && <Loading inline />}
      {!this.state.error && !this.state.loading && <>
        <ListGroup className="listing">
          {this.state.schedulesSorted.map((schedule) => <ListGroup.Item key={schedule.id}>
            {schedule.id} {schedule.description} {moment(schedule.start_at).format('DD.MM.YYYY')} - {moment(schedule.end_at).format('DD.MM.YYYY')}
            <Trash onClick={() => { this.delete(schedule); }} />
            <Edit onClick={() => { this.edit(schedule); }} />
            {this.renderScheduleChild(schedule)}
          </ListGroup.Item>)}
        </ListGroup>


        <h3>{I18n.t('views.schedules.create-schedule')}</h3>

        <Form.Group controlId="create-schedule">

          <Form.Label>{I18n.t('views.schedules.create-schedule-description')}</Form.Label>
          <Form.Control type="text" onChange={this.formChange} name="description" value={this.state.description} />

          <Form.Label>{I18n.t('views.schedules.create-schedule-start_at')}</Form.Label>
          <DatePicker
            name="start_at"
            selected={this.state.start_at}
            onChange={this.onChangeDatepickerStart}
            className="datepicker-input"
            dateFormat="dd.MM.yyyy"
          />

          <Form.Label>{I18n.t('views.schedules.create-schedule-end_at')}</Form.Label>
          <DatePicker
            name="end_at"
            selected={this.state.end_at}
            onChange={this.onChangeDatepickerEnd}
            className="datepicker-input"
            dateFormat="dd.MM.yyyy"
          />

          <Form.Label>{I18n.t('views.schedules.create-schedule-parent_id')}</Form.Label>
          <Form.Control as="select" onChange={this.formChange} name="parent_id" value={this.state.parent_id}>
            <option key="0" value="0"> - </option>
            {this.state.schedules.map((schedule) => <option key={schedule.id} value={schedule.id}>
              {schedule.description}
            </option>)}
          </Form.Control>

        </Form.Group>
        <Button variant="primary" onClick={this.createSchedule}>{I18n.t('views.schedules.button-create')}</Button>
      </>}

      {this.state.schedulesSorted.length > 0 && <SchedulerGraph schedules={this.state.schedulesSorted} /> }

      <EditScheduleModal
        key={this.state.baseKey}
        show={this.state.modal === Modals.scheduleEdit}
        onHide={this.hideModal}
        onRefresh={this.refreshView}
        schedule={this.state.scheduleToEdit}
        schedules={this.state.schedules}
        formatDateDB = {this.formatDateDB}
        project={this.props.project}
      />
    </div>;
  }
}

export default SchedulesTab;
