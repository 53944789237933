
import APIService from './APIService.js';
import ConfigurationService from './ConfigurationService.js';
import GlobalsService from './GlobalsService.js';
import GPSService from './GPSService.js';
import LocalStorageService from './LocalStorageService.js';
import AvainiaPermissions from './AvainiaPermissions.js';
// import RequestService from './RequestService.js';
import ScheduleService from './ScheduleService.js';
import TabService from './TabService.js';
import UtilService from './UtilService.js';

const Utils = {
  API: APIService,
  Configuration: ConfigurationService,
  Globals: GlobalsService,
  GPS: GPSService,
  LocalStorage: LocalStorageService,
  AvainiaPermissions,
  // Requests: RequestService,
  Schedules: ScheduleService,
  Tabs: TabService,
  Utils: UtilService,
};

export default Utils;
