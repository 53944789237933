import React from 'react';
import I18n from 'i18n-js';
import { Alert } from 'react-bootstrap';

export default (props) => {
  if (props.hide) {
    return <></>;
  }

  if (props.inline) {
    return (
      <Alert variant="danger">
        {I18n.t(`errors.code-${props.error}`)}
      </Alert>
    );
  }

  return (
    <div className="App-main">
      <Alert variant="danger" className="error">
        {I18n.t(`errors.code-${props.error}`)}
      </Alert>
    </div>
  );
};
