import React, { Component } from 'react';
import './DocumentReportDate.scss';
import I18n from 'i18n-js';
import moment from 'moment';
import IconifiedDocumentGrid from './IconifiedDocumentGrid.js';

class DocumentReportDate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sortAscending: false,
      openDocuments: {},
      openImageDocuments: false,
      error: false,
      loading: true,
    };
  }

  sortDocuments = (documents) => {
    let documentsSorted = {};

    documents.forEach((doc) => {
      const date = doc.createdDateOnly;
      const type = doc.document_type.name;
      if (!documentsSorted[date]) {
        documentsSorted[date] = {};
      }
      if (!documentsSorted[date][type]) {
        documentsSorted[date][type] = [];
      }
      documentsSorted[date][type].push(doc);
    });

    documentsSorted = this.sortObjectKeys(documentsSorted);
    return documentsSorted;
  }

  sortObjectKeys = (obj) => {
    if (this.state.sortAscending) {
      return Object.keys(obj).sort().reduce((result, key) => {
        result[key] = obj[key];
        return result;
      }, {});
    }
    return Object.keys(obj).sort().reverse().reduce((result, key) => {
      result[key] = obj[key];
      return result;
    }, {});
  }

  toggleCollapse = (key) => {
    const doc = !this.state.openDocuments[key];
    this.setState((prevState) => ({
      openDocuments: {
        ...prevState.openDocuments,
        [key]: doc,
      },
    }));
  }

  setOpenImageDocuments = (openImageDocuments) => {
    this.setState({ openImageDocuments });
  }

  render() {
    const documentsSorted = this.sortDocuments(this.props.visibleDocuments);

    return <div className='DocumentReportDate'>
      {!this.state.openImageDocuments && <>
        <table className='table table-bordered AvainiaTable clickable'>
          <thead>
            <tr>
              <th className="sortable" onClick={() => this.setState({ sortAscending: !this.state.sortAscending })}>
                {I18n.t('views.infraproject.reports.date')}
                {this.state.sortAscending && <span className="caret-asc"></span>}
                {!this.state.sortAscending && <span className="caret-desc"></span>}
              </th>
              <th>{I18n.t('views.infraproject.reports.type-and-file')}</th>
              <th>{I18n.t('views.infraproject.reports.phase')}</th>
              <th>{I18n.t('views.infraproject.reports.format')}</th>
              <th>{I18n.t('views.infraproject.reports.created')}</th>
            </tr>
          </thead>
          {Object.keys(documentsSorted).map((dateGroup) => <tbody key={dateGroup}>
            <tr>
              <td colSpan='5' onClick={() => { this.toggleCollapse(dateGroup); }}>
                {this.state.openDocuments[dateGroup] ? '▾' : '▴'} {moment(dateGroup, 'YYYY.MM.DD').format('DD.MM.YYYY')}
              </td>
            </tr>
            {Object.keys(documentsSorted[dateGroup]).map((typeGroup) => {
              const documentsForType = documentsSorted[dateGroup][typeGroup];
              const key = dateGroup + typeGroup;
              const display = this.state.openDocuments[dateGroup] ? '' : 'hidden';
              const displayText = typeGroup === 'document_type_default' ? I18n.t('documentType.defaultDocumentType') : typeGroup;

              return (
                <React.Fragment key={key}>
                  <tr className={display}>
                    <td></td>
                    <td colSpan='2' onClick={() => { this.toggleCollapse(key); }}>
                      {this.state.openDocuments[key] ? '▾' : '▴'}
                      <strong>{displayText}</strong>
                      ({documentsForType.length})
                    </td>
                    <td colSpan='2'>
                      <button className='btn btn-primary' onClick={() => { this.setOpenImageDocuments(documentsForType); }}>{I18n.t('views.infraproject.reports.show-images')}</button>
                    </td>
                  </tr>
                  {Object.keys(documentsForType).map((document) => {
                    const doc = documentsForType[document];
                    const key = dateGroup + typeGroup + document;
                    const display = this.state.openDocuments[dateGroup] && this.state.openDocuments[dateGroup + typeGroup] ? '' : 'hidden';
                    const active = (this.props.documentActive && doc.id === this.props.documentActive.id) ? 'selected' : '';
                    return (
                      <tr className={`${display} document ${active}`} key={key} onClick={() => { this.props.documentActivate(doc); }}>
                        <td></td>
                        <td>{doc.id}</td>
                        <td>{doc.phase}</td>
                        <td>{doc.files[0] && doc.files[0].extension}</td>
                        <td>{moment(doc.created, 'YYYY.MM.DD HH:mm').format('YYYY.MM.DD HH:mm')}</td>
                      </tr>
                    );
                  })}
                </React.Fragment>
              );
            })}
            </tbody>)}
        </table>
        <div className="DocumentTable-bottomPadding" />
      </>}
      {this.state.openImageDocuments && <div className='DocumentReportDateImages'>
        <button className="btn btn-primary" onClick={() => { this.setState({ openImageDocuments: false }); }}>{I18n.t('views.infraproject.reports.back')}</button>
        <IconifiedDocumentGrid
          phases={this.props.phases}
          subjects={this.props.subjects}
          visibleDocuments={this.state.openImageDocuments}
          documentActive={this.props.documentActive}
          documentActivate={this.props.documentActivate}
        />
      </div> }
    </div>;
  }
}

export default DocumentReportDate;
