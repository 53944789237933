import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Tab, Badge } from 'react-bootstrap';
import moment from 'moment';
import I18n from 'i18n-js';
import APIService from '../../../../../AvainiaTools/APIService.js';
import Error from '../../../../multiview/Error/Error.js';
import Loading from '../../../../multiview/Loading/Loading.js';
import LocalStorageService from '../../../../../AvainiaTools/LocalStorageService.js';
import ModalViewDocument from '../../../../multiview/Modals/ModalViewDocument/ModalViewDocument.js';
import TabService from '../../../../../AvainiaTools/TabService.js';
import Topbar from '../../../../multiview/Topbar/Topbar.js';
import CompaniesTab from '../CompaniesTab.js';
import ContactTab from '../ContactTab.js';
import DocumentTable from '../DocumentTable.js';
import Map from './Map.js';
import AvainiaPermissions from '../../../../../AvainiaTools/AvainiaPermissions.js';
import markerIcon from '../../../../../images/marker.png';
import activeMarkerIcon from '../../../../../images/marker_active.png';
import './InfraProject.scss';

const Tabs = {
  base: 'base',
  companies: 'companies',
  contacts: 'contacts',
};

class InfraProject extends Component {
  constructor(props) {
    super(props);

    const view = this.getView(props, this.props.project.id);

    this.state = {
      view,
      tab: Tabs.base,
      forceMapview: false,
      folders: [],
      allImages: [],
      visibleImages: [],
      phases: [],
      allPhases: [],
      subjects: [],
      states: [],
      fields: [],
      documentTypes: [],
      activeMarker: false,
      error: false,
      loading: true,
    };
  }

  componentDidMount() {
    const { project } = this.props;
    const { id } = project;

    const promises = [
      APIService.getAllPhases(),
      APIService.getAllSubjects(),
      APIService.getAllStates(),
      APIService.projectFoldersGet(id, 'folders'),
      APIService.getFields('fields'),
      APIService.documentTypesGet('documentTypes'),
    ];

    Promise.all(promises).then((data) => {
      let error = false;
      let phases;
      let allPhases;
      let folders;
      let subjects;
      let states;
      let fields;
      let documentTypes;

      data.forEach((x) => {
        if (error) { return; }
        if (x.error) { error = x.error; return; }
        if (x.phases) { allPhases = x.phases; phases = x.phases.filter((p) => !p.deactivated); }
        if (x.subjects) { subjects = x.subjects; }
        if (x.states) { states = x.states; }
        if (x.fields) { fields = x.fields; }
        if (x.folders) { folders = x.folders; }
        if (x.documentTypes) { documentTypes = x.documentTypes; }
      });

      if (error) { return this.setState({ error }); }

      APIService.projectDocumentsGet(id, fields).then((result) => {
        if (result.error) { return this.setState({ error: result.error }); }

        const allImages = result.map((item) => {
          return {
            ...item,
            document_type: documentTypes.find((x) => Number(x.id) === Number(item.document_type_id)),
          };
        });

        const visibleImages = this.getVisibleImages(this.state.view, allImages);
        LocalStorageService.incrementProjectOpeningCount(id);

        const tab = TabService.getTab() || Tabs.base;
        this.setState({ project, folders, allImages, visibleImages, allPhases, phases, subjects, states, fields, tab, documentTypes, loading: false });
      });
    });
  }

  tabChange = (tab) => {
    TabService.updateUrl(tab);
    this.setState({ tab });
  }

  getView = (props, id) => {
    const viewInitString = props.match.params.view;

    if (viewInitString) {
      try {
        return JSON.parse(decodeURIComponent(viewInitString)); // TODO: sanitize and validate phases etc for outdated or removed
      } catch (e) {
        console.error(`JSON parse fail ${e.message}`); // TODO: Actual error reporting
      }
    }

    const o = {
      filter: '',
      filterToDate: '',
      filterFromDate: '',
      activeFilters: { subject: [], phase: [], state: [] },
      activeImage: null,
    };

    this.updateUrl(o, id);
    return o;
  }

  updateUrl = (newView, id) => {
    const projectId = id || this.props.project.id;
    const urlAppendix = `/project/${projectId}/${encodeURIComponent(JSON.stringify(newView))}`;
    // TODO: This works, but seems suboptimal
    this.props.history.replace(urlAppendix);
  }

  toggleFilter = (e) => {
    e.stopPropagation();
    let { target } = e;
    let sanityBreaker = 40;

    while (--sanityBreaker) { // TODO Refactor?
      if (target.classList.contains('filter-toggle')) { break; }
      target = target.parentNode;
    }

    if (!sanityBreaker) { console.error('SanityBreaker triggered!'); /* TODO: Actual error logging */ }

    target.classList.toggle('active');

    // Update filters
    const activeFilters = target.parentNode.parentNode.parentNode.querySelectorAll('.filter-toggle.active'); // TODO Refactor?
    const newFilters = { subject: [], phase: [], state: [] };

    activeFilters.forEach((filter) => {
      let type = filter.dataset.filtertype;
      if (!type) { type = filter.children[0].dataset.filtertype; }
      newFilters[type].push(filter.innerText.trim());
    });

    const view = { ...this.state.view };
    view.activeFilters = newFilters;

    const visibleImages = this.getVisibleImages(view);

    this.setState({ visibleImages, view });
    this.updateUrl(view);
  }

  resetFilters = () => {
    const newFilters = { subject: [], phase: [], state: [] };
    const view = { ...this.state.view };

    view.filter = '';
    view.filterFromDate = '';
    view.filterToDate = '';
    view.activeFilters = newFilters;

    const visibleImages = this.getVisibleImages(view);
    this.setState({ visibleImages, view });
    this.updateUrl(view);
  }

  getVisibleImages(view, allImages) {
    const { activeFilters } = view;
    const lowcaseFilter = view.filter ? view.filter.toLowerCase() : null;
    const fromFilter = view.filterFromDate ? moment(view.filterFromDate, 'YYYY.MM.DD').startOf('day') : false;
    const toFilter = view.filterToDate ? moment(view.filterToDate, 'YYYY.MM.DD').endOf('day') : false;

    const mainSubsectOfImags = allImages || this.state.allImages;

    function match(a) {
      return a ? a.toLowerCase().indexOf(lowcaseFilter) !== -1 : false;
    }

    const visibleImages = mainSubsectOfImags.filter((image) => {
      const phaseMatch = activeFilters.phase.length > 0 ? activeFilters.phase.some((phase) => image.phase === phase) : true;
      if (!phaseMatch) { return false; }

      const subjectMatch = activeFilters.subject.length > 0 ? activeFilters.subject.some((subject) => image.subject === subject) : true;
      if (!subjectMatch) { return false; }

      const stateMatch = activeFilters.state.length > 0 ? activeFilters.state.some((state) => image.state === state) : true;
      if (!stateMatch) { return false; }

      const dateMatch = true; /* TODO: Check implementation */
      if (!dateMatch) { return false; }

      if (image.createdMoment) {
        if (fromFilter && fromFilter.isValid() && fromFilter.diff(image.createdMoment) > 0) { return false; }
        if (toFilter && toFilter.isValid() && toFilter.diff(image.createdMoment) < 0) { return false; }
      }

      if (!view.filter) { return true; }

      if (
        match(image.date) ||
        match(image.name) ||
        match(image.phase) ||
        match(image.subject) ||
        match(image.state) ||
        match(image.addedByCompany) ||
        match(image.detailText) ||
        match(image.positionText)
      ) {
        return true;
      }

      return false;
    });

    return visibleImages;
  }

  getImageData = (id) => {
    for (let i = 0; i < this.state.allImages.length; i++) {
      // eslint-disable-next-line
      if (this.state.allImages[i].id == id) {// Intentional, needs to match string and Number
        return this.state.allImages[i];
      }
    }
  }

  activateImage = (image, options) => {
    const config = options || {};

    if (!image) {
      const view = { ...this.state.view };
      view.activeImage = null;
      this.setDefaultMarkerIcons();
      this.setState({ view, activeMarker: false });
      this.updateUrl(view);
      return;
    }

    const marker = window._markers.find((m) => m.label === image.counter);
    if (marker) {
      if (!config.dontCenter) { window._map.setCenter(marker.getPosition()); }
      this.setDefaultMarkerIcons();
      marker.setIcon({
        url: activeMarkerIcon,
        labelOrigin: new window.google.maps.Point(14, 14),
      });
    }

    const activeMarker = marker ? marker.label : false;
    const viewElement = document.getElementById('infra-project-view');
    const { view } = this.state;
    view.activeImage = image.id;
    this.setState({
      view,
      activeMarker,
      forceMapview: viewElement.classList.contains('map-view-active'),
    });
    this.updateUrl(view);
  }

  onChange = (e) => {
    const view = { ...this.state.view };
    view.filter = e.target.value;
    const visibleImages = this.getVisibleImages(view);
    this.setState({ visibleImages, view });
    this.updateUrl(view);
  }

  onChangeFrom = (e) => {
    const view = { ...this.state.view };
    view.filterFromDate = e.target.value;
    const visibleImages = this.getVisibleImages(view);
    this.setState({ visibleImages, view });
    this.updateUrl(view);
  }

  onChangeTo = (e) => {
    const view = { ...this.state.view };
    view.filterToDate = e.target.value;
    const visibleImages = this.getVisibleImages(view);
    this.setState({ visibleImages, view });
    this.updateUrl(view);
  }

  toggleMapView = (e) => {
    const viewElement = document.getElementById('infra-project-view');
    viewElement.classList.toggle('map-view-active');

    const root = document.getElementById('root');
    root.classList.remove('mobile-map-fullscreen');
  }

  viwImageDetailsInMobileMapview = (e) => {
    this.toggleMapView(e);
    const element = document.querySelector('.image-operations');
    element.scrollIntoView();
  }

  setDefaultMarkerIcons = (e) => {
    window._markers.forEach((m) => {
      m.setIcon({
        url: markerIcon,
        labelOrigin: new window.google.maps.Point(14, 14),
      });
    });
  }

  renderProjectStatus = () => {
    return <Badge variant="primary">{I18n.t(`views.projects.statuses.${this.state.project.status}`)}</Badge>;
  }

  render() {
    if (this.state.error) { return <Error error={this.state.error} />; }
    if (this.state.loading) { return <Loading />; }

    const activeImage = this.getImageData(this.state.view.activeImage);
    const classInject = this.state.forceMapview ? 'map-view-active' : '';// TODO extract variable
    const visibleImagesKey = this.state.visibleImages.map((vi) => vi.id).join('-');

    const user = LocalStorageService.getUser();
    const canManage = user.hasPermission(AvainiaPermissions.ProjectsManage);

    return <div className="App-main">
      <Topbar
        tabKey={this.state.tab}
        tabChangeCallback={this.tabChange}
        userManagement={canManage ? () => { this.tabChange(Tabs.companies); } : false}
      >
        <Tab eventKey={Tabs.base} title={I18n.t('general.basicdata')} />
        <Tab eventKey={Tabs.contacts} title={I18n.t('views.contacts.contacts')} />
      </Topbar>

      {this.state.tab === Tabs.base && <div id="infra-project-view" className={classInject}>
        <Map
          key={visibleImagesKey}
          activateImage={this.activateImage}
          viwImageDetailsInMobileMapview={this.viwImageDetailsInMobileMapview}
          images={this.state.visibleImages}
          allImages={this.state.allImages}
          activeImage={activeImage}
          toggleMapView={this.toggleMapView}
        />

        <div className="width-limiter">
          <div className="project-heading">
            <div className="project-heading-title">
              <h4 className="image-title">
                {this.props.project.name}
              </h4>
              <span className="hilight-text">
                {this.props.project.code} &nbsp;
                {this.renderProjectStatus()}
              </span>
            </div>
          </div>

          <DocumentTable
            project={this.state.project}
            allPhases={this.state.allPhases}
            phases={this.state.phases}
            subjects={this.state.subjects}
            states={this.state.states}
            documentActivate={this.activateImage}
            documentActive={activeImage}
            documents={this.state.allImages}
            folders={this.state.folders}
            visibleDocuments={this.state.visibleImages}
            resetFilters={this.resetFilters}
            configuration={this.props.configuration}
            useIcons
            useFilters
            thing={this}
          />

        </div>
      </div>}

      {this.state.tab === Tabs.companies && canManage && <CompaniesTab project={this.props.project} folders={this.state.folders} />}
      {this.state.tab === Tabs.contacts && <ContactTab project={this.props.project} showRenderedContacts="true" />}
      {activeImage &&
        <ModalViewDocument
          parent="infraproject"
          key={activeImage ? activeImage.id : '0'}
          documentActive={activeImage}
          documentEditedCallback={() => { document.location.reload(); }}
          documentDeletedCallback={() => { document.location.reload(); }}
          hideModal={this.activateImage}
          fields={this.state.fields}
          phases={this.state.phases}
          project={this.props.project}
          subjects={this.state.subjects}
          states={this.state.states}
          show={true}
        />
      }
    </div>;
  }
}

export default withRouter(InfraProject);
