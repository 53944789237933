import React, { Component } from 'react';
import I18n from 'i18n-js';
import { FormControl } from 'react-bootstrap';
import { Filter, AngleDown, AngleRight, MapMarker } from '../../../multiview/Icon/Icon.js';

class MobileFilters extends Component {
  constructor(props) {
    super(props);

    this.state = {
      toggleFilters: false,
    };
  }

  toggleFilters = (e) => {
    const togg = this.state.toggleFilters;
    this.setState({ toggleFilters: !togg });
  }

  toggleFold = (e) => {
    let { target } = e;

    while (true) {
      if (target.nodeName.toLowerCase() === 'body') { return; }
      if (target.classList.contains('foldable')) {
        target.classList.toggle('closed');
        break;
      }
      target = target.parentNode;
    }
  }

  toggleMapView = () => {
    document.getElementById('root').classList.add('mobile-map-fullscreen');
    document.getElementsByClassName('map-wrapper')[0].style.display = 'flex';
    document.getElementsByClassName('map-close-button')[0].style.display = 'none';
  }

  render() {
    const { thing } = this.props;

    return <div className="mobile-filters">
        <div className="mobile-only viewselector">
          <div onClick={this.toggleFilters}><Filter /> {I18n.t('views.infraproject.filter-documents')}</div>
          <div onClick={this.toggleMapView}><MapMarker /> {I18n.t('views.infraproject.toggle-mapview')}</div>
      </div>
      { this.state.toggleFilters && <>
        <div className="foldable closed" onClick={this.toggleFold}>
          <div className="foldable-title">
            <span className="foldable-icon-closed"><AngleRight /></span>
            <span className="foldable-icon-open"><AngleDown /></span>
            {I18n.t('views.infraproject.phase')} {thing.state.view.activeFilters.phase.length ? `(${thing.state.view.activeFilters.phase.length})` : ''}
          </div>
          <div className="filterset filter-phase">
            {thing.state.phases.map((phase) => <span
                onClick={thing.toggleFilter}
                data-filtertype="phase"
                key={phase.id}
                className={thing.state.view.activeFilters.phase.includes(phase.name) ? 'filter-toggle active' : 'filter-toggle'}
              >
                {phase.name}
              </span>)}
          </div>
        </div>
        <div className="foldable closed" onClick={thing.toggleFold}>
          <div className="foldable-title">
            <span className="foldable-icon-closed"><AngleRight /></span>
            <span className="foldable-icon-open"><AngleDown /></span>
            {I18n.t('views.infraproject.subject')} {thing.state.view.activeFilters.subject.length ? `(${thing.state.view.activeFilters.subject.length})` : ''}
          </div>
          <div className="filterset filter-subject" data-todo="Why subjectName and not an object?">
            {thing.state.subjects.map((subject, i) => <span
                onClick={thing.toggleFilter}
                data-filtertype="subject"
                key={subject.name}
                className={thing.state.view.activeFilters.subject.includes(subject.name) ? 'filter-toggle active' : 'filter-toggle'}
              >
                {subject.name}
              </span>)}
          </div>
        </div>
        <FormControl
          autoFocus
          placeholder="Suodata"
          name="filter"
          type="text"
          value={thing.state.view.filter}
          onChange={thing.onChange}
        />
      </>}
    </div>;
  }
}

export default MobileFilters;
