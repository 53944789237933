import React, { Component, Fragment, useState } from 'react';
import I18n from 'i18n-js';
import { Button, FormControl, Modal, Form, Spinner } from 'react-bootstrap';
import APIService from '../../../AvainiaTools/APIService.js';
import Error from '../Error/Error.js';
import Loading from '../Loading/Loading.js';

const Steps = {
  documentType: 1,
  fields: 2,
};

const AvainiaField = (props) => {
  const { field, updateFieldData } = props;
  const [value, setValue] = useState('');

  if (field.is_automatic) {
    return false;
  }

  if (field.type === 'keywords') {
    const options = field.keywords
      .filter((kw) => !kw.deactivated)
      .sort((a, b) => { return a.text > b.text ? 1 : -1; })
      .map((kw) => <option key={`${field.id}_${kw.id}`}>{kw.text}</option>);

    return <Form.Control as="select" value={value} onChange={(e) => {
      setValue(e.target.value);
      updateFieldData(field.id, e.target.value);
    }}>
      <option default>{I18n.t('general.select')} {I18n.t(`fields.${field.key}`, { defaultValue: field.key }).toLowerCase()}</option>
      { options }
    </Form.Control>;
  }

  if (field.type === 'text') {
    return <FormControl placeholder={I18n.t(`fields.${field.key}`, { defaultValue: field.key })} type="text" value={value}
      onChange={(e) => {
        setValue(e.target.value);
        updateFieldData(field.id, e.target.value);
      }}
    />;
  }

  return false;
};

class ModalDocumentAdd extends Component {
  constructor(props) {
    super(props);

    this.state = {
      allFields: [],
      documentTypes: [],
      selectedDocumentType: false,
      step: Steps.documentType,
      documentData: {},
      selectedFile: null,
      loading: true,
      error: false,
    };
  }

  componentDidMount = () => {
    APIService.documentTypesGet().then((documentTypes) => {
      if (documentTypes.error) { return this.setState({ error: documentTypes.error }); }

      APIService.fieldsGet().then((allFields) => {
        if (allFields.error) { return this.setState({ error: allFields.error }); }

        this.setState({
          allFields,
          documentTypes,
          loading: false,
        });
      });
    });
  }

  handleSelectedFile = (e) => {
    this.setState({ selectedFile: e.target.files[0] });
  }

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  updateFieldData = (fieldId, fieldValue) => {
    this.setState((prevState) => ({
      fieldData: {
        ...prevState.fieldData,
        [fieldId]: fieldValue,
      },
    }));
  }

  nextStep = () => {
    if (this.state.step === Steps.documentType) {
      // eslint-disable-next-line eqeqeq
      const selectedDocumentType = this.state.documentTypes.find((dt) => dt.id == this.state.selectedDocumentType);
      this.setState({
        step: Steps.fields,
        selectedDocumentType,
      });
    }
  }

  // TODO: Check for empty fields
  storeDocument = () => {
    if (!this.state.fieldData) { return; }
    if (!this.state.selectedDocumentType) { return; }
    if (!this.state.selectedDocumentType.id) { return; }

    this.setState({ loading: true }, () => {
      const fieldData = Object.entries(this.state.fieldData);

      const payload = {
        projectId: this.props.project.id,
        documentTypeId: this.state.selectedDocumentType.id,
        folderId: this.props.parentFolder ? this.props.parentFolder.id : null,
        file: this.state.selectedFile,
        fields: [],
        keywords: [],
      };

      fieldData.forEach((fieldSet) => {
        // eslint-disable-next-line eqeqeq
        const field = this.state.allFields.find((f) => f.id == fieldSet[0]);
        if (field.type === 'keywords') {
          const keywordId = field.keywords.find((keyword) => keyword.text === fieldSet[1]).id;
          payload.keywords.push({
            field_id: field.id,
            id: keywordId,
            text: fieldSet[1],
          });
        } else if (field.type === 'text') {
          payload.fields.push({
            field_id: field.id,
            text: fieldSet[1],
          });
        }
      });

      APIService.documentAdd(payload).then((result) => {
        if (result.error) { return this.setState({ error: result.error, loading: false }); } // This will probably never trigger
        window.location.reload(); // TODO: Improve
      }).catch(() => {
        this.setState({ error: 6, loading: false }); // TODO: Improve
      });
    });
  }

  render() {
    const activeDocumentTypes = this.state.documentTypes.filter((docType) => docType.is_active);

    return <Modal show={true} onHide={this.props.hideModal}>
      <Modal.Header closeButton>
        <Modal.Title>{I18n.t('views.infraproject.add-document')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Error inline error={this.state.error} hide={!this.state.error} />
        <Loading inline hide={!this.state.loading} />
        {!this.state.loading && this.state.step === Steps.documentType &&
          <Fragment>
            {this.props.parentFolder &&
              <FormControl
                type="text"
                disabled
                value={`${I18n.t('folders.folder')}: ${this.props.parentFolder.name}`}
              />
            }
            <Form.Control as="select" name="selectedDocumentType" value={this.state.selectedDocumentType} onChange={this.onChange}>
              <option default value="">{I18n.t('views.documents.select-type')}</option>
              {activeDocumentTypes.map((type) => <option value={type.id} key={type.id}>
                {type.is_default ? I18n.t('documentType.default') : type.name}
              </option>)}
            </Form.Control>
            <Button onClick={this.nextStep} disabled={!this.state.selectedDocumentType}>{I18n.t('views.documents.next')}</Button>
          </Fragment>
        }
        {this.state.step === Steps.fields &&
          <Fragment>
            {this.state.selectedDocumentType.fields.map((field) => <AvainiaField key={field.id} field={field} updateFieldData={this.updateFieldData} />)}
            <input type="file" name="addNewImage" onChange={this.handleSelectedFile} />
          </Fragment>
        }
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={this.props.hideModal}>{I18n.t('general.cancel')}</Button>
        <Button
          variant="primary"
          onClick={this.storeDocument}
          disabled={this.state.loading}
        >
          {I18n.t('general.save')}
          {this.state.loading && <>&nbsp;
            <Spinner animation="border" size="sm" role="status" aria-hidden="true" />
          </>}
        </Button>
      </Modal.Footer>
    </Modal>;
  }
}

export default ModalDocumentAdd;
