import React, { Component } from 'react';
import Toast from 'react-bootstrap/Toast';
import { Key } from '../Icon/Icon.js';
import { Button } from 'react-bootstrap';
import I18n from 'i18n-js';
import ReLoginModal from '../Modals/ReLoginModal.js';
import LocalStorageService from '../../../AvainiaTools/LocalStorageService.js';

class LoginStatusToast extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: true,
      showLoginModal: false,
    };
  }

  close = () => {
    this.setState({ show: false });
  }

  logout = (e) => {
    LocalStorageService.clearUser();
    window.location.reload(); // TODO: Improve
  }

  toggleLoginModal = () => {
    this.setState((prevState) => ({
      showLoginModal: !prevState.showLoginModal,
    }));
  }

  render() {
    return <div>
    <ReLoginModal
      show={this.state.showLoginModal}
      onHide={this.toggleLoginModal}
      callBack={this.close}
      loginCallback={this.props.loginCallback}
    />
      <Toast
        style={{
          position: 'absolute',
          width: '300px',
          marginLeft: '-150px',
          bottom: '10px',
          left: '50%',
        }}
        show={this.state.show}
        onClose={this.close}
      >
        <Toast.Header>
          <Key/>
          <strong className="mr-auto ml-1">{I18n.t('general.appname')}</strong>
          <small>{I18n.t('views.toasts.timeout.when')}</small>
        </Toast.Header>
        <Toast.Body>
          {I18n.t('views.toasts.timeout.warning-message')}
          <div>
            <Button
              className="w-100 mt-2"
              onClick={this.toggleLoginModal}
            >
              {I18n.t('views.toasts.timeout.button-continue')}
            </Button>
          </div>
          <div>
            <Button
              className="w-100 mt-2"
              onClick={this.logout}
            >
              {I18n.t('views.toasts.timeout.button-logout')}
            </Button>
          </div>
        </Toast.Body>
      </Toast>
      </div>;
  }
}

export default LoginStatusToast;
