import React, { Component } from 'react';
import { Button, Modal, Row, Form, Col, Container } from 'react-bootstrap';
import I18n from 'i18n-js';
import LocalStorageService from '../../../AvainiaTools/LocalStorageService.js';

class ModalConfigProjectsTable extends Component {
  setColumnVisibility = (tableConfig, key) => {
    const config = tableConfig;
    config[key] = !config[key];
    LocalStorageService.setProjectsTableConfig(config);
    this.props.callBack();
  }

  render() {
    const tableConfig = LocalStorageService.getProjectsTableConfig();
    return <Modal show={this.props.show} onHide={this.props.hide}>
      <Modal.Header closeButton>
        <Modal.Title>{I18n.t('views.projects.configure-view')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col><Form.Check type="checkbox" label={I18n.t('views.projects.table.show-id')} checked={tableConfig.id} onChange={() => { this.setColumnVisibility(tableConfig, 'id'); }} /></Col>
            <Col><Form.Check type="checkbox" label={I18n.t('views.projects.table.show-code')} checked={tableConfig.code} onChange={() => { this.setColumnVisibility(tableConfig, 'code'); }} /></Col>
          </Row>
          <Row>
            <Col><Form.Check disabled type="checkbox" label={I18n.t('views.projects.table.show-name')} checked={tableConfig.name} onChange={() => { this.setColumnVisibility(tableConfig, 'name'); }} /></Col>
            <Col><Form.Check type="checkbox" label={I18n.t('views.projects.table.show-status')} checked={tableConfig.status} onChange={() => { this.setColumnVisibility(tableConfig, 'status'); }} /></Col>
          </Row>
          <Row>
            <Col><Form.Check type="checkbox" label={I18n.t('views.projects.table.show-companies')} checked={tableConfig.companies} onChange={() => { this.setColumnVisibility(tableConfig, 'companies'); }} /></Col>
            <Col><Form.Check type="checkbox" disabled={this.props.disableType} label={I18n.t('views.projects.table.show-type')} checked={tableConfig.type} onChange={() => { this.setColumnVisibility(tableConfig, 'type'); }} /></Col>
          </Row>
          <Row>
            <Col><Form.Check type="checkbox" label={I18n.t('views.projects.table.show-actions')} checked={tableConfig.actions} onChange={() => { this.setColumnVisibility(tableConfig, 'actions'); }} /></Col>
            <Col><Form.Check type="checkbox" label={I18n.t('views.projects.table.show-inactive')} checked={tableConfig.showInactiveProjects} onChange={() => { this.setColumnVisibility(tableConfig, 'showInactiveProjects'); }} /></Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={this.props.hide}>
          {I18n.t('general.return')}
        </Button>
      </Modal.Footer>
    </Modal>;
  }
}

export default ModalConfigProjectsTable;
