import React, { Component } from 'react';
import { Form, InputGroup, Modal, Tabs } from 'react-bootstrap';
import I18n from 'i18n-js';
import { Search, Cog, UsersCog } from '../Icon/Icon.js';
import './Topbar.scss';

/*
Example:
import { Tab } from 'react-bootstrap';
...
<Topbar searchChangeHandler={this.onSearchTermChange} tabChangeCallback={()=>{alert("TCC")}} configModal={ProjectsConfigModal}>
  <Tab eventKey="foo" title="xxx" disabled={false}></Tab>
  <Tab eventKey="bar" title="xxx" disabled={false}></Tab>
</Topbar>
*/

class Topbar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      search: '',
      configModalOpen: false,
      searchActive: true,
      loading: false,
      error: false,
    };
  }

  toggleSearch = () => { this.setState({ searchActive: !this.state.searchActive }); }

  toggleCog = () => { this.setState({ configModalOpen: !this.state.configModalOpen }); }

  onChange = (e) => { this.setState({ [e.target.name]: e.target.value }); }

  render() {
    const { searchActive, search, configModalOpen } = this.state;

    return <div className={`
      Topbar ${(this.props.searchChangeHandler && searchActive) ? 'is-active' : ''}
      ${this.props.searchChangeHandler ? 'has-search' : ''}
    `}>
      <div className="Topbar-inner">
        <div className="Topbar-content">
          {this.props.searchChangeHandler && <div className="Topbar-search">
            <InputGroup size="sm">
              <InputGroup.Prepend>
                <InputGroup.Text id="inputGroupPrepend" onClick={this.toggleSearch} >
                  <Search />
                </InputGroup.Text>
              </InputGroup.Prepend>
              {this.props.searchChangeHandler && searchActive && <Form.Control
                type="text"
                placeholder=""
                name="search"
                value={search}
                onChange={(e) => {
                  this.onChange(e);
                  this.props.searchChangeHandler(e);
                }}
                autoFocus
              />}
            </InputGroup>
          </div>}
          <div className="Topbar-tabs">
            {this.props.children && <Tabs activeKey={this.props.tabKey} onSelect={this.props.tabChangeCallback}>{this.props.children}</Tabs>}
          </div>
          <div className="Topbar-actions">
            {this.props.configModal && <InputGroup size="sm">
              <InputGroup.Append>
                <InputGroup.Text id="inputGroupAppend" onClick={this.toggleCog}>
                  <Cog />
                </InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>}

            {this.props.userManagement && <InputGroup size="sm">
              <InputGroup.Append>
                <InputGroup.Text id="inputGroupAppend" onClick={this.props.userManagement}>
                  <UsersCog />
                </InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>}
          </div>
        </div>

        {this.props.configModal && <Modal
          centered
          dialogClassName="modal-90w"
          show={configModalOpen}
          onHide={this.toggleCog}>
          <Modal.Header closeButton>
            <Modal.Title>{I18n.t('general.configure')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.props.configModal()}
          </Modal.Body>
          <Modal.Footer />
        </Modal>}
      </div>
    </div>;
  }
}

export default Topbar;
