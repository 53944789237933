import React, { Component } from 'react';
import SecureImage from '../../../multiview/SecureImage/SecureImage.js';

class IconifiedDocumentGrid extends Component {
  /* These will be handled via DOM selector */
  toggle = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { target } = e;
    setTimeout(() => { target.checked = !target.checked; }, 0);
  }

  namePhase = (inp) => {
    if (typeof inp === 'number') { return this.props.phases.find((x) => x.id === inp).name; }
    // eslint-disable-next-line eqeqeq
    if (inp && inp.length < 3) { return (this.props.phases.find((x) => x.id == inp) || { name: inp }).name; }
    return inp.name ? inp.name : inp;
  }

  nameSubject = (inp) => {
    if (typeof inp === 'number') { return this.props.subjects.find((x) => x.id === inp).name; }
    // eslint-disable-next-line eqeqeq
    if (inp && inp.length < 3) { return (this.props.subjects.find((x) => x.id == inp) || { name: inp }).name; }
    return inp.name ? inp.name : inp;
  }

  render() {
    const { documentActive } = this.props;

    function act(doc, baseClass) {
      if (!documentActive) { return baseClass; }
      if (documentActive.id !== doc.id) { return baseClass; }
      return `${baseClass} active`;
    }

    return <div className="imagegrid">
      {this.props.visibleDocuments.map((document) => <div
        key={document.id}
        className={act(document, 'grid-item')}
        onClick={() => { this.props.documentActivate(document); }}
      >
        <SecureImage className="image-thumbnail" src={ document.thumb } />
        <div>{ document.counter } / { document.positionText }</div>
      </div>)}
    </div>;
  }
}

export default IconifiedDocumentGrid;
